import React, { useEffect, useState } from "react";
import DashboardCard from "../../components/card/card";
import Custom_Text from "../../components/custom_text";
import SectionHeader from "../../components/custom_text/section_header";
import DashboardPieChart from "../../components/dashboard_cards/dashboard_card_pie";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import Sidebar from "../../components/sidebars/sidebar";
import TableList from "../../components/table/Organization_Table";
import './organization_dashboard.scss'
import SideBarOrganization from "./sidebar_organization/sidebarorganization";

// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';


import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import logoImage from './images/logo.png';
import axios from 'axios';
import { Organization } from "../authentication/authentication_interfaces";


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const OrganizationDashboard : React.FC = () => {

  
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const individualsTrainedPerSubjectBarData = {
    labels: ['Water Management', 'Sanitation and Hygiene (Kids)', 'Sanitation and Hygiene (Adults)', "Bole Hole Maintenance"],
    datasets: [
      {
        label: 'Individuals Trained per Subject',
        backgroundColor: "#4EC7F2",
        borderColor: "#4EC7F2",
        borderWidth: 2,
        data: [34, 2, 9, 100]
      }
    ]
  }
  
  const communitySystemStatusPieData = {
    labels:["Functioning", "Maintenance Checklist Overdue", "Unresolved Diagnostics", "Maintenance & Diagnostic needs"],
    datasets:[{
     data: [300, 50, 100, 40],
     backgroundColor: [
        "#79B82C",
        "#4EC7F2",
        "#FFC870",
        "#A8B3C5",
        "#616774"
      ],
     borderWidth: 1
    }]
  };
  
  const systemTypeStatus = {
    labels:["Bole Hole System", "Rainwater System", "Pipe Water System", "Pumping Machine System"],
    datasets:[{
     data: [30, 10, 15, 40],
     backgroundColor: [
        "#79B82C",
        "#4EC7F2",
        "#FFC870",
        "#A8B3C5",
        "#616774"
      ],
     borderWidth: 1
    }]
  };
  
  const maintenanceStatusPieData = {
    labels:["Compliant", "Overdue"],
    datasets:[{
     data: [300, 50],
     backgroundColor: [
        "#79B82C",
        "#FFB200"
      ],
     borderWidth: 1
    }]
  };
  
  const diagnosticStatusPieData = {
    labels:["Functioning", "Pending Maintenance", "Issue Reported", ],
    datasets:[{
     data: [300, 100, 50],
     backgroundColor: [
        "#79B82C",
        "#FFB200",
        // "#5AD3D1",
        // "#531CBC",
        "#FF0000",
      ],
     borderWidth: 1
    }]
  };

  let handleChange = (event : any) => {}
  let handleSubmit = (event : any) => {}

  const [organization_list_data, setOrganizationListData] = useState<Organization[] | null>([]);
  console.log({organization_list_data})
  useEffect(() => {
    getData();
  }, []);
  //Api calls
  const getData = async () => {
    const userResponse = await axios.get(`http://api.wellbeyondwater.com:8000/api/organization`).then((response) => {
      console.log('API call response status ' + response.status);
      setOrganizationListData(response.data.data);
      console.log('Community Name ' + response['data']['data'][0]['name']);
      // console.log('Organization Name ' + userResponse['data']['status']);
    }).catch((err) => {
      console.log(err)
    })
  };  

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div className="home">

          <div className="homeContainer">

            <SectionHeader sectionTitle={'ORGANIZATIONS'} link='' />
            <div className="cardPadding">
            
                <NavCard_Community cardContent={20} cardTitle={'Create Organization'} image={createImage} link={"/home/organization/create"} />
                <NavCard_Community cardContent={20} cardTitle={'Organization List'} image={listImage} link={"/home/organization/list/:organizationId"} />
                <NavCard_Community cardContent={20} cardTitle={'Edit Organization'} image={editImage} link={"/home/organization/edit/:organizationId"} />
                <NavCard_Community cardContent={20} cardTitle={'Organization Details'} image={detailImage} link={"/home/organization/detail/:organizationId"} />

              </div>
              <div className="cardPadding">
                {/* <DashboardCard cardContent={20} cardTitle={'Organizations'} image={organizationImage} link={""}/> */}
                <DashboardCard cardContent={organization_list_data?.length} cardTitle={'Total number of Organizations'} image={communityImage} link={""}/>
                {/* <DashboardCard cardContent={230} cardTitle={'Management Users'} image={managementUsersImage} link={""}/> */}
                {/* <DashboardCard cardContent={130} cardTitle={'People Trained'} image={trainedPeopleImage} link={""}/> */}
              </div>
            
            <SectionHeader sectionTitle={'SYSTEM OVERVIEW'} link='' />
            <div className="dashboardPieChartContainer">
              <DashboardPieChart data={systemTypeStatus} title='Active System Types' />
              {/* <DashboardPieChart data={communitySystemStatusPieData} title='Community System Status' /> */}
              <DashboardPieChart data={maintenanceStatusPieData} title='Maintenance Status' />
              <DashboardPieChart data={diagnosticStatusPieData} title='Diagnostic Status' />
            </div>
          
            <SectionHeader sectionTitle={'DATA LISTS'} link='' />
            <div className="listContainer">
              <div className="listTitle">Organization</div>
              <TableList data={organization_list_data}/>
            </div>

          </div>
        </div>
       
      </Main>
    </Box>
  );
  
};

export default OrganizationDashboard;