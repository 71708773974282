import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";

//creating a datatype fot the expected props interface for the table components types

export interface User {
    user_id: number;
    organization_id: number;
    user_token: string;
    firebase_token: string;
    profile_image?: any;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    role: string;
    phone_number: string;
    management_role: string;
    reset_password_expiry_time: string;
    reset_password_token?: any;
    isPhoneVerified: boolean;
    isEmailVerifed: boolean;
    active: boolean;
    accepted_terms: boolean;
    suspended: boolean;
    updated_by: number;
    created_by: number;
    created_at: Date;
    updated_at: Date;
  }

//creating a props interface for the table components types
interface tableProps {
    data: User [] | null;
}

const TableList : React.FC<tableProps> = ({data}) => {

    return (
        <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">User ID</TableCell>
            <TableCell className="tableCell">First Name</TableCell>
            <TableCell className="tableCell">Last Name</TableCell>
            <TableCell className="tableCell">User Role</TableCell>

            <TableCell className="tableCell">Contact Number</TableCell>
            <TableCell className="tableCell">Email</TableCell>
            <TableCell className="tableCell">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data!.map((row) => (
            // <Link to='#'>
            <TableRow key={row.user_id}>
              <TableCell className="tableCell">{row.user_id}</TableCell>
              <TableCell className="tableCell">{row.first_name}</TableCell>
              <TableCell className="tableCell">{row.last_name}</TableCell>

              <TableCell className="tableCell">{row.role}</TableCell>
              {/* <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img src={row.image} alt="" className="image" />
                </div>
              </TableCell> */}
              <TableCell className="tableCell">{row.phone_number}</TableCell>
              <TableCell className="tableCell">{row.email}</TableCell>
              <TableCell className="tableCell">
              <span className={`status ${row.active}`}>{row.active ? 'Active' : 'Inactive'}</span>
              </TableCell>
            </TableRow>
              // </Link>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    );
}

export default TableList;