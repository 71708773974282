import React from 'react';
import './card.scss';

//creating a props interface for the DashboardCard components types
//creating a props interface for the DashboardCard components types
interface DashboardCardProps {
    cardTitle: string;
    cardContent: any;
    image: any;
    link: string;
}

const DashboardCard : React.FC<DashboardCardProps> = ({cardTitle, cardContent, image, }) => {
    return (
        <div className="cardBody"> 
            <h1 className='cardContentTitle'>{cardTitle} </h1>
            <hr style={{
                height: "2px",
                backgroundColor: '#dddddd',
                marginTop: '10px',
                marginBottom: '10px',
                border: "none",
            }}/>
            <div className='twoContents'>
                <div className='imageContainer'>
                    <img className='image' src={image} alt="Logo" />
                </div>
                <h1 className='cardContent'>{cardContent} </h1>
            </div> 
        </div>
    );
}

export default DashboardCard;