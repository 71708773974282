import React from 'react';
import logo from './logo.svg';
import './App.css';
import pic from './asset/images/logo.png';
import SignUp from './screens/authentication/signup';
import Login from './screens/authentication/login';
import ForgotPassword from './screens/authentication/forgot_password';
import Home from './screens/dashboard/Home';
import Test from './screens/dashboard/test';
import {
  BrowserRouter as Router,
  Route,
  Link,
  BrowserRouter,
  Routes
} from "react-router-dom";
import OrganizationDetails from './screens/organization/organization_details';
import OrganizationList from './screens/organization/organization_list';
import OrganizationDashboard from './screens/organization/organization_dashboard';
import Administrator from './screens/administrator/administrator';
import Create_User_Management from './screens/user_management/create_user_management';
import Edit_Profile from './screens/profile/edit_profile';
import User_Management_List from './screens/user_management/user_management_list';
import Profile from './screens/profile/profile';
import User_Management from './screens/user_management/user_management';
import Create_System_Type from './screens/system_type/create_system_type';
import Edit_System_Type from './screens/system_type/edit_system_type';
import System_Type from './screens/system_type/system_types';
import System_Type_Details from './screens/system_type/system_type_details';
import System_Type_List from './screens/system_type/system_type_list';
import Create_Diagnostic from './screens/diagnostic/create_diagnostic';
import Diagnostic_Log from './screens/diagnostic/diagnostic_log';
import Diagnostic_Log_Detail from './screens/diagnostic/diagnostic_log_detail';
import Diagnostic from './screens/diagnostic/diagnostic';
import Response_Diagnostic from './screens/diagnostic/response_diagnostic';
import Edit_Training from './screens/training/edit_training';
import Training from './screens/training/training';
import Create_Training from './screens/training/create_training';
import Training_Details from './screens/training/training_details';
import Training_List from './screens/training/training_list';
import Completed_Training from './screens/training/completed_training';
import Settings from './screens/settings/settings';
import Forms from './screens/form/forms';
import Create_Forms from './screens/form/create_form';
import Edit_Forms from './screens/form/edit_forms';
import Form_Details from './screens/form/form_detail';
import Form_Logs from './screens/form/form_logs';
import SubmittedForms from './screens/form/submitted_forms';
import Impact_Measurement from './screens/impact_measurement/impact_measurement';
import Create_Impact_Measurement from './screens/impact_measurement/create_imapct_measurement';
import Edit_Impact_Measurement from './screens/impact_measurement/edit_impact_measurement';
import Impact_Measurement_Details from './screens/impact_measurement/impact_measurement_details';
import Impact_Measurement_Logs from './screens/impact_measurement/impact_measurement_logs';
import Communities from './screens/community/communities';
import Create_Community from './screens/community/create_community';
import Edit_Community from './screens/community/edit_community';
import Community_Detail from './screens/community/community_detail';
import Community_List from './screens/community/community_list';
import Edit_Oganization from './screens/organization/edit_organization';
import Create_Organization from './screens/organization/create_organization';
import Maintenance_Checklist from './screens/maintenance_checklist/maintenance_checklist';
import Create_Maintenance_Checklist from './screens/maintenance_checklist/create_maintenance_checklist';
import Edit_Maintenance_Checklist from './screens/maintenance_checklist/edit_maintenance_checklist';
import Maintenance_Checklist_Details from './screens/maintenance_checklist/maintenance_checklist_details';
import Maintenance_Checklist_Logs from './screens/maintenance_checklist/maintenance_checklist_log';
import Logs from './screens/logs/logs';
import Stats from './screens/stats/stats';
import Notifications from './screens/notifications/notification';
import Edit_Diagnostic from './screens/diagnostic/edit_diagnostic';
import Create_Form_types from './screens/form/create_form_type';
import Create_Client from './screens/user_management/create_clients';
import Clients_List from './screens/user_management/clients_list';
import Super_Admin_List from './screens/user_management/super_admin_list';
import Create_Super_Admin from './screens/user_management/create_super_admin';
import Edit_User_Management from './screens/user_management/edit_management_users';
import Edit_Client from './screens/user_management/edit_client';
import Edit_Super_Admin from './screens/user_management/edit_super_admin';
import Maintenance_Checklist_Response_Detail from './screens/maintenance_checklist/maintenance_checklist_response_details';
import Maintenance_Checklist_Response from './screens/maintenance_checklist/maintenance_checklist_response';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path='/'>
              {/* mapping all routes from / to other pages / routes */}
              <Route index element={<SignUp/>}/>
              <Route path='login' element={<Login/>}/>
              <Route path='forgotPassword' element={<ForgotPassword/>}/>
              {/* mapping all routes from /home to other pages / routes as nested */}
              <Route path='home'>
                <Route index element={<Home/>}/>

                <Route path='organization' element={<OrganizationDashboard/>}/>
                <Route path='organization/create' element={<Create_Organization/>}/>
                <Route path='organization/edit/:organizationId' element={<Edit_Oganization/>}/>
                <Route path='organization/detail/:organizationId' element={<OrganizationDetails/>}/>
                <Route path='organization/list/:organizationId' element={<OrganizationList/>}/>

                <Route path='diagnostic' element={<Diagnostic/>}/>
                <Route path='diagnostic/:diagnosticId' element={<Diagnostic_Log_Detail/>}/>
                <Route path='diagnostic/detail/:diagnosticId' element={<Diagnostic_Log_Detail/>}/>
                <Route path='diagnostic/list/:diagnosticId' element={<Diagnostic_Log/>}/>
                <Route path='diagnostic/create' element={<Create_Diagnostic/>}/>
                <Route path='diagnostic/edit/:diagnosticId' element={<Edit_Diagnostic/>}/>
                <Route path='diagnostic/response' element={<Response_Diagnostic/>}/>

                <Route path='training' element={<Training/>}/>
                <Route path='training/create' element={<Create_Training/>}/>
                <Route path='training/edit/:trainingId' element={<Edit_Training/>}/>
                <Route path='training/detail/:trainingId' element={<Training_Details/>}/>
                <Route path='training/list/:trainingId' element={<Training_List/>}/>
                <Route path='training/completed' element={<Completed_Training/>}/>

                <Route path='user_management' element={<User_Management/>}/>
                <Route path='user_management/detail/:user_managementId' element={<Profile/>}/>
                <Route path='user_management/list/users' element={<User_Management_List/>}/>
                <Route path='user_management/list/clients' element={<Clients_List/>}/>
                <Route path='user_management/list/super_admins' element={<Super_Admin_List/>}/>
                <Route path='user_management/edit/client/:user_id' element={<Edit_Client/>}/>
                <Route path='user_management/edit/user/:user_id' element={<Edit_User_Management/>}/>
                <Route path='user_management/edit/super_admin/:user_id' element={<Edit_Super_Admin/>}/>
                <Route path='user_management/create' element={<Create_User_Management/>}/>
                <Route path='user_management/create_client' element={<Create_Client/>}/>
                <Route path='user_management/create_super_admin' element={<Create_Super_Admin/>}/>

                <Route path='impact_measurement' element={<Impact_Measurement/>}/>
                <Route path='impact_measurement/:impact_measurementId' element={<OrganizationDetails/>}/>
                <Route path='impact_measurement/create' element={<Create_Impact_Measurement/>}/>
                <Route path='impact_measurement/edit/:impact_measurementId' element={<Edit_Impact_Measurement/>}/>
                <Route path='impact_measurement/detail/:impact_measurementId' element={<Impact_Measurement_Details/>}/>
                <Route path='impact_measurement/list/:impact_measurementId' element={<Impact_Measurement_Logs/>}/>

                <Route path='maintenance_checklist' element={<Maintenance_Checklist/>}/>
                <Route path='maintenance_checklist/:maintenance_checklistId' element={<Maintenance_Checklist/>}/>
                <Route path='maintenance_checklist/create' element={<Create_Maintenance_Checklist/>}/>
                <Route path='maintenance_checklist/edit/:maintenance_checklistId' element={<Edit_Maintenance_Checklist/>}/>
                <Route path='maintenance_checklist/detail/:maintenance_checklistId' element={<Maintenance_Checklist_Details/>}/>
                <Route path='maintenance_checklist/list/:maintenance_checklistId' element={<Maintenance_Checklist_Logs/>}/>
                <Route path='maintenance_checklist/response_details/:maintenance_checklist_response_Id' element={<Maintenance_Checklist_Response_Detail/>}/>
                <Route path='maintenance_checklist/response/:maintenance_checklistId' element={<Maintenance_Checklist_Response/>}/>

                <Route path='community' element={<Communities/>}/>
                <Route path='community/create' element={<Create_Community/>}/>
                <Route path='community/edit/:communityId' element={<Edit_Community/>}/>
                <Route path='community/detail/:communityId' element={<Community_Detail/>}/>
                <Route path='community/list/:communityId' element={<Community_List/>}/>

                <Route path='forms' element={<Forms/>}/>
                <Route path='forms/form_type/create' element={<Create_Form_types/>}/>
                <Route path='forms/create' element={<Create_Forms/>}/>
                <Route path='forms/edit/:formsId' element={<Edit_Forms/>}/>
                <Route path='forms/detail/:formsId' element={<Form_Details/>}/>
                <Route path='forms/list/:formsId' element={<Form_Logs/>}/>
                <Route path='forms/forms_submitted' element={<SubmittedForms/>}/>

                <Route path='profile' element={<Profile/>}/>
                <Route path='profile/edit/:profileId' element={<Edit_Profile/>}/>
                <Route path='profile/detail/:profileId' element={<OrganizationDetails/>}/>

                <Route path='system_type' element={<System_Type/>}/>
                <Route path='system_type/create' element={<Create_System_Type/>}/>
                <Route path='system_type/edit/:system_typeId' element={<Edit_System_Type/>}/>
                <Route path='system_type/detail/:system_typeId' element={<System_Type_Details/>}/>
                <Route path='system_type/list/:system_typeId' element={<System_Type_List/>}/>

                <Route path='notification' element={<Notifications/>}/>

              </Route>

              <Route path='admin'>
                <Route index element={<Administrator/>}/>

                <Route path='organization' element={<OrganizationDashboard/>}/>
                <Route path='organization/create' element={<Create_Organization/>}/>
                <Route path='organization/edit/:organizationId' element={<Edit_Oganization/>}/>
                <Route path='organization/detail/:organizationId' element={<OrganizationDetails/>}/>
                <Route path='organization/list/:organizationId' element={<OrganizationList/>}/>

                <Route path='diagnostic' element={<Diagnostic/>}/>
                <Route path='diagnostic/:diagnosticId' element={<Diagnostic_Log_Detail/>}/>
                <Route path='diagnostic/detail/:diagnosticId' element={<Diagnostic_Log_Detail/>}/>
                <Route path='diagnostic/list/:diagnosticId' element={<Diagnostic_Log/>}/>
                <Route path='diagnostic/create' element={<Create_Diagnostic/>}/>
                <Route path='diagnostic/edit/:diagnosticId' element={<Edit_Diagnostic/>}/>
                <Route path='diagnostic/response' element={<Response_Diagnostic/>}/>

                <Route path='training' element={<Training/>}/>
                <Route path='training/create' element={<Create_Training/>}/>
                <Route path='training/edit/:trainingId' element={<Edit_Training/>}/>
                <Route path='training/detail/:trainingId' element={<Training_Details/>}/>
                <Route path='training/list/:trainingId' element={<Training_List/>}/>
                <Route path='training/completed' element={<Completed_Training/>}/>

                <Route path='user_management' element={<User_Management/>}/>
                <Route path='user_management/detail/:user_managementId' element={<Profile/>}/>
                <Route path='user_management/list/users' element={<User_Management_List/>}/>
                <Route path='user_management/list/clients' element={<Clients_List/>}/>
                <Route path='user_management/list/super_admins' element={<Super_Admin_List/>}/>
                <Route path='user_management/edit/client/:user_id' element={<Edit_Client/>}/>
                <Route path='user_management/edit/user/:user_id' element={<Edit_User_Management/>}/>
                <Route path='user_management/edit/super_admin/:user_id' element={<Edit_Super_Admin/>}/>
                <Route path='user_management/create' element={<Create_User_Management/>}/>
                <Route path='user_management/create_client' element={<Create_Client/>}/>
                <Route path='user_management/create_super_admin' element={<Create_Super_Admin/>}/>

                <Route path='impact_measurement' element={<Impact_Measurement/>}/>
                <Route path='impact_measurement/:impact_measurementId' element={<OrganizationDetails/>}/>
                <Route path='impact_measurement/create' element={<Create_Impact_Measurement/>}/>
                <Route path='impact_measurement/edit/:impact_measurementId' element={<Edit_Impact_Measurement/>}/>
                <Route path='impact_measurement/detail/:impact_measurementId' element={<Impact_Measurement_Details/>}/>
                <Route path='impact_measurement/list/:impact_measurementId' element={<Impact_Measurement_Logs/>}/>

                <Route path='maintenance_checklist' element={<Maintenance_Checklist/>}/>
                <Route path='maintenance_checklist/:maintenance_checklistId' element={<Maintenance_Checklist/>}/>
                <Route path='maintenance_checklist/create' element={<Create_Maintenance_Checklist/>}/>
                <Route path='maintenance_checklist/edit/:maintenance_checklistId' element={<Edit_Maintenance_Checklist/>}/>
                <Route path='maintenance_checklist/detail/:maintenance_checklistId' element={<Maintenance_Checklist_Details/>}/>
                <Route path='maintenance_checklist/list/:maintenance_checklistId' element={<Maintenance_Checklist_Logs/>}/>
                <Route path='maintenance_checklist/response_details/:maintenance_checklist_response_Id' element={<Maintenance_Checklist_Response_Detail/>}/>
                <Route path='maintenance_checklist/response/:maintenance_checklistId' element={<Maintenance_Checklist_Response/>}/>
                
                <Route path='community' element={<Communities/>}/>
                <Route path='community/create' element={<Create_Community/>}/>
                <Route path='community/edit/:communityId' element={<Edit_Community/>}/>
                <Route path='community/detail/:communityId' element={<Community_Detail/>}/>
                <Route path='community/list/:communityId' element={<Community_List/>}/>

                <Route path='forms' element={<Forms/>}/>
                <Route path='forms/create' element={<Create_Forms/>}/>
                <Route path='forms/edit/:formsId' element={<Edit_Forms/>}/>
                <Route path='forms/detail/:formsId' element={<Form_Details/>}/>
                <Route path='forms/list/:formsId' element={<Form_Logs/>}/>
                <Route path='forms/forms_submitted' element={<SubmittedForms/>}/>

                <Route path='profile' element={<Profile/>}/>
                <Route path='profile/edit/:profileId' element={<Edit_Profile/>}/>
                <Route path='profile/detail/:profileId' element={<OrganizationDetails/>}/>

                <Route path='system_type' element={<System_Type/>}/>
                <Route path='system_type/create' element={<Create_System_Type/>}/>
                <Route path='system_type/edit/:system_typeId' element={<Edit_System_Type/>}/>
                <Route path='system_type/detail/:system_typeId' element={<System_Type_Details/>}/>
                <Route path='system_type/list/:system_typeId' element={<System_Type_List/>}/>

                <Route path='notification' element={<Notifications/>}/>

              </Route>

              <Route path='settings'>
                <Route index element={<Settings/>}/>
                <Route path='settings/:settingsId' element={<OrganizationDetails/>}/>
                <Route path='settings/detail/:settingsId' element={<OrganizationDetails/>}/>
              </Route>

              <Route path='logs'>
                <Route index element={<Logs/>}/>
              </Route>

              <Route path='stats'>
                <Route index element={<Stats/>}/>
              </Route>

              <Route path='admin'>
                
              </Route>

            </Route> 
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
