import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Organization } from "../../screens/authentication/authentication_interfaces";

//creating a datatype fot the expected props interface for the table components types



//creating a props interface for the table components types
interface tableProps {
    data: Organization [] | null;
}

const OrganizationTableComponent : React.FC<tableProps> = ({data}) => {
    return (
        <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Oragnization ID</TableCell>
            <TableCell className="tableCell">Oragnization Name</TableCell>
            <TableCell className="tableCell">Oragnization Logo</TableCell>
            <TableCell className="tableCell">Contact</TableCell>
            <TableCell className="tableCell">Status</TableCell>
            <TableCell className="tableCell">Created On</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {data!.map((row) => (
            <TableRow key={row.organization_id}>
              <TableCell className="tableCell">{row.organization_id}</TableCell>
              <TableCell className="tableCell">{row.name}</TableCell>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img src={row.organization_logo} alt="" className="image" />
                  {/* {row.product} */}
                </div>
              </TableCell>
              <TableCell className="tableCell">{row.phone_number}</TableCell>
              <TableCell className="tableCell">
                <span className={`status ${row.active}`}>{row.active ? 'Active' : 'Inactive'}</span>
              </TableCell>
              <TableCell className="tableCell">{new Date(row.created_at).toISOString().split('T')[0] || ""}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    );
}

export default OrganizationTableComponent;