
import DashboardCard from "../../components/card/card";
import Custom_Text from "../../components/custom_text";
import SectionHeader from "../../components/custom_text/section_header";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import Sidebar from "../../components/sidebars/sidebar";
import './maintenance_checklist.scss'
import SideBarMaintenanceChecklist from "./sidebar_maintenance_checklist/sidebarmaintenance";
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';
import Organization_Details_Card from "../../components/organization_card_details";

import React, { FC, useCallback, useMemo, useState } from 'react';
import MaterialReactTable, {
  MaterialReactTableProps,
  MRT_Cell,
  MRT_ColumnDef,
  MRT_Row,
} from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { data, states, Person } from './makeData';

import { styled, useTheme } from '@mui/material/styles';

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link, useParams } from 'react-router-dom';
import logoImage from './images/logo.png';
import { Grid, Select, FormHelperText, InputLabel, SelectChangeEvent } from '@mui/material';
import TableList from '../../components/table/table';
import Details_Card from '../../components/card/card_details';
import { Community, MaintenanceChecklist, Maintenance_Checklist_Steps, Maintenance_Checklist_response, Organization, SystemTypes, User } from '../authentication/authentication_interfaces';
import {useEffect} from 'react';
import authentication_services from "../authentication/authentication_services";
import maintenance_checklist_service from "./maintenance_checklist_service";
import MaintenanceChecklistStepTable from "../../components/table/maintenance_checklist_steps_table";
import FormControl from '@mui/material/FormControl';
import organization_services from "../organization/organization_services";
import MaintenanceChecklistResponseTable from '../../components/table/maintenance_checklist_response_table';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Maintenance_Checklist_Details : React.FC = () => {

  const { maintenance_checklistId } = useParams();
  console.log("maintenance_checklistId " + maintenance_checklistId);
  
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [error, setUserError] = React.useState(false);
  const [fullName, setFullName] = useState<string>();
  const [userId, setUserId] = useState([]);
  const [bearer, setBearerToken] = useState<string>('');
  const [community_list_data, setCommunityListData] = useState<Community[] | null>([]);
  const [data, setOrganizationData] = useState<Organization | null>();
  const [dataList, setOrganizationListData] = useState<Organization[] | null>();
  const [systemData, setSystemTypeData] = useState<SystemTypes | null>();
  const [userData, setUserData] = useState<User | null>();
  const [userCreatorData, setUserCreatorData] = useState<User | null>();
  const [organization_name, setOrganization] = React.useState('');

  const [maintenance_checklist_list_data, setMaintenanceChecklistData] = useState<MaintenanceChecklist | null>();
  const [maintenance_checklist_step_list_data, setMaintenanceChecklistStepListData] = useState<Maintenance_Checklist_Steps[] | null>([]);
  const [maintenance_checklist_response_list_data, setMaintenanceChecklistResponseListData] = useState<Maintenance_Checklist_response[] | null>([]);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('userId') || '{}');
    const user_fullName = JSON.parse(localStorage.getItem('user_fullName') || '{}');
    const bearerToken = JSON.parse(localStorage.getItem('bearerToken') || '{}');
    console.timeLog(bearerToken);

    //get user details
    authentication_services.getUserDetails(userId, bearerToken).then((response) => {

      const status = response['data']['status'];
      const message = response['data']['message'];
      const userId = response['data']['data']["user_id"];
      console.log("user id " + userId);

      setUserData(response.data.data);
      console.log('User First Name ' + response['data']['data']['first_name']);
      console.log('User API status ' + response['data']['status']);
      
      if(status === "Success"){

        setUserId(userId);
        setFullName(user_fullName);
        setBearerToken(bearerToken);
         
      }

      }).catch(function (error) {

        // setErrorMessage(error.toString());
        console.log('error ' + error.toJSON());
        console.log('error message ' + error.toString());
        // setopenErrorModal(true);

    });
    
    

    //Get all system type list 
    // systemtype_services.getAllSystemTypeDropDownList()
    // .then((response) => {

    //   console.log('API call response status ' + response.status);
    //   setSystemTypeData(response.data.data);
    //   console.log('Organization Name ' + response['data']['data'][0]['name']);
    //   console.log('Organization Name ' + response['data']['status']);

    // }).catch((error) => {
    //   console.log('error ' + error.toJSON());
    //   console.log('error message ' + error.toString());
    // });
    
    // //get list of communities
    // community_services.getAllCommunityList(bearerToken)
    // .then((response) => {

    //   console.log('API call response status ' + response.status);
    //   console.log(response.data.data)
    //   setCommunityListData(response.data.data);
    //   console.log('Community Name ' + response['data']['data'][0]['community_name']);

    // }).catch((error) => {
    //   console.log('error ' + error.toJSON());
    //   console.log('error message ' + error.toString());
    // });

    maintenance_checklist_service.getMaintenanceChecklist(bearerToken, parseInt(maintenance_checklistId!))
    .then((response) => {

      console.log('API call response status ' + response.status);
      console.log(response.data.data)
      setMaintenanceChecklistData(response.data.data);
      console.log('Maintenance Checklist Name ' + response['data']['data']['title']);
      setMaintenanceChecklistStepListData(response['data']['data']['maintenanceChecklistSteps']);
      setUserCreatorData(response['data']['data']['user']);
      setOrganizationData(response['data']['data']['organization']);
      setSystemTypeData(response['data']['data']['system_type']);

    }).catch((error) => {
      console.log('error ' + error.toJSON());
      console.log('error message ' + error.toString());
    });

    //Get all organizations list 
    organization_services.getAllOrganizationsDropDownList()
    .then((response) => {

      console.log('API call response status ' + response.status);
      setOrganizationListData(response.data.data);
      console.log('Organization Name ' + response['data']['data'][0]['name']);
      console.log('Organization Name ' + response['data']['status']);

    }).catch((error) => {
      console.log('error ' + error.toJSON());
      console.log('error message ' + error.toString());
    });

  }, [bearer]); 

  const handleDropDowOrganizationChange = (event: SelectChangeEvent) => {
    setOrganization(event.target.value);
  };

    const [display_organization_name, setDisplayOrganizationName] = React.useState<string>('');
    const [display_organization_id, setDisplayOrganizationId] = React.useState<number>();
    const [errorMessage, setErrorMessage] = React.useState();
    const [communityData, setcommunityData] = React.useState<Community | null>();
 
    useEffect(() => {
      getOrganizationName(organization_name);
     }, [organization_name]);
   
    function getOrganizationName(str: string) {
       var values = str.split(" ");
       var id = values.shift();
       var name = values.join(' ');
       setDisplayOrganizationName(name);
       setDisplayOrganizationId(parseInt(id!));
 
 
       console.log("Organization Id " + id);
       console.log("Organization Name " + name);

       maintenance_checklist_service.getOrganizationMaintenanceChecklistResponse(bearer, parseInt(maintenance_checklistId!), parseInt(id!))
      .then((response) => {

        console.log('API call response status ' + response.status);
        console.log(response.data.data)
        setMaintenanceChecklistData(response.data.data);
        console.log('Maintenance Checklist Response List ' + response['data']['data'][0]["maintenance_checklist_step_id"]);
        setMaintenanceChecklistResponseListData(response['data']['data']);
        // setUserCreatorData(response['data']['data']['user']);
        // setOrganizationData(response['data']['data']['organization']);
        // setSystemTypeData(response['data']['data']['system_type']);

      }).catch((error) => {
        console.log('error ' + error.toJSON);
        console.log('error message ' + error.toString());
      });

    }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open} style={{width: "100%"}}>
        <DrawerHeader />
        <DrawerHeader />
        

        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          <Grid item xs={12} md={12} sm={12} lg={12}>
            <SectionHeader sectionTitle={'MAINTENANCE CHECKLIST'} link='' />
          </Grid>
          <div style={{height: "20px"}}></div>
          <Grid item xs={12} md={4} sm={6} lg={6}>
            <NavCard_Community cardContent={20} cardTitle={'Create Maintenance Checklist'} image={createImage} link={"/home/maintenance_checklist/create"} />
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={6}>
            <NavCard_Community cardContent={20} cardTitle={'Maintenance Checklist List'} image={listImage} link={"/home/maintenance_checklist/list/:maintenance_checklistId"} />
          </Grid>
          {/* <Grid item xs={12} md={4} sm={6} lg={3}>
            <NavCard_Community cardContent={20} cardTitle={'Edit Maintenance Checklist'} image={editImage} link={"/home/maintenance_checklist/edit/:maintenance_checklistId"} />
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={3}>
            <NavCard_Community cardContent={20} cardTitle={'Maintenance Checklist Details'} image={detailImage} link={"/home/maintenance_checklist/detail/:maintenance_checklistId"} />
          </Grid> */}
          
          {/* <Grid item xs={12} md={4} sm={6} lg={4}>
            <Checklist_DashboardCard cardContent={20} cardTitle={'Total Maintenance Checklist'} image={allForms} link={""}/>
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={4}>
            <Checklist_DashboardCard cardContent={40} cardTitle={'Pending Maintenance Checklist'} image={pendingForms} link={""}/>
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={4}>
            <Checklist_DashboardCard cardContent={230} cardTitle={'Completed Maintenance Checklist'} image={submitted_forms} link={""}/>
          </Grid> */}

          <Grid item xs={12} md={6} sm={12} lg={6}>
            {data != null ? <Details_Card 
                organizationName={data!.name} 
                email={data!.email} 
                image={data!.organization_logo} 
                phoneNumber={data!.phone_number} 
                address={"Lagos, Island"} 
                country={"Nigeria"}
                catrdTile={"Organization Information"}
            /> : "" }
          </Grid>

          <Grid item xs={12} md={6} sm={12} lg={6}>
            <div className="topLevel">
                <div className="leftContent">
                  <h5>Select an organization to view their response to the maintenance checklist</h5>
                  {/* dropdown component */}
                  <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} >
                    <InputLabel id="demo-simple-select-helper-label">Select Organizations</InputLabel>
                    <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper-label"
                    value={organization_name}
                    label="Select Organizations"
                    onChange={handleDropDowOrganizationChange}
                    renderValue={(value) =>  value }
                      >
                        {dataList ? dataList.map((organizations) => {
                          return <MenuItem value={`${organizations.organization_id} ${organizations.name}`}>{organizations.name}</MenuItem>
                        }) : null}
                      </Select>
                    {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                  </FormControl>
                </div>
            </div>
          </Grid>

          <Grid item xs={12} md={12} sm={12} lg={12}>
            <div className="topLevel">
                <div className="leftContent">
                  {/* <div className="editButton">Edit</div> */}
                  <h3 className="titleContents">Maintenance Checklist Information</h3>
                  <hr style={{
                      height: "2px",
                      backgroundColor: '#dddddd',
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: "none",
                  }}/>
                  <div className="itemContents">
                    <img
                      src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"
                      alt=""
                      className="itemContentsImg"
                    />
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      { systemData != null ? <Grid item xs={12} md={6} sm={6} lg={4}>
                          <div className="detailsContents">
                            <h3 className="itemTitleContents">{systemData!.name}</h3>
                            <div className="detailItemContents">
                              <span className="itemKeyContents">Description:</span>
                              <span className="itemValueContents">{systemData!.description}</span>
                            </div>
                            <div className="detailItemContents">
                              <span className="itemKeyContents">Date Created:</span>
                              <span className="itemValueContents">{systemData!.created_at}</span>
                            </div>
                            
                          </div>
                        </Grid> : ""}
                      
                      <Grid item xs={12} md={6} sm={6} lg={4}>
                        {
                          userCreatorData != null ? <div className="detailsContents" style={{"marginLeft":"0px"}}>
                            <h3 className="itemTitleContents">CheckList Created by</h3>
                            <div className="detailItemContents">
                              <span className="itemKeyContents">Name:</span>
                              <span className="itemValueContents">{userCreatorData!.first_name} {userCreatorData!.last_name}</span>
                            </div>
                            <div className="detailItemContents">
                              <span className="itemKeyContents">Phone:</span>
                              <span className="itemValueContents">{userCreatorData!.phone_number}</span>
                            </div>
                            <div className="detailItemContents">
                              <span className="itemKeyContents">Email:</span>
                              <span className="itemValueContents">
                                {userCreatorData!.email}
                              </span>
                            </div>
                            
                          </div> : ""
                        }
                      </Grid>
                    </Grid>
                    


                  </div>

                  {/* <hr style={{
                      height: "2px",
                      backgroundColor: '#dddddd',
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: "none",
                  }}/> */}

                    {
                      maintenance_checklist_list_data != null ? <div className="itemContents" style={{
                          "marginLeft":"0px", 
                          "border": "0.5px solid lightgray",
                          "padding":"20px", "borderRadius":"10px",
                          "marginTop":"10px",
                          "marginBottom":"10px",
                        }}>
                        <div className="detailsContents" style={{"width":"100%", }}>
                          <h3 className="itemTitleContents">{maintenance_checklist_list_data!.title}</h3>
                          <hr style={{
                            height: "0.5px",
                            backgroundColor: '#dddddd',
                            marginTop: '10px',
                            marginBottom: '10px',
                            border: "none",
                          }}/>
                          <div className="detailItemContents">
                            <span className="itemKeyContents">Recommended frequency of maintenance:</span>
                            <span className="itemValueContents">{maintenance_checklist_list_data!.recommended_frequency_of_maintenance}</span>
                          </div>
                          <div className="detailItemContents">
                            <span className="itemKeyContents">Number of Steps:</span>
                            <span className="itemValueContents" style={{"color":"green", }}>{maintenance_checklist_list_data!.number_of_steps}</span>
                          </div>
                          <div className="detailItemContents">
                            <span className="itemKeyContents">Description:</span>
                            <span className="itemValueContents">{maintenance_checklist_list_data!.description}</span>
                          </div>
                          <div className="detailItemContents">
                            <span className="itemKeyContents">Published:</span>
                            <span className="itemValueContents">{maintenance_checklist_list_data!.isPublished ? "Published" : "Not Published"}</span>
                          </div>
                          <div className="detailItemContents">
                            <span className="itemKeyContents">Date created:</span>
                            <span className="itemValueContents">
                              {maintenance_checklist_list_data!.created_at}
                            </span>
                          </div>
                          
                        </div>
                      </div> : ""
                    }

                  {/* <div className="itemContents" 
                    style={{
                      "marginLeft":"20px", 
                      "border": "0.5px solid lightgray",
                      "padding":"20px", "borderRadius":"10px",
                      "marginTop":"10px",
                      "marginBottom":"10px",
                    }}>
                    <div className="detailsContents" style={{"width":"100%", }}>
                      <h1 className="itemTitleContents">Table Name</h1>
                      <hr style={{
                        height: "0.5px",
                        backgroundColor: '#dddddd',
                        marginTop: '10px',
                        marginBottom: '10px',
                        border: "none",
                        }}
                      /> 

                      
                                      
                              
                      </div>
                    </div> */}

                </div>
            </div>
          </Grid>

          <Grid item xs={12} md={12} sm={12} lg={12}>
            <SectionHeader sectionTitle={'CHECKLISTS STEPS'} link='' />
            <div style={{height: "20px"}}></div>
            <div className="listContainer">
              <div className="listTitle">Maintenance Checklist Step Lists</div>
              {<MaintenanceChecklistStepTable data={maintenance_checklist_step_list_data} isFromDashboard={false}/>}
            </div>
          </Grid>
          
          {
            maintenance_checklist_response_list_data != null ? 
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <SectionHeader sectionTitle={'CHECKLISTS STEPS RESPONSE'} link='' />
              <div style={{height: "20px"}}></div>
              <div className="listContainer">
                <div className="listTitle">Maintenance Checklist Step Response Lists</div>
                {<MaintenanceChecklistResponseTable data={maintenance_checklist_response_list_data}/>}
              </div>
            </Grid> : ""
          }

        </Grid>
       
      </Main>
    </Box>
  );
  

};


export default Maintenance_Checklist_Details;