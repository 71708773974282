import React, { useEffect, useState } from "react";
import './home.scss'
import NavBar from '../../components/navbar/navbar';
import Sidebar from "../../components/sidebars/sidebar";
import Chart from "../../components/chart/chart";
import { Chart as ChartJS, registerables } from 'chart.js';
import Featured from "../../components/featured/featured";
import TableList from "../../components/table/table";
import CommunityTableList from "../../components/table/Community_Table";
import OrganizationTableList from "../../components/table/Organization_Table";

import DashboardPieChart from "../../components/dashboard_cards/dashboard_card_pie";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import DashboardCard from "../../components/card/card";
import SectionHeader from "../../components/custom_text/section_header";
import DashboardBarChart from "../../components/dashboard_cards/DashboardBarChart";
import DashboardCardOverall from "../../components/dashboard_cards/DashboardCardOverall";
import ProgressBarChart from "../../components/progress_bar/progress_bar_chart";


import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';
import logoImage from './images/logo.png';
import { Link } from "react-router-dom";
import axios from 'axios';
import Auth_Services from "../authentication/authentication_services";
import Community_Services from "../community/community_services";
import organization_services from "../organization/organization_services";
import { Community, MaintenanceChecklist, Organization, User } from "../authentication/authentication_interfaces";
import maintenance_checklist_service from "../maintenance_checklist/maintenance_checklist_service";
import MaintenanceChecklistTable from "../../components/table/maintenance_checklist_table";

ChartJS.register(...registerables);

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));



const Home : React.FC = () => {

  const [fullName, setFullName] = useState<string>();
  const [userId, setUserId] = useState([]);
  const [bearer, setBearerToken] = useState<string>();

  //Api call state management
  const [userData, setUserData] = useState<User | null>();
  const [community_list_data, setCommunityListData] = useState<Community[] | null>([]);
  const [organization_list_data, setOrganizationListData] = useState<Organization[] | null>([]);
  const [maintenance_checklist_list_data, setMaintenanceChecklistListData] = useState<MaintenanceChecklist[] | null>([]);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('userId') || '{}');
    const user_fullName = JSON.parse(localStorage.getItem('user_fullName') || '{}');
    const bearerToken = JSON.parse(localStorage.getItem('bearerToken') || '{}');

    //get user details
    Auth_Services.getUserDetails(userId, bearerToken).then((response) => {

      const status = response['data']['status'];
      const message = response['data']['message'];
      const userId = response['data']['data']["user_id"];
      console.log("user id " + userId);

      setUserData(response.data.data);
      console.log('User First Name ' + response['data']['data']['first_name']);
      console.log('User API status ' + response['data']['status']);
      console.log('User API Last Name ' + userData?.last_name);

      if(status === "Success"){

        setUserId(userId);
        setFullName(user_fullName);
        setBearerToken(bearerToken);
         
      }

      }).catch(function (error) {

        // setErrorMessage(error.toString());
        console.log('error ' + error.toJSON());
        console.log('error message ' + error.toString());
        // setopenErrorModal(true);

    });
    //Get all communities 
    Community_Services.getAllCommunityList(bearerToken)
    .then((response) => {

      console.log('API call response status ' + response.status);
      console.log(response.data.data)
      setCommunityListData(response.data.data);
      console.log('Community Name ' + response['data']['data'][0]['community_name']);

    }).catch((error) => {
      console.log('error ' + error.toJSON());
      console.log('error message ' + error.toString());
    });
    //Get all organization 
    organization_services.getAllOrganizationList(bearerToken)
    .then((response) => {

      console.log('API call response status ' + response.status);
      setOrganizationListData(response.data.data);
      console.log('Organization Name ' + response['data']['data'][0]['name']);

    }).catch((error) => {
      console.log('error ' + error.toJSON());
      console.log('error message ' + error.toString());
    });

    maintenance_checklist_service.getAllMaintenanceChecklistDropDownList()
    .then((response) => {

      console.log('API call response status ' + response.status);
      console.log(response.data.data)
      setMaintenanceChecklistListData(response.data.data);
      console.log('Maintenance Checklist Name ' + response['data']['data'][0]['title']);

    }).catch((error) => {
      console.log('error ' + error.toJSON);
      console.log('error message ' + error.toString());
    });



  }, []);


  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

const individualsTrainedPerSubjectBarData = {
  labels: ['Water Management', 'Sanitation and Hygiene (Kids)', 'Sanitation and Hygiene (Adults)', "Bole Hole Maintenance"],
  datasets: [
    {
      label: 'Individuals Trained per Subject',
      backgroundColor: "#4EC7F2",
      borderColor: "#4EC7F2",
      borderWidth: 2,
      data: [34, 2, 9, 100]
    }
  ]
}

const communitySystemStatusPieData = {
  labels:["Functioning", "Maintenance Checklist Overdue", "Unresolved Diagnostics", "Maintenance & Diagnostic needs"],
  datasets:[{
   data: [300, 50, 100, 40],
   backgroundColor: [
      "#79B82C",
      "#4EC7F2",
      "#FFC870",
      "#A8B3C5",
      "#616774"
    ],
   borderWidth: 1
  }]
};

const systemTypeStatus = {
  labels:["Bole Hole System", "Rainwater System", "Pipe Water System", "Pumping Machine System"],
  datasets:[{
   data: [30, 10, 15, 40],
   backgroundColor: [
      "#79B82C",
      "#4EC7F2",
      "#FFC870",
      "#A8B3C5",
      "#616774"
    ],
   borderWidth: 1
  }]
};

const maintenanceStatusPieData = {
  labels:["Compliant", "Overdue"],
  datasets:[{
   data: [300, 50],
   backgroundColor: [
      "#79B82C",
      "#FFB200"
    ],
   borderWidth: 1
  }]
};

const diagnosticStatusPieData = {
  labels:["Functioning", "Pending Maintenance", "Issue Reported", ],
  datasets:[{
   data: [300, 100, 50],
   backgroundColor: [
      "#79B82C",
      "#FFB200",
      // "#5AD3D1",
      // "#531CBC",
      "#FF0000",
    ],
   borderWidth: 1
  }]
};
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div className="home">
          
          <div className="homeContainer">
            
            <SectionHeader sectionTitle={'OVERVIEW'} link='' />
            <div className="cardPadding">
              <DashboardCard cardContent={organization_list_data?.length} cardTitle={'Organizations'} image={organizationImage} link={""}/>
              <DashboardCard cardContent={community_list_data?.length} cardTitle={'Communities'} image={communityImage} link={""}/>
              <DashboardCard cardContent={230} cardTitle={'Management Users'} image={managementUsersImage} link={""}/>
              <DashboardCard cardContent={130} cardTitle={'People Trained'} image={trainedPeopleImage} link={""}/>
            </div>
            <p>{fullName}</p>
            <SectionHeader sectionTitle={'SYSTEM OVERVIEW'} link='' />
            <div className="dashboardPieChartContainer">
            <DashboardPieChart data={systemTypeStatus} title='Active System Types' />
              {/* <DashboardPieChart data={communitySystemStatusPieData} title='Community System Status' /> */}
              <DashboardPieChart data={maintenanceStatusPieData} title='Maintenance Status' />
              <DashboardPieChart data={diagnosticStatusPieData} title='Diagnostic Status' />
            </div>
            {/* <SectionHeader sectionTitle={'SYSTEM OVERVIEW'} link='' /> */}
            <div className="cardPadding">
              <DashboardBarChart data={individualsTrainedPerSubjectBarData} title='Community Training' />
              <DashboardCardOverall cardTitle={"Overall Knowledge gained across all communities & all trainings"} cardContent={4} />
            </div>
            {/* <SectionHeader sectionTitle={'SYSTEM CHARTS'} link='' />
            <div className="charts">
              <Featured title="clovis" subtitle="saintiano" />
              <Chart title="IMPACT MEASUREMENT" aspect={3 / 1} />
            </div> */}
            <div className="cardPadding">
              <ProgressBarChart progressBarListTitle={20} progressBarTitle={'IMPACT MEASUREMENT'} 
              ratingFirst={20} ratingSecond={50} ratingThird={30} ratingForth={40} />
            </div>
            <SectionHeader sectionTitle={'LISTS'} link='' />
            <div className="listContainer">
              <div className="listTitle">Organizations</div>
              <OrganizationTableList data={organization_list_data}/>
            </div>
            <div className="listContainer">
              <div className="listTitle">Communities</div>
              <CommunityTableList data={community_list_data}/>
            </div>
            <div className="listContainer">
              <div className="listTitle">Maintenance Checklist</div>
              <MaintenanceChecklistTable data={maintenance_checklist_list_data}/>
            </div>
            <div className="listContainer">
              <div className="listTitle">Diagnostics</div>
              <TableList data={[]}/>
            </div>
            <div className="listContainer">
              <div className="listTitle">Forms</div>
              <TableList data={[]}/>
            </div>
            
          </div>
        </div>
       
      </Main>
    </Box>
  );
};

export default Home;