import React from "react";
import Custom_Text from "../../components/custom_text";
import SectionHeader from "../../components/custom_text/section_header";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import Sidebar from "../../components/sidebars/sidebar";
import './profile.scss'
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";


import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Button, Chip, FormControlLabel, FormHelperText, MenuItem, Select, SelectChangeEvent, Step, StepLabel, Stepper, Switch, TextField, Typography } from "@mui/material";
import System_Type from '../system_type/system_types';
import { Theme, useTheme } from '@mui/material/styles';
import Administrator from '../administrator/administrator';



import { styled } from '@mui/material/styles';

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import logoImage from './images/logo.png';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const steps = ['Edit User Details', 'Edit User Address', 'Confirm User Details'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const managementRoleData = [
  'Diagnostics',
  'Forms',
  'Maintenance Checklist',
  'Impact Measurement',
  'Training',
  'All Access Management',
];

const CommunitiesData = [
  'Lagos Island',
  'AJah',
  'Owerri',
  'Onisha',
  'Awka',
  'Badorie',
  'Victoria Island',
];

function getStyles(name: string, communities: readonly string[], theme: Theme) {
  return {
    fontWeight:
    communities.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Edit_Profile : React.FC = () => {


  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

 // let handleChange = (event : any) => {}
 let handleSubmit = (event : any) => {}


 const [activeStep, setActiveStep] = React.useState(0);
 const [skipped, setSkipped] = React.useState(new Set<number>());
 const [isPublishedChecked, setPublishedChecked] = React.useState(false);
 const [organization_name, setOrganization] = React.useState('');
 const [adminRole, setAdminRole] = React.useState('');
 const [managementRole, setManagementRole] = React.useState<string[]>([]);
 const [communities, setCommunities] = React.useState<string[]>([]);
 const [user_name, setUserName] = React.useState('User Name');

 const [userEmail, setUserEmail] = React.useState('User Email');


 const handleDropDowOrganizationChange = (event: SelectChangeEvent) => {
   setOrganization(event.target.value);
 };

 const handleDropDownAdminRoleChange = (event: SelectChangeEvent) => {
   setAdminRole(event.target.value);
 };

 const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   setUserName(event.target.value);
 };

 const handleUserEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   setUserEmail(event.target.value);
 };

 const isStepOptional = (step: number) => {
   return step === 1;
 };

 const isStepSkipped = (step: number) => {
   return skipped.has(step);
 };

 const handleNext = () => {
   let newSkipped = skipped;
   if (isStepSkipped(activeStep)) {
     newSkipped = new Set(newSkipped.values());
     newSkipped.delete(activeStep);
   }

   setActiveStep((prevActiveStep) => prevActiveStep + 1);
   setSkipped(newSkipped);
 };

 const handleBack = () => {
   setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleSkip = () => {
   if (!isStepOptional(activeStep)) {
     // You probably want to guard against something like this,
     // it should never occur unless someone's actively trying to break something.
     throw new Error("You can't skip a step that isn't optional.");
   }

   setActiveStep((prevActiveStep) => prevActiveStep + 1);
   setSkipped((prevSkipped) => {
     const newSkipped = new Set(prevSkipped.values());
     newSkipped.add(activeStep);
     return newSkipped;
   });
 };

 const handleReset = () => {
   setActiveStep(0);
 };

 const handlePublishedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   setPublishedChecked(event.target.checked);
   console.log(isPublishedChecked);
 };

 const handleDropDownManagementRoleChange = (event: SelectChangeEvent<typeof managementRole>) => {
   const {
     target: { value },
   } = event;
   setManagementRole(
     // On autofill we get a stringified value.
     typeof value === 'string' ? value.split(',') : value,

   );
   console.log(managementRole);
 };

 const handleDropDownCommunityChange = (event: SelectChangeEvent<typeof communities>) => {
   const {
     target: { value },
   } = event;
   setCommunities(
     // On autofill we get a stringified value.
     typeof value === 'string' ? value.split(',') : value,

   );
   console.log(communities);
 };

 return (
  <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>

        <Link to="/home" style={{ textDecoration: "none" }}>
              {/* <span className='logo'>WellBeyond</span> */}
              <img
                  src={logoImage}
                  alt=""
                  className="itemImg"
              />
          </Link>
      </Toolbar>
      
      <NavBarAdmin/>

    </AppBar>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? 
          <img
            src={logoImage}
            alt=""
            className="itemImg"
              
          /> : 
          <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Sidebar />
    </Drawer>
    <Main open={open}>
      <DrawerHeader />
      <div className="home">

<div className="homeContainer">

  <SectionHeader sectionTitle={'EDIT PROFILE'} link='' />
  <div className="cardPadding">
  
  <NavCard_Community cardContent={20} cardTitle={'Edit Profile'} image={editImage} link={"/home/profile/edit/:profileId"} />
  <NavCard_Community cardContent={20} cardTitle={'Profile Details'} image={detailImage} link={"/home/profile"} />

  </div>

  <div className="top">
      <div className="left">
        <div className="item">
          <img
            src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"
            alt=""
            className="itemImg"
          />
          <div style={{
            "width": "50%",
            "height": "50px", 
            "marginTop":"20px", 
            "marginBottom":"20px", 
            "marginRight":"10px",  
            "marginLeft":"10px",
            }}>
                        
            <Button 
              style={{
                "width": "50%",
                "height": "50px", 
                "marginTop":"40px", 
                "marginBottom":"20px", 
                "marginRight":"10px",  
                "marginLeft":"10px",
              }}
                size="large"
                variant="outlined"
                onClick={() => {
                  alert('clicked');
                }}
              >
              Select User Image
            </Button>
          </div>

        </div>

        {/* <hr style={{
            height: "2px",
            backgroundColor: '#dddddd',
            marginTop: '10px',
            marginBottom: '10px',
            border: "none",
        }}/> */}

          <div className="formBox" style={{
            "marginLeft":"10px", 
            "border": "0.5px solid lightgray",
            "padding":"20px", 
            "borderRadius":"5px",
            "marginTop":"10px",
            "marginBottom":"10px",
          }}>
          <div className="details" style={{"width":"100%", }}>
            <h1 className="itemTitle">User</h1>
            <hr style={{
              height: "0.5px",
              backgroundColor: '#dddddd',
              marginTop: '10px',
              marginBottom: '10px',
              border: "none",
            }}/>

            <div style={{"width":"100%", "marginTop": "30px"}}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  // if (isStepOptional(index)) {
                  //   labelProps.optional = (
                  //     <Typography variant="caption">Optional</Typography>
                  //   );
                  // }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                  {
                    activeStep === 0 ? 
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1 },
                        }}
                        noValidate
                        autoComplete="off">
      
                        <div style={{"width":"100%", "marginTop": "30px"}}>

                            <div 
                              style={{
                                "display": "flex", 
                                "justifyContent": "space-between", 
                                "marginRight":"10px",  
                                "marginLeft":"10px",
                                "marginTop":"20px",  
                                // "marginBottom":"20px"  
                              }}
                            >
      
                              <FormControl style={{"width": "45%"}}>
                                <InputLabel htmlFor="component-outlined">First Name</InputLabel>
                                <OutlinedInput
                                  id="component-outlined"
                                  value={user_name}
                                  onChange={handleUserNameChange}
                                  label="First Name"
                                  
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl>
      
                              <FormControl style={{"width": "45%"}}>
                                <InputLabel htmlFor="component-outlined">Last Name</InputLabel>
                                <OutlinedInput
                                  id="component-outlined"
                                  value={user_name}
                                  onChange={handleUserNameChange}
                                  label="Last Name"
                                  
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl>
      
                            </div>
      
                            <div 
                              style={{
                                "display": "flex", 
                                "justifyContent": "space-between", 
                                "marginRight":"10px",  
                                "marginLeft":"10px",
                                "marginTop":"20px",  
                                // "marginBottom":"20px"  
                              }}
                            >
      
                              <FormControl style={{"width": "45%"}}>
                                <InputLabel htmlFor="component-outlined">Phone Number</InputLabel>
                                <OutlinedInput
                                  id="component-outlined"
                                  value={user_name}
                                  onChange={handleUserNameChange}
                                  label="Phone Number"
                                  
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl>
      
                              <FormControl style={{"width": "45%"}}>
                                <InputLabel htmlFor="component-outlined">User Email</InputLabel>
                                <OutlinedInput
                                  id="component-outlined"
                                  value={userEmail}
                                  onChange={handleUserEmailChange}
                                  label="User Email"
                                  
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl>
      
                            </div>

                            <div 
                              style={{
                                "display": "flex", 
                                "justifyContent": "space-between", 
                                "marginRight":"10px",  
                                "marginLeft":"10px",
                                "marginTop":"20px",  
                                // "marginBottom":"20px"  
                              }}
                            >
      
                              <FormControl style={{"width": "45%"}}>
                                <InputLabel htmlFor="component-outlined">Password</InputLabel>
                                <OutlinedInput
                                  id="component-outlined"
                                  value={user_name}
                                  onChange={handleUserNameChange}
                                  label="Password"
                                  
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl>
      
                              <FormControl style={{"width": "45%"}}>
                                <InputLabel htmlFor="component-outlined">Confirm Password</InputLabel>
                                <OutlinedInput
                                  id="component-outlined"
                                  value={userEmail}
                                  onChange={handleUserEmailChange}
                                  label="Confirm Password"
                                  
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl>
      
                            </div>

                            <div 
                              style={{
                                "display": "block", 
                                "justifyContent": "space-between", 
                                "marginRight":"10px",  
                                "marginLeft":"10px",
                                "marginTop":"0px",  
                                // "marginBottom":"20px"  
                              }}
                              >
      
                              {/* dropdown component */}
                              <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} >
                                <InputLabel id="demo-simple-select-helper-label">Select Organizations</InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper-label"
                                  value={organization_name}
                                  label="Select Organizations"
                                  onChange={handleDropDowOrganizationChange}
                                  renderValue={(value) =>  value }
                                >
                                  <MenuItem value=""><em>None</em></MenuItem>
                                  <MenuItem value={"well_beyond"}>Well Beyond</MenuItem>
                                  <MenuItem value={"well_aware"}>Well Aware</MenuItem>
                                  <MenuItem value={"princess_aware"}>Princess Aware</MenuItem>
                                </Select>
                                <FormHelperText>Error</FormHelperText>
                              </FormControl>
      
                              {/* dropdown component */}
                              <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} >
                                <InputLabel id="demo-simple-select-helper-label">Select Administrator Role</InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper-label"
                                  value={adminRole}
                                  label="Select Administrator Role"
                                  onChange={handleDropDownAdminRoleChange}
                                  renderValue={(value) =>  value }
                                >
                                  <MenuItem value=""><em>None</em></MenuItem>
                                  <MenuItem value={"Super Admin"}>Super Administrator</MenuItem>
                                  <MenuItem value={"Admin"}>Administrator</MenuItem>
                                  <MenuItem value={"Management Admin"}>Management Admin</MenuItem>
                                </Select>
                                <FormHelperText>Error</FormHelperText>
                              </FormControl>

                              <FormControl sx={{ m: 0, minWidth: 300 }} style={{"width": "100%", "marginTop":"20px",}}>
                                <InputLabel id="demo-multiple-chip-label">Select Communities</InputLabel>
                                <Select
                                  labelId="demo-multiple-chip-label"
                                  id="demo-multiple-chip"
                                  multiple
                                  value={communities}
                                  onChange={handleDropDownCommunityChange}
                                  input={<OutlinedInput id="select-multiple-chip" label="Select Communities" />}
                                  renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                      {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                      ))}
                                    </Box>
                                  )}
                                  MenuProps={MenuProps}
                                >
                                  {CommunitiesData.map((community) => (
                                    <MenuItem
                                      key={community}
                                      value={community}
                                      style={getStyles(community, communities, theme)}
                                    >
                                      {community}
                                    </MenuItem>
                                  ))}
                                  
                                </Select>
                              </FormControl>

                              <FormControl sx={{ m: 0, minWidth: 300 }} style={{"width": "100%", "marginTop":"40px",}}>
                                <InputLabel id="demo-multiple-chip-label">Select Management Role</InputLabel>
                                <Select
                                  labelId="demo-multiple-chip-label"
                                  id="demo-multiple-chip"
                                  multiple
                                  value={managementRole}
                                  onChange={handleDropDownManagementRoleChange}
                                  input={<OutlinedInput id="select-multiple-chip" label="Select Management Role" />}
                                  renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                      {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                      ))}
                                    </Box>
                                  )}
                                  MenuProps={MenuProps}
                                >
                                  {managementRoleData.map((name) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      style={getStyles(name, managementRole, theme)}
                                    >
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
      
                            </div>
      
      
                        </div>
      
      
                      </Box>
                      : 
                      activeStep === 1 ? 
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1 },
                        }}
                        noValidate
                        autoComplete="off">
      
                        <div style={{"width":"100%", "marginTop": "30px"}}>
      
                            <div 
                              style={{
                                "display": "flex", 
                                "justifyContent": "space-between", 
                                "marginRight":"10px",  
                                "marginLeft":"10px",
                                "marginTop":"20px",  
                                // "marginBottom":"20px"  
                              }}
                            >
      
                              <FormControl style={{"width": "45%"}}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Street Address"
                                  multiline
                                  maxRows={4}
                                  value={user_name}
                                  onChange={handleUserNameChange}
                                  defaultValue="Default Value"
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl>
      
                              <FormControl style={{"width": "45%"}}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="City"
                                  multiline
                                  maxRows={4}
                                  value={user_name}
                                  onChange={handleUserNameChange}
                                  defaultValue="Default Value"
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl>
      
                            </div>

                            <div 
                              style={{
                                "display": "flex", 
                                "justifyContent": "space-between", 
                                "marginRight":"10px",  
                                "marginLeft":"10px",
                                "marginTop":"20px",  
                                // "marginBottom":"20px"  
                              }}
                            >
      
                              <FormControl style={{"width": "45%"}}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Postal Code"
                                  multiline
                                  maxRows={4}
                                  value={user_name}
                                  onChange={handleUserNameChange}
                                  defaultValue="Default Value"
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl>
      
                              <FormControl style={{"width": "45%"}}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Country"
                                  multiline
                                  maxRows={4}
                                  value={user_name}
                                  onChange={handleUserNameChange}
                                  defaultValue="Default Value"
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl>
      
                            </div>

                            <div 
                              style={{
                                "display": "flex", 
                                "justifyContent": "space-between", 
                                "marginRight":"10px",  
                                "marginLeft":"10px",
                                "marginTop":"20px",  
                                // "marginBottom":"20px"  
                              }}
                            >
      
                              <FormControl style={{"width": "45%"}}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Longitude"
                                  multiline
                                  maxRows={4}
                                  value={user_name}
                                  onChange={handleUserNameChange}
                                  defaultValue="Default Value"
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl>
      
                              <FormControl style={{"width": "45%"}}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Latitude"
                                  multiline
                                  maxRows={4}
                                  value={user_name}
                                  onChange={handleUserNameChange}
                                  defaultValue="Default Value"
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl>
      
                            </div>
      
                        </div>
      
      
                      </Box>

                      : <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1 },
                        }}
                        noValidate
                        autoComplete="off">
    
                          <div style={{"width":"100%", "marginTop": "30px"}}>
        
                            <div className="item" style={{
                              "marginLeft":"20px", 
                              "border": "0.5px solid lightgray",
                              "padding":"20px", "borderRadius":"10px",
                              "marginTop":"10px",
                              "marginBottom":"10px",
                            }}>
                            <div className="details" style={{"width":"100%", }}>
                              
                              <div className="detailItem">
                                <span className="itemKey">First Name:</span>
                                <span className="itemValue">Clovis</span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Last Name:</span>
                                <span className="itemValue">Okonkwo</span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Email:</span>
                                <span className="itemValue">Lagos Island</span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Phone Number:</span>
                                <span className="itemValue">Lagos Island</span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Organization Name:</span>
                                <span className="itemValue">Well Beyond</span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Community Name:</span>
                                <span className="itemValue">Lagos Island</span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Role:</span>
                                <span className="itemValue">Administrator</span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Management Access:</span>
                                <span className="itemValue">All Access</span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Password:</span>
                                <span className="itemValue" style={{"color":"green", }}>********</span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Created On:</span>
                                <span className="itemValue">
                                  30-09-2022
                                </span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Completed On:</span>
                                <span className="itemValue">
                                  30-09-2022
                                </span>
                              </div>
                              <hr style={{
                                height: "0.5px",
                                backgroundColor: '#dddddd',
                                marginTop: '20px',
                                marginBottom: '20px',
                                border: "none",
                              }}/>
                              <div className="detailItem">
                                <span className="itemKey">Street Address:</span>
                                <span className="itemValue">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">City:</span>
                                <span className="itemValue">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Postal Code:</span>
                                <span className="itemValue">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Country:</span>
                                <span className="itemValue">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Longitude:</span>
                                <span className="itemValue">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">Latitude:</span>
                                <span className="itemValue">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </span>
                              </div>

                              <FormControlLabel style={{"marginTop":"20px",}}
                                control={
                                  <Switch
                                    checked={isPublishedChecked}
                                    onChange={handlePublishedChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                } 
                                label="Accept Terms and Conditions" 
                              />
                              
                            </div>
                          </div>

                          
    
                          <Button 
                            style={{
                              "width": "50%",
                              "height": "50px", 
                              "marginTop":"40px", 
                              "marginBottom":"20px", 
                              "marginRight":"10px",  
                              "marginLeft":"20px",
                            }}
                            size="large"
                            variant="contained"
                            onClick={() => {
                              alert('clicked');
                            }}
                          >
                            Create User
                          </Button>
    
                      </div>
    
    
                    </Box> 
                    } 
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {isStepOptional(activeStep) && (
                      <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                        Skip
                      </Button>
                    )}
                    <Button onClick={handleNext}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </div>
            
          </div>
        </div>

      </div>
    </div>

</div>
</div>
     
    </Main>
  </Box>
);

  
};

export default Edit_Profile;