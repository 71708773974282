import React from 'react';
import './details_card.scss';

//creating a props interface for the DashboardCard components types
//creating a props interface for the DashboardCard components types
interface Community_Details_CardProps {
    communityName: string;
    population: string;
    image: any;
    community_description: string;
    address: string;
    country: string;
    catrdTile: string;
    dateCreated: string;
    
}

const Community_Details_Card : React.FC<Community_Details_CardProps> = ({communityName, population, image, community_description, address, country, catrdTile, dateCreated }) => {
    return (
        <div className="topLevel">
          <div className="leftContent">
            {/* <div className="editButton">Edit</div> */}
            <h3 className="title"> {catrdTile}</h3>
            <hr style={{
                height: "2px",
                backgroundColor: '#dddddd',
                marginTop: '10px',
                marginBottom: '10px',
                border: "none",
            }}/>
            <div className="itemContents">
              <img
                src={image}
                alt="Community Image"
                className="itemContentsImg"
              />
              <div className="detailsContents">
                <h2 className="itemTitleContents">{communityName}</h2>
                <div className="detailItemContents">
                  <span className="itemKeyContents">Population:</span>
                  <span className="itemValueContents">{population}</span>
                </div>
                <div className="detailItemContents">
                  <span className="itemKeyContents">Community description:</span>
                  <span className="itemValueContents">{community_description}</span>
                </div>
                <div className="detailItemContents">
                  <span className="itemKeyContents">Date Created:</span>
                  <span className="itemValueContents">{dateCreated}</span>
                </div>
                {/* <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">
                    {address}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">{country}</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
    );
}

export default Community_Details_Card;