import React from 'react';
import './checklist_dashboard_card.scss';

//creating a props interface for the DashboardCard components types
//creating a props interface for the DashboardCard components types
interface Checklist_DashboardCardProps {
    cardTitle: string;
    cardContent: any;
    image: any;
    link: string;
}

const Checklist_DashboardCard : React.FC<Checklist_DashboardCardProps> = ({cardTitle, cardContent, image, }) => {
    return (
        <div className="cardBodyContent"> 
            <h1 className='cardBodyContentTitle'>{cardTitle} </h1>
            <hr style={{
                height: "2px",
                backgroundColor: '#dddddd',
                marginTop: '10px',
                marginBottom: '10px',
                border: "none",
            }}/>
            <div className='cardTwoContents'>
                <div className='imageContainer'>
                    <img className='image' src={image} alt="Logo" />
                </div>
                <h1 className='cardBodyContentDetail'>{cardContent} </h1>
            </div> 
        </div>
    );
}

export default Checklist_DashboardCard;