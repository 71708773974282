// import './data_table.scss';
import './SystemOverviewStyle.scss';
import React, { useMemo, useRef, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';


interface DataTableComponentFormsProps {
    data: any;
}


const DataTableComponentForms: React.FC<DataTableComponentFormsProps> = ({data}, probObject:object) => {

    let handleChange = (event : any) => {}
    let handleSubmit = (event : any) => {}
  
    const columns = useMemo(
      () => [
        {
          accessorKey: 'name', //simple recommended way to define a column
          header: 'Name',
          muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
          Cell: ({cell} : any) => <span>{cell.getValue()}</span>, //optional custom cell render
        },
        {
            accessorKey: 'community', //simple recommended way to define a column
            header: 'community',
            muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
            Cell: ({cell} : any) => <span>{cell.getValue()}</span>, //optional custom cell render
        },
        {
            accessorKey: 'form_name', //simple recommended way to define a column
            header: 'form_name',
            muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
            Cell: ({cell} : any) => <span>{cell.getValue()}</span>, //optional custom cell render
        },
        {
            accessorKey: 'link_to_pdf', //simple recommended way to define a column
            header: 'link_to_pdf',
            muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
            Cell: ({cell} : any) => <span>{cell.getValue()}</span>, //optional custom cell render
        },
        {
            accessorKey: 'submitted_date', //simple recommended way to define a column
            header: 'submitted_date',
            muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
            Cell: ({cell} : any) => <span>{cell.getValue()}</span>, //optional custom cell render
        },
        {
          accessorFn: (row : any) => row.age, //alternate way
          id: 'age', //id required if you use accessorFn instead of accessorKey
          header: 'Age',
          Header: () => <i>Age</i>, //optional custom header render
        },
      ],
      [],
    );
  
    //optionally, you can manage any/all of the table state yourself
    const [rowSelection, setRowSelection] = useState({});
  
    useEffect(() => {
      //do something when the row selection changes
    }, [rowSelection]);
  
    //Or, optionally, you can get a reference to the underlying table instance
    const tableInstanceRef = useRef(null);
  
    const someEventHandler = () => {
      //read the table state during an event from the table instance ref
      // console.log(tableInstanceRef.current.getState().sorting);
    }
    

    return (
      <div className='cardTableBody' >
          {/* Dashboard List */}
          <MaterialReactTable 
              // enableTableHead={true}
              enableColumnResizing ={false}
              enableExpandAll={false}
              columns={columns} 
              data={data} 
              enableColumnOrdering //enable some features
              enableRowSelection 
              enablePagination={true} //disable a default feature
              onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
              state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
              tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
          />
          
      </div>
    );
}



export default DataTableComponentForms;