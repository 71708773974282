import React from "react";
import DashboardCard from "../../components/card/card";
import Custom_Text from "../../components/custom_text";
import SectionHeader from "../../components/custom_text/section_header";
import DashboardPieChart from "../../components/dashboard_cards/dashboard_card_pie";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import NavCard_Community from "../../components/nav_card/navcard_community";
import Sidebar from "../../components/sidebars/sidebar";
import TableList from "../../components/table/table";
import './forms.scss'
import SideBarForm from './sidebar_form/sidebar_form';
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';

import pendingForms from './images/pending_forms.png';
import allForms from './images/all.png';
import submitted_forms from './images/submitted_forms.png';


import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import logoImage from './images/logo.png';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const Forms : React.FC = () => {

  const individualsTrainedPerSubjectBarData = {
    labels: ['today', 'yesterday', 'tomorrow', "next week"],
    datasets: [
      {
        label: 'Individuals Trained per Subject',
        backgroundColor: "#4EC7F2",
        borderColor: "#79B82C",
        borderWidth: 2,
        data: [34, 2, 9, 100]
      }
    ]
  }
  
  const communitySystemStatusPieData = {
    labels:["Functioning", "Maintenance Checklist Overdue", "Unresolved Diagnostics", "Maintenance & Diagnostic needs"],
    datasets:[{
     data: [300, 50, 100, 40],
     backgroundColor: [
        "#79B82C",
        "#4EC7F2",
        "#FFC870",
        "#A8B3C5",
        "#616774"
      ],
     borderWidth: 1
    }]
  };
  
  const maintenanceStatusPieData = {
    labels:["Compliant", "Overdue"],
    datasets:[{
     data: [300, 50],
     backgroundColor: [
        "#79B82C",
        "#FFB200"
      ],
     borderWidth: 1
    }]
  };
  
  const diagnosticStatusPieData = {
    labels:["Issue Reported", "Under Review", "Functioning", "Pending Maintenace", "Contact Community"],
    datasets:[{
     data: [300, 50, 100, 40, 20],
     backgroundColor: [
        "#79B82C",
        "#FFB200",
        "#5AD3D1",
        "#531CBC",
        "#FF0000",
      ],
     borderWidth: 1
    }]
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let handleChange = (event : any) => {}
  let handleSubmit = (event : any) => {}

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div className="home">

          <div className="homeContainer">


            <SectionHeader sectionTitle={'FORMS'} link='' />
              <div className="cardPadding">
                <NavCard_Community cardContent={20} cardTitle={'Create Form Types'} image={createImage} link={"/home/forms/form_type/create"} />
                <NavCard_Community cardContent={20} cardTitle={'Create Form'} image={createImage} link={"/home/forms/create"} />
                <NavCard_Community cardContent={20} cardTitle={'Forms List'} image={listImage} link={"/home/forms/list/:formsId"} />
                <NavCard_Community cardContent={20} cardTitle={'Submitted Forms'} image={editImage} link={"/home/forms/forms_submitted"} />
                
              </div>
              <div className="cardPadding" >

                <NavCard_Community cardContent={20} cardTitle={'Form Details'} image={detailImage} link={"/home/forms/detail/:formsId"} />

              </div>
              <div className="cardPadding">
                <DashboardCard cardContent={20} cardTitle={'Total Forms'} image={allForms} link={""}/>
                <DashboardCard cardContent={40} cardTitle={'Pending Forms'} image={pendingForms} link={""}/>
                <DashboardCard cardContent={230} cardTitle={'Submitted Forms'} image={submitted_forms} link={""}/>
                {/* <DashboardCard cardContent={130} cardTitle={'People Trained'} image={trainedPeopleImage} link={""}/> */}
              </div>
            
            {/* <SectionHeader sectionTitle={'SYSTEM OVERVIEW'} link='' /> */}
            {/* <div className="dashboardPieChartContainer">
              <DashboardPieChart data={communitySystemStatusPieData} title='Community System Status' />
              <DashboardPieChart data={maintenanceStatusPieData} title='Maintenance Status' />
              <DashboardPieChart data={diagnosticStatusPieData} title='Diagnostic Status' />
            </div> */}
          
            <SectionHeader sectionTitle={'FORM LOGS'} link='' />
            <div className="listContainer">
              <div className="listTitle">Forms</div>
              <TableList data={[]}/>
            </div>

          </div>
        </div>
       
      </Main>
    </Box>
  );

};

export default Forms;