import DashboardCard from "../../components/card/card";
import Custom_Text from "../../components/custom_text";
import SectionHeader from "../../components/custom_text/section_header";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import Sidebar from "../../components/sidebars/sidebar";
import SideBarUserManagement from "./sidebar_user_management/sidebaruser_management";
import './user_management.scss'
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';



import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Button, Chip, FormControlLabel, FormHelperText, MenuItem, Modal, Select, SelectChangeEvent, Step, StepLabel, Stepper, Switch, TextField, Checkbox } from '@mui/material';
import System_Type from '../system_type/system_types';
import { Theme } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import Administrator from '../administrator/administrator';



import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import logoImage from './images/logo.png';
import axios from "axios";
import { Community, Organization, SystemTypes, User } from "../authentication/authentication_interfaces";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const steps = ['Create User Details', 'Create User Address', 'Confirm User Details'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const managementRoleData = [
  'Diagnostics',
  'Forms',
  'Maintenance Checklist',
  'Impact Measurement',
  'Training',
  'All Access Management',
];

const CommunitiesData = [
  'Lagos Island',
  'AJah',
  'Owerri',
  'Onisha',
  'Awka',
  'Badorie',
  'Victoria Island',
];

function getStyles(name: string, communities: readonly string[], theme: Theme) {
  return {
    fontWeight:
    communities.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Create_User_Management : React.FC = () => {

    const config = {
      headers:{
        'content-type': 'application/json',
        // header2: value2
      }
    };

    const [fullName, setFullName] = useState<string>();
    const [userId, setUserId] = useState([]);
    const [bearer, setBearerToken] = useState<string>();

    //Api call state management
    const [userData, setUserData] = useState<User | null>();

    useEffect(() => {
      const userId = JSON.parse(localStorage.getItem('userId') || '{}');
      const user_fullName = JSON.parse(localStorage.getItem('user_fullName') || '{}');
      const bearerToken = JSON.parse(localStorage.getItem('bearerToken') || '{}');
      if (userId) {
        setUserId(userId);
        setFullName(user_fullName);
        setBearerToken(bearerToken);
        getUserData(userId);
      }
    }, []);

    const getUserData = async (userID : number) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
      const userResponse = await axios.get(`http://api.wellbeyondwater.com:8000/api/user/${userID}`, config);
      console.log('API call response status ' + userResponse.status);
      setUserData(userResponse.data.data["user"]);
      console.log('User First Name ' + userResponse['data']['data']['first_name']);
      console.log('User API status ' + userResponse['data']['status']);
      console.log('User API status ' + userData?.first_name);
    };

    
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
      setOpen(true);
    };

    const handleDrawerClose = () => {
      setOpen(false);
    };

    const [openModal, setOpenModal] = React.useState(false);
    const [openErrorModal, setopenErrorModal] = React.useState(false);

    const handleOpen = () => {
      setOpenModal(true);
    };
    const handleClose = () => {
      setOpenModal(false);
    };

    const handleErrorClose  = () => {
      setopenErrorModal(false);
    };

    // let handleChange = (event : any) => {}

    let selectedSystemTypesId : any[] = [];
    let selectedSystemTypes : any[] = [];

    //Api call state management
    const [data, setOrganizationData] = useState<Organization[] | null>();
    const [systemData, setSystemTypeData] = useState<SystemTypes[] | null>();
    const [CommunityData, setCommunityData] = useState<Community[] | null>();
    const [displaySystemTypeData, setDisplaySystemTypeData] = useState<any[]>();
    useEffect(() => {
      getOrganizationData();
      getSystemTypeData();
      getCommunitiesData();
    }, []);
    //Api calls
    const getOrganizationData = async () => {
        const userResponse = await axios.get(`http://api.wellbeyondwater.com:8000/api/organization/`);
        console.log('API call response status ' + userResponse.status);
        setOrganizationData(userResponse.data.data);
        console.log('Organization Name ' + userResponse['data']['data'][0]['name']);
        console.log('Organization Name ' + userResponse['data']['status']);
    };

    const getSystemTypeData = async () => {
      const userResponse = await axios.get(`http://api.wellbeyondwater.com:8000/api/system_type/`);
      console.log('API call response status ' + userResponse.status);
      setSystemTypeData(userResponse.data.data);
      console.log('System Type Name ' + userResponse['data']['data'][0]['name']);
      console.log('System Type Name ' + userResponse['data']['status']);
    };

    const getCommunitiesData = async () => {
      const communityResponse = await axios.get(`http://api.wellbeyondwater.com:8000/api/community/`);
      console.log('Community API call response status ' + communityResponse.status);
      setCommunityData(communityResponse.data.data);
      console.log('Community Name ' + communityResponse['data']['data'][0]['name']);
      console.log('Community Name ' + communityResponse['data']['status']);
    };

    

    // let handleChange = (event : any) => {}
    // let handleSubmit = (event : any) => {}

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [communities, setCommunities] = React.useState<string[]>([]);

    const [isPublishedChecked, setPublishedChecked] = React.useState(false);
    const [organization_name, setOrganization] = React.useState('');
    const [display_organization_name, setDisplayOrganizationName] = React.useState('');
    const [adminRole, setAdminRole] = React.useState('Management Admin');
    const [managementRole, setManagementRole] = React.useState<string[]>([]);
    const [error, setUserError] = React.useState(false);

    //setState for the input field
    const [last_name, setUserLastName] = React.useState('');
    const [first_name, setUserFirstName] = React.useState('');
    const [userEmail, setUserEmail] = React.useState('');
    const [phone_number, setUserPhoneNumber] = React.useState('');
    const [password, setUserPassword] = React.useState('');
    const [confirm_password, setUserConfirmPassword] = React.useState('');
    const [street_address, setUserAddress] = React.useState('');
    const [city, setUserCity] = React.useState('');
    const [postal_code, setUserPostalCode] = React.useState('');

    const [country, setUserCountry] = React.useState('');
    const [longitude, setUserLongitude] = React.useState('');
    const [latitude, setUserLatitude] = React.useState('');
    const [organization_id, setOrganizationId] = React.useState<number>();

  
  const [formsChecked, setFormsChecked] = React.useState(false);
  const [diagnosticChecked, setDiagnosticChecked] = React.useState(false);
  const [maintenanceChecked, setMaintenanceChecked] = React.useState(false);
  const [impactChecked, setImpactChecked] = React.useState(false);
  const [trainingChecked, setTrainingChecked] = React.useState(false);
  const [allAccessChecked, setAllAccessChecked] = React.useState(false);

  const handleFormsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormsChecked(event.target.checked);
  };
  const handleDiagnosticChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiagnosticChecked(event.target.checked);
  };
  const handleMaintenanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaintenanceChecked(event.target.checked);
  };
  const handleImpactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImpactChecked(event.target.checked);
  };
  const handleTrainingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrainingChecked(event.target.checked);
  };
  const handleAllAccessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllAccessChecked(event.target.checked);
  };


   //methods to handle input validations
   const handleUserFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserFirstName(event.target.value);
   };

   const handleUserLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserLastName(event.target.value);
   };

   const handleUserPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
      setUserPhoneNumber(event.target.value);
   };

   const handleUserEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserEmail(event.target.value);
   };

   const handleUserPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
      setUserPassword(event.target.value);
   };

   const handleUserConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
      setUserConfirmPassword(event.target.value);
   };

   const handleUserAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserAddress(event.target.value);
   };

   const handleUserCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserCity(event.target.value);
   };

   const handleUserPostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserPostalCode(event.target.value);
   };

   const handleUserCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserCountry(event.target.value);
   };

   const handleUserLongitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserLongitude(event.target.value);
   };

   const handleUserLatitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserLatitude(event.target.value);
   };

   //dropdown methods
    const handleDropDowOrganizationChange = (event: SelectChangeEvent) => {
      setOrganization(event.target.value);
      console.log(organization_name);
      
    };
  
    const handleDropDownAdminRoleChange = (event: SelectChangeEvent) => {
      setAdminRole(event.target.value);
    };

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handlePublishedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublishedChecked(event.target.checked);
    console.log(isPublishedChecked);
  };

  useEffect(() => {
    getOrganizationName(organization_name);
  }, [organization_name]);

  function getOrganizationName(str: string) {
    var values = str.split(" ");
    var f_name = values.shift();
    var l_name = values.join(' ');
    setDisplayOrganizationName(l_name);
    console.log(f_name);
    console.log(l_name);
  }

  const [communityMultiple, setCommunityMultiple] = React.useState<string[]>([]);
  const [displayCommunityData, setDisplayCommunityData] = useState<any[]>();
  const [displayCommunityIdData, setDisplayCommunityIdData] = useState<any[]>();
  let selectedCommunityId : any[] = [];
  let selectedCommunitiess : any[] = [];

  //use to update the display summary
  useEffect(() => {

    getSelectedCommunities();
    getSelectedCommunityId();
  
  }, [communityMultiple]);

  //handle multiple selected dropdown values
  const handleDropDownCommunityChange = (event: SelectChangeEvent<typeof communityMultiple>) => {
    const {
      target: { value },
    } = event;
    console.log("Communities selected" + communityMultiple);
    setCommunityMultiple(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,

    );
  };
  
  const getSelectedCommunityId = async () => {
    selectedCommunityId.length = 0;
    console.log("selected multiple communities " + communityMultiple);
    for(let i = 0; i < communityMultiple.length; i++){
      var values = communityMultiple[i].split(" ");
      var communityId = values.shift();
      var communityName = values.join(' ');
      selectedCommunityId.push(communityId);
    }
    // getSelectedSystemTypes();
    console.log("selected id value " + selectedCommunityId);
    setDisplayCommunityIdData(selectedCommunityId);
  };

  const getSelectedCommunities = async () => {
    selectedCommunitiess.length = 0;
    console.log("selected communities " + communityMultiple);
    for(let i = 0; i < communityMultiple.length; i++){
      var values = communityMultiple[i].split(" ");
      var communityId = values.shift();
      var communityName = values.join(' ');
      selectedCommunitiess.push(communityName + " "); //remove the first string character and add to the list to display in summary
    }
    console.log("display values "+ selectedCommunitiess);
    setDisplayCommunityData(selectedCommunitiess);
  };
  

  const [errorMessage, setErrorMessage] = React.useState();

  let handleSubmit = (event : any) => {
    event.persist(); 
    console.log("Create client user and address event fired");
    PostData();
  };

  const PostData = async () => {

    let organizationId = organization_name.replace(/[^0-9]/g, '');
    console.log("organization id " + organizationId);
    console.log("Communities selected id" + displayCommunityIdData);

    let SaveData = {
      // organization_id: parseInt(organization_name.charAt(0)),
      organization_id: organizationId,
      user_token: "string",
      firebase_token: "string",
      profile_image: "any",
      first_name: first_name,
      last_name: last_name,
      email: userEmail,
      password: password,
      role: adminRole,
      phone_number: phone_number,
      management_role: 'management_role',
      reset_password_expiry_time: " ",
      reset_password_token: " ",
      isPhoneVerified: false,
      isEmailVerifed: false,
      active: true,
      accepted_terms: isPublishedChecked,
      suspended: false,
      updated_by: userData?.user_id,
      created_by: userData?.user_id,
      community: displayCommunityIdData,

      diagnostic: diagnosticChecked,
      maintenance_checklist: maintenanceChecked,
      forms: formsChecked,
      impact_measurement: impactChecked,
      training: trainingChecked,
      all_access: allAccessChecked,

    };

    console.log("user data " + SaveData);

    axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
    const createUserResponse = await axios.post(`http://api.wellbeyondwater.com:8000/api/user/createClientUser`, SaveData)
    .then((response) => {
      setOrganizationData(response.data.data);
      const status = response['data']['status'];
      const message = response['data']['message'];
      const userId = response['data']['data']["user_id"];
      console.log("user id " + userId);

      console.log('Create client user Status ' + status);
      console.log('Message ' + message);

       let createUserAddressData = {
          user_id: userId,
          community_id : null,
          organization_id: null,
          is_default: true,
          address : street_address,
          state : "userState",
          city : city,
          latitude : "latitude",
          longitude : "longitude",
          postal_code : postal_code,
          country : country
       };

      if(status === "Success"){

        console.log("User address data " + createUserAddressData);
        axios.post(`http://api.wellbeyondwater.com:8000/api/address/createAddress`, createUserAddressData).then((response) => {
          const status = response['data']['status'];
          const message = response['data']['message'];
          const addressData = response['data']['data'];
          console.log("create address response " + addressData)
          console.log('Create address Status ' + status);
          console.log('Message ' + message);
          setOpenModal(true);
        });
        
      }

    }).catch(function (error) {

      setErrorMessage(error.toString());
      console.log('error ' + error.toJSON());
      console.log('error message ' + error.toString());
      setopenErrorModal(true);

    });

  };


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div className="home">

<div className="homeContainer">

  <SectionHeader sectionTitle={'CREATE CLIENT USER'} link='' />
  <div className="cardPadding">
                <NavCard_Community cardContent={20} cardTitle={'Create User'} image={createImage} link={"/home/user_management/create"} />
                <NavCard_Community cardContent={20} cardTitle={'Create Client'} image={createImage} link={"/home/user_management/create_client"} />
                <NavCard_Community cardContent={20} cardTitle={'Create Super Admin'} image={createImage} link={"/home/user_management/create_super_admin"} />
              </div>

              <div className="cardPadding">
                <NavCard_Community cardContent={20} cardTitle={'Super Admins'} image={listImage} link={"/home/user_management/list/super_admins"} />
                <NavCard_Community cardContent={20} cardTitle={'Clients'} image={listImage} link={"/home/user_management/list/clients"} />
                <NavCard_Community cardContent={20} cardTitle={'Management User'} image={listImage} link={"/home/user_management/list/users"} />

              </div>
              <div className="cardPadding">
                <NavCard_Community cardContent={20} cardTitle={'Edit Super Admin'} image={editImage} link={"/home/user_management/edit/super_admin/:user_id"} />
                <NavCard_Community cardContent={20} cardTitle={'Edit Client'} image={editImage} link={"/home/user_management/edit/client/:user_id"} />
                {/* <NavCard_Community cardContent={20} cardTitle={'Edit User'} image={editImage} link={"/home/profile/edit/:profileId"} /> */}
                <NavCard_Community cardContent={20} cardTitle={'Edit Management User'} image={editImage} link={"/home/user_management/edit/user/:user_id"} />
                <NavCard_Community cardContent={20} cardTitle={'User Details'} image={detailImage} link={"/home/profile"} />
              </div>
  {/* <div className="cardPadding">
    <DashboardCard cardContent={20} cardTitle={'Organizations'} image={organizationImage} link={""}/>
    <DashboardCard cardContent={40} cardTitle={'Communities'} image={communityImage} link={""}/>
    <DashboardCard cardContent={230} cardTitle={'Management Users'} image={managementUsersImage} link={""}/>
    <DashboardCard cardContent={130} cardTitle={'People Trained'} image={trainedPeopleImage} link={""}/>
  </div> */}

  <div className="top">
    <div className="left">
      <div className="item">
        <img
          src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"
          alt=""
          className="itemImg"
        />
        <div style={{
          "width": "50%",
          "height": "50px", 
          "marginTop":"20px", 
          "marginBottom":"20px", 
          "marginRight":"10px",  
          "marginLeft":"10px",
          }}>
                      
          <Button 
            style={{
              "width": "50%",
              "height": "50px", 
              "marginTop":"40px", 
              "marginBottom":"20px", 
              "marginRight":"10px",  
              "marginLeft":"10px",
            }}
              size="large"
              variant="outlined"
              onClick={() => {
                alert('clicked');
              }}
            >
            Select Client User Image
          </Button>
        </div>

      </div>

      {/* <hr style={{
          height: "2px",
          backgroundColor: '#dddddd',
          marginTop: '10px',
          marginBottom: '10px',
          border: "none",
      }}/> */}

        <div className="formBox" style={{
          "marginLeft":"10px", 
          "border": "0.5px solid lightgray",
          "padding":"20px", 
          "borderRadius":"5px",
          "marginTop":"10px",
          "marginBottom":"10px",
        }}>
        <div className="details" style={{"width":"100%", }}>
          <h1 className="itemTitle">Client User</h1>
          <hr style={{
            height: "0.5px",
            backgroundColor: '#dddddd',
            marginTop: '10px',
            marginBottom: '10px',
            border: "none",
          }}/>

          <div style={{"width":"100%", "marginTop": "30px"}}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                // if (isStepOptional(index)) {
                //   labelProps.optional = (
                //     <Typography variant="caption">Optional</Typography>
                //   );
                // }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                {
                  activeStep === 0 ? 
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1 },
                      }}
                      noValidate
                      autoComplete="off">
    
                      <div style={{"width":"100%", "marginTop": "30px"}}>

                          <div 
                            style={{
                              "display": "flex", 
                              "justifyContent": "space-between", 
                              "marginRight":"10px",  
                              "marginLeft":"10px",
                              "marginTop":"20px",  
                              // "marginBottom":"20px"  
                            }}
                          >
    
                            <FormControl style={{"width": "45%"}}>
                              <InputLabel htmlFor="component-outlined">First Name</InputLabel>
                              <OutlinedInput
                                id="component-outlined"
                                value={first_name}
                                onChange={handleUserFirstNameChange}
                                label="First Name"
                                type="text"
                                
                              />
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                            <FormControl style={{"width": "45%"}}>
                              <InputLabel htmlFor="component-outlined">Last Name</InputLabel>
                              <OutlinedInput
                                id="component-outlined"
                                value={last_name}
                                onChange={handleUserLastNameChange}
                                label="Last Name"
                                type="text"
                              />
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                          </div>
    
                          <div 
                            style={{
                              "display": "flex", 
                              "justifyContent": "space-between", 
                              "marginRight":"10px",  
                              "marginLeft":"10px",
                              "marginTop":"20px",  
                              // "marginBottom":"20px"  
                            }}
                          >
    
                            <FormControl style={{"width": "45%"}}>
                              <InputLabel htmlFor="component-outlined">Phone Number</InputLabel>
                              <OutlinedInput
                                id="component-outlined"
                                value={phone_number}
                                onChange={handleUserPhoneNumberChange}
                                label="Phone Number"
                                type="tel"
                                
                              />
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                            <FormControl style={{"width": "45%"}}>
                              <InputLabel htmlFor="component-outlined">User Email</InputLabel>
                              <OutlinedInput
                                id="component-outlined"
                                value={userEmail}
                                onChange={handleUserEmailChange}
                                label="User Email"
                                type="email"
                              />
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                          </div>

                          <div 
                            style={{
                              "display": "flex", 
                              "justifyContent": "space-between", 
                              "marginRight":"10px",  
                              "marginLeft":"10px",
                              "marginTop":"20px",  
                              // "marginBottom":"20px"  
                            }}
                          >
    
                            <FormControl style={{"width": "45%"}}>
                              <InputLabel htmlFor="component-outlined">Password</InputLabel>
                              <OutlinedInput
                                id="component-outlined"
                                value={password}
                                onChange={handleUserPasswordChange}
                                label="Password"
                                type="password"
                              />
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                            <FormControl style={{"width": "45%"}}>
                              <InputLabel htmlFor="component-outlined">Confirm Password</InputLabel>
                              <OutlinedInput
                                id="component-outlined"
                                value={confirm_password}
                                onChange={handleUserConfirmPasswordChange}
                                label="Confirm Password"
                                type="password"
                              />
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                          </div>

                          <div 
                            style={{
                              "display": "block", 
                              "justifyContent": "space-between", 
                              "marginRight":"10px",  
                              "marginLeft":"10px",
                              "marginTop":"0px",  
                              // "marginBottom":"20px"  
                            }}
                            >
    
                            {/* dropdown component */}
                            <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} >
                              <InputLabel id="demo-simple-select-helper-label">Select Organizations</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper-label"
                                value={organization_name}
                                label="Select Organizations"
                                onChange={handleDropDowOrganizationChange}
                                renderValue={(value) =>  value }
                              >
                                <MenuItem value=""><em>None</em></MenuItem>
                                  {data ? data.map((organizations) => {
                                    return <MenuItem value={`${organizations.organization_id} ${organizations.name}`}>{organizations.name}</MenuItem>
                                  }) : null}
                                  {/* <MenuItem value={"well_beyond"}>Well Beyond</MenuItem>
                                  <MenuItem value={"well_aware"}>Well Aware</MenuItem>
                                  <MenuItem value={"princess_aware"}>Princess Aware</MenuItem> */}
                              </Select>
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                            {/* dropdown component */}
                            <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px", "marginBottom":"20px",}} >
                              <InputLabel id="demo-simple-select-helper-label">Select Administrator Role</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper-label"
                                value={adminRole}
                                label="Select Administrator Role"
                                onChange={handleDropDownAdminRoleChange}
                                renderValue={(value) =>  value }
                              >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {/* <MenuItem value={"Super Admin"}>Super Administrator</MenuItem> */}
                                {/* <MenuItem value={"Admin"}>Administrator(Client)</MenuItem> */}
                                <MenuItem value={"Management Admin"}>Management Admin</MenuItem>
                              </Select>
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>

                            <FormControl sx={{ m: 0, minWidth: 300 }} style={{"width": "100%", "marginTop":"20px", "marginBottom":"20px",}}>
                              <InputLabel id="demo-multiple-chip-label">Select Communities</InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={communityMultiple}
                                onChange={handleDropDownCommunityChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Select Communities" />}
                                renderValue={(selected) => (
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                              >
                                {CommunityData ? CommunityData.map((community, index) => (
                                  <MenuItem
                                    key={community.community_id}
                                    value={`${community.community_id} ${community.community_name}`}
                                    // value={index}
                                    style={getStyles(community.community_name, communityMultiple, theme)}
                                  >
                                    {community.community_name}
                                  </MenuItem>
                                )) : null}
                                
                              </Select>
                            </FormControl>

                            <FormControlLabel control={
                              <Checkbox
                                checked={formsChecked}
                                onChange={handleFormsChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />} label="Forms" 
                            />
                            <FormControlLabel control={
                              <Checkbox
                                checked={diagnosticChecked}
                                onChange={handleDiagnosticChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />} label="Diagnostic" 
                            />

                            <FormControlLabel control={
                              <Checkbox
                                checked={maintenanceChecked}
                                onChange={handleMaintenanceChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />} label="Maintenance Checklist" 
                            />

                            <FormControlLabel control={
                              <Checkbox
                                checked={impactChecked}
                                onChange={handleImpactChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />} label="Impact Measurement" 
                            />
                            
                            <FormControlLabel control={
                              <Checkbox
                                checked={trainingChecked}
                                onChange={handleTrainingChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />} label="Training" 
                            />

                            <FormControlLabel control={
                              <Checkbox
                                checked={allAccessChecked}
                                onChange={handleAllAccessChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />} label="All Access" 
                            />

                            {/* <FormControl sx={{ m: 0, minWidth: 300 }} style={{"width": "100%", "marginTop":"20px",}}>
                              <InputLabel id="demo-multiple-chip-label">Select Communities</InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={communities}
                                onChange={handleDropDownCommunityChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Select Communities" />}
                                renderValue={(selected) => (
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                              >
                                {CommunitiesData.map((community) => (
                                  <MenuItem
                                    key={community}
                                    value={community}
                                    style={getStyles(community, communities, theme)}
                                  >
                                    {community}
                                  </MenuItem>
                                ))}
                                
                              </Select>
                            </FormControl>

                            <FormControl sx={{ m: 0, minWidth: 300 }} style={{"width": "100%", "marginTop":"40px",}}>
                              <InputLabel id="demo-multiple-chip-label">Select Management Role</InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={managementRole}
                                onChange={handleDropDownManagementRoleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Select Management Role" />}
                                renderValue={(selected) => (
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                              >
                                {managementRoleData.map((name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, managementRole, theme)}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}
    
                          </div>
    
    
                      </div>
    
    
                    </Box>
                    : 
                    activeStep === 1 ? 
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1 },
                      }}
                      noValidate
                      autoComplete="off">
    
                      <div style={{"width":"100%", "marginTop": "30px"}}>
    
                          <div 
                            style={{
                              "display": "flex", 
                              "justifyContent": "space-between", 
                              "marginRight":"10px",  
                              "marginLeft":"10px",
                              "marginTop":"20px",  
                              // "marginBottom":"20px"  
                            }}
                          >
    
                            <FormControl style={{"width": "45%"}}>
                              <TextField
                                id="outlined-multiline-static"
                                label="Street Address"
                                multiline
                                maxRows={4}
                                value={street_address}
                                onChange={handleUserAddressChange}
                                defaultValue="Default Value"
                              />
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                            <FormControl style={{"width": "45%"}}>
                              <TextField
                                id="outlined-multiline-static"
                                label="City"
                                multiline
                                maxRows={4}
                                value={city}
                                onChange={handleUserCityChange}
                                defaultValue="Default Value"
                              />
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                          </div>

                          <div 
                            style={{
                              "display": "flex", 
                              "justifyContent": "space-between", 
                              "marginRight":"10px",  
                              "marginLeft":"10px",
                              "marginTop":"20px",  
                              // "marginBottom":"20px"  
                            }}
                          >
    
                            <FormControl style={{"width": "45%"}}>
                              <TextField
                                id="outlined-multiline-static"
                                label="Postal Code"
                                multiline
                                maxRows={4}
                                value={postal_code}
                                onChange={handleUserPostalCodeChange}
                                defaultValue="Default Value"
                              />
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                            <FormControl style={{"width": "45%"}}>
                              <TextField
                                id="outlined-multiline-static"
                                label="Country"
                                multiline
                                maxRows={4}
                                value={country}
                                onChange={handleUserCountryChange}
                                defaultValue="Default Value"
                              />
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                          </div>

                          {/* <div 
                            style={{
                              "display": "flex", 
                              "justifyContent": "space-between", 
                              "marginRight":"10px",  
                              "marginLeft":"10px",
                              "marginTop":"20px",  
                              // "marginBottom":"20px"  
                            }}
                          >
    
                            <FormControl style={{"width": "45%"}}>
                              <TextField
                                id="outlined-multiline-static"
                                label="Longitude"
                                multiline
                                maxRows={4}
                                value={longitude}
                                onChange={handleUserLongitudeChange}
                                defaultValue="Default Value"
                              />
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                            <FormControl style={{"width": "45%"}}>
                              <TextField
                                id="outlined-multiline-static"
                                label="Latitude"
                                multiline
                                maxRows={4}
                                value={latitude}
                                onChange={handleUserLatitudeChange}
                                defaultValue="Default Value"
                              />
                              {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                            </FormControl>
    
                          </div> */}
    
                      </div>
    
    
                    </Box>

                    : <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1 },
                      }}
                      noValidate
                      autoComplete="off">
  
                        <div style={{"width":"100%", "marginTop": "30px"}}>
      
                          <div className="item" style={{
                            "marginLeft":"20px", 
                            "border": "0.5px solid lightgray",
                            "padding":"20px", "borderRadius":"10px",
                            "marginTop":"10px",
                            "marginBottom":"10px",
                          }}>
                          <div className="details" style={{"width":"100%", }}>
                            
                            <div className="detailItem">
                              <span className="itemKey">First Name:</span>
                              <span className="itemValue">{first_name}</span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Last Name:</span>
                              <span className="itemValue">{last_name}</span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Email:</span>
                              <span className="itemValue">{userEmail}</span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Phone Number:</span>
                              <span className="itemValue">{phone_number}</span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Organization Name:</span>
                              <span className="itemValue">{display_organization_name}</span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Role:</span>
                              <span className="itemValue">{adminRole}</span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Communities:</span>
                              <span className="itemValue">{displayCommunityData}</span>
                              {/* <span className="itemValue">{displayCommunityIdData}</span> */}
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Password:</span>
                              <span className="itemValue" style={{"color":"green", }}>{password}</span>
                            </div>
                            <hr style={{
                              height: "0.5px",
                              backgroundColor: '#dddddd',
                              marginTop: '20px',
                              marginBottom: '20px',
                              border: "none",
                            }}/>
                            <div className="detailItem">
                              <span className="itemKey">Street Address:</span>
                              <span className="itemValue">
                                {street_address}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">City:</span>
                              <span className="itemValue">
                                {city}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Postal Code:</span>
                              <span className="itemValue">
                                {postal_code}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Country:</span>
                              <span className="itemValue">
                                {country}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Longitude:</span>
                              <span className="itemValue">
                                {longitude}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Latitude:</span>
                              <span className="itemValue">
                                {latitude}
                              </span>
                            </div>

                            <FormControlLabel style={{"marginTop":"20px",}}
                              control={
                                <Switch
                                  checked={isPublishedChecked}
                                  onChange={handlePublishedChange}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              } 
                              label="Accept Terms and Conditions" 
                            />
                            
                          </div>
                        </div>

                        
  
                        {/* <Button 
                          style={{
                            "width": "50%",
                            "height": "50px", 
                            "marginTop":"40px", 
                            "marginBottom":"20px", 
                            "marginRight":"10px",  
                            "marginLeft":"20px",
                          }}
                          size="large"
                          variant="contained"
                          onClick={() => {
                            alert('clicked');
                          }}
                        >
                          Create User
                        </Button> */}
  
                    </div>
  
  
                  </Box> 
                  } 
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )}
                  { activeStep === steps.length - 1 ? 
                                    
                                    <Button 
                                       style={{
                                          "width": "20%",
                                          "height": "50px", 
                                             
                                          "fontSize" : "12px"
                                          }}
                                       size="large"
                                       variant="contained"
                                       onClick={handleSubmit}
                                    >
                                       Create Client User
                                    </Button>
                                     : 
                                    <Button onClick={handleNext}>
                                       {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                 }
                </Box>
              </React.Fragment>
            )}
          </div>
          
        </div>
      </div>

    </div>
  </div>

</div>
</div>

<Modal
            hideBackdrop
            open={openModal}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 450 }}>
            <Button onClick={handleClose}>x</Button>
                <h3 id="child-modal-title" style={{textAlign: "center"}}>Client User Created Successfully</h3>
                {/* <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
                  Welcome
                </p> */}
                <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{first_name} {last_name}</h2>
                <Link to="/home/user_management/" style={{ textDecoration: "none", marginTop: "10px", }}>
                  <span className="hasAccount"><Button 
                      style={{
                        "width": "100%",
                        "height": "50px", 
                        "marginTop":"20px", 
                        "marginBottom":"20px", 
                        "marginRight":"10px",  
                        "marginLeft":"20px",
                      }}
                      size="large"
                      variant="contained"
                      // onClick={handleSubmit}
                  >
                      Continue
                  </Button></span>
                </Link>
            </Box>
          </Modal>

          <Modal
            hideBackdrop
            open={openErrorModal}
            onClose={handleErrorClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 550 }}>
            <Button onClick={handleErrorClose}>x</Button>
                <h3 id="child-modal-title" style={{textAlign: "center"}}>Client User Creation Failed</h3>
                <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
                  {errorMessage}
                </p>
                <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{first_name} {last_name}</h2>
                <span className="hasAccount"><Button 
                      style={{
                        "width": "100%",
                        "height": "50px", 
                        "marginTop":"20px", 
                        "marginBottom":"20px", 
                        "marginRight":"10px",  
                        "marginLeft":"20px",
                      }}
                      size="large"
                      variant="contained"
                      onClick={handleErrorClose}
                  >
                      Continue
                  </Button></span>
            </Box>
          </Modal>
       
      </Main>
    </Box>
  );
    
};

export default Create_User_Management;