import React, { useEffect, useState } from "react";
import DashboardCard from "../../components/card/card";
import SectionHeader from "../../components/custom_text/section_header";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import NavCard_Community from "../../components/nav_card/navcard_community";
import Sidebar from "../../components/sidebars/sidebar";

// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';
import Organization_Details_Card from "../../components/organization_card_details";



import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link, useParams } from 'react-router-dom';
import logoImage from './images/logo.png';
import { Community, User, CommunitySystemTypes, Address, Organization, CommunitySystemTypesList, MaintenanceChecklist, SystemTypes, Maintenance_Checklist_Steps, Maintenance_Checklist_response, Maintenance_checklist_response_files } from '../authentication/authentication_interfaces';
import authentication_services from "../authentication/authentication_services";

import { Grid, SelectChangeEvent } from '@mui/material';
import CommunitySystemTypeTable from "../../components/table/community_systemType_table";
import { data } from '../form/makeData';
import MaintenanceChecklistStepTable from "../../components/table/maintenance_checklist_steps_table";
import MaintenanceChecklistResponseTable from "../../components/table/maintenance_checklist_response_table";
import community_services from "../community/community_services";
import MaintenanceChecklistTable from "../../components/table/maintenance_checklist_table";
import Checklist_DashboardCard from './checklist_dashboard_card';
import Details_Card from "../../components/card/card_details";
import maintenance_checklist_service from "./maintenance_checklist_service";
import organization_services from "../organization/organization_services";
import Community_Details_Card from '../../components/card/card_details_community';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Maintenance_Checklist_Response_Detail : React.FC = () => {

  const { maintenance_checklist_response_Id } = useParams();
  console.log("maintenance_checklist_response_Id " + maintenance_checklist_response_Id);
  
  // const { maintenance_checklistId } = useParams();
  // console.log("maintenance_checklistId " + maintenance_checklistId);
  
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [fullName, setFullName] = useState<string>();
  const [userId, setUserId] = useState([]);
  const [bearer, setBearerToken] = useState<string>('');
  const [community_list_data, setCommunityListData] = useState<Community | null>();
  const [data, setOrganizationData] = useState<Organization | null>();
  const [systemData, setSystemTypeData] = useState<SystemTypes | null>();
  const [userData, setUserData] = useState<User | null>();
  const [userCreatorData, setUserCreatorData] = useState<User | null>();
  const [maintenance_checklist_response_list_data, setMaintenanceChecklistResponseListData] = useState<Maintenance_Checklist_response | null>();
  const [maintenance_checklist_response_files_data, setMaintenanceChecklistResponseFilesData] = useState<Maintenance_checklist_response_files[] | null>([]);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('userId') || '{}');
    const user_fullName = JSON.parse(localStorage.getItem('user_fullName') || '{}');
    const bearerToken = JSON.parse(localStorage.getItem('bearerToken') || '{}');
    console.timeLog(bearerToken);

    //get user details
    authentication_services.getUserDetails(userId, bearerToken).then((response) => {

      const status = response['data']['status'];
      const message = response['data']['message'];
      const userId = response['data']['data']["user_id"];
      console.log("user id " + userId);

      setUserData(response.data.data);
      console.log('User First Name ' + response['data']['data']['first_name']);
      console.log('User API status ' + response['data']['status']);
      
      if(status === "Success"){

        setUserId(userId);
        setFullName(user_fullName);
        setBearerToken(bearerToken);
         
      }

      }).catch(function (error) {

        // setErrorMessage(error.toString());
        console.log('error ' + error.toJSON());
        console.log('error message ' + error.toString());
        // setopenErrorModal(true);

    });

    maintenance_checklist_service.getMaintenanceChecklistResponse(bearerToken, parseInt(maintenance_checklist_response_Id!))
    .then((response) => {

      console.log('API call response status ' + response.status);
      console.log(response.data.data)
      setMaintenanceChecklistResponseListData(response.data.data);
      console.log('Maintenance Checklist Response Question ' + response['data']['data']['question']);

      setUserCreatorData(response['data']['data']['user']);
      setOrganizationData(response['data']['data']['organization']);
      setSystemTypeData(response['data']['data']['systemTypes']);
      setCommunityListData(response['data']['data']['community']);
      setMaintenanceChecklistResponseFilesData(response['data']['data']['maintenance_checklist_response_files']);

    }).catch((error) => {
      console.log('error ' + error.toJSON());
      console.log('error message ' + error.toString());
    });

  }, [bearer]); 


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open} style={{width: "100%"}}>
        <DrawerHeader />
        <DrawerHeader />
        

        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          <Grid item xs={12} md={12} sm={12} lg={12}>
            <SectionHeader sectionTitle={'MAINTENANCE CHECKLIST RESPONSE DETAILS'} link='' />
          </Grid>
          <div style={{height: "20px"}}></div>
          <Grid item xs={12} md={4} sm={6} lg={6}>
            <NavCard_Community cardContent={20} cardTitle={'Create Maintenance Checklist'} image={createImage} link={"/home/maintenance_checklist/create"} />
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={6}>
            <NavCard_Community cardContent={20} cardTitle={'Maintenance Checklist List'} image={listImage} link={"/home/maintenance_checklist/list/:maintenance_checklistId"} />
          </Grid>
          {/* <Grid item xs={12} md={4} sm={6} lg={3}>
            <NavCard_Community cardContent={20} cardTitle={'Edit Maintenance Checklist'} image={editImage} link={"/home/maintenance_checklist/edit/:maintenance_checklistId"} />
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={3}>
            <NavCard_Community cardContent={20} cardTitle={'Maintenance Checklist Details'} image={detailImage} link={"/home/maintenance_checklist/detail/:maintenance_checklistId"} />
          </Grid> */}
          
          {/* <Grid item xs={12} md={4} sm={6} lg={4}>
            <Checklist_DashboardCard cardContent={20} cardTitle={'Total Maintenance Checklist'} image={allForms} link={""}/>
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={4}>
            <Checklist_DashboardCard cardContent={40} cardTitle={'Pending Maintenance Checklist'} image={pendingForms} link={""}/>
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={4}>
            <Checklist_DashboardCard cardContent={230} cardTitle={'Completed Maintenance Checklist'} image={submitted_forms} link={""}/>
          </Grid> */}

          <Grid item xs={12} md={6} sm={12} lg={6}>
            {data != null ? <Details_Card 
                organizationName={data!.name} 
                email={data!.email} 
                image={data!.organization_logo} 
                phoneNumber={data!.phone_number} 
                address={"Lagos, Island"} 
                country={"Nigeria"}
                catrdTile={"Organization Information"}
            /> : "" }
          </Grid>

          <Grid item xs={12} md={6} sm={12} lg={6}>
            {community_list_data != null ? <Community_Details_Card 
                communityName={community_list_data!.community_name} 
                population={community_list_data!.population} 
                image={community_list_data!.image} 
                community_description={community_list_data!.community_description} 
                dateCreated={community_list_data!.created_at}
                address={"Lagos, Island"} 
                country={"Nigeria"}
                catrdTile={"Community Information"}
            /> : "" }
          </Grid>

          {/* <Grid item xs={12} md={6} sm={12} lg={6}>
            <div className="topLevel">
                <div className="leftContent">
                  <h5>Select an organization to view their response to the maintenance checklist</h5>
                  
                </div>
            </div>
          </Grid> */}

          <Grid item xs={12} md={12} sm={12} lg={12}>
            <div className="topLevel">
                <div className="leftContent">
                  {/* <div className="editButton">Edit</div> */}
                  <h3 className="titleContents">System Type and Maintenance Checklist Information</h3>
                  <hr style={{
                      height: "2px",
                      backgroundColor: '#dddddd',
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: "none",
                  }}/>
                  <div className="itemContents">
                    <img
                      src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"
                      alt=""
                      className="itemContentsImg"
                    />
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      { systemData != null ? <Grid item xs={12} md={6} sm={6} lg={4}>
                          <div className="detailsContents">
                            <h3 className="itemTitleContents">{systemData!.name}</h3>
                            <div className="detailItemContents">
                              <span className="itemKeyContents">Description:</span>
                              <span className="itemValueContents">{systemData!.description}</span>
                            </div>
                            <div className="detailItemContents">
                              <span className="itemKeyContents">Date Created:</span>
                              <span className="itemValueContents">{systemData!.created_at}</span>
                            </div>
                            
                          </div>
                        </Grid> : ""}
                      
                      <Grid item xs={12} md={6} sm={6} lg={4}>
                        {
                          userCreatorData != null ? <div className="detailsContents" style={{"marginLeft":"0px"}}>
                            <h3 className="itemTitleContents">Checklist Created by</h3>
                            <div className="detailItemContents">
                              <span className="itemKeyContents">Name:</span>
                              <span className="itemValueContents">{userCreatorData!.first_name} {userCreatorData!.last_name}</span>
                            </div>
                            <div className="detailItemContents">
                              <span className="itemKeyContents">Phone:</span>
                              <span className="itemValueContents">{userCreatorData!.phone_number}</span>
                            </div>
                            <div className="detailItemContents">
                              <span className="itemKeyContents">Email:</span>
                              <span className="itemValueContents">
                                {userCreatorData!.email}
                              </span>
                            </div>
                            
                          </div> : ""
                        }
                      </Grid>
                    </Grid>
                    


                  </div>

                  {/* <hr style={{
                      height: "2px",
                      backgroundColor: '#dddddd',
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: "none",
                  }}/> */}

                    {
                      maintenance_checklist_response_list_data != null ? <div className="itemContents" style={{
                          "marginLeft":"0px", 
                          "border": "0.5px solid lightgray",
                          "padding":"20px", "borderRadius":"10px",
                          "marginTop":"10px",
                          "marginBottom":"10px",
                        }}>
                        <div className="detailsContents" style={{"width":"100%", }}>
                          <h3 className="itemTitleContents">Response Details</h3>
                          <hr style={{
                            height: "0.5px",
                            backgroundColor: '#dddddd',
                            marginTop: '10px',
                            marginBottom: '10px',
                            border: "none",
                          }}/>
                          <div className="detailItemContents">
                            <span className="itemKeyContents">Question:</span>
                            <span className="itemValueContents">{maintenance_checklist_response_list_data!.question}</span>
                          </div>
                          <div className="detailItemContents">
                            <span className="itemKeyContents">Instruction:</span>
                            <span className="itemValueContents">{maintenance_checklist_response_list_data!.instructions}</span>
                          </div>
                          <hr style={{
                              height: "1px",
                              backgroundColor: '#dddddd',
                              marginTop: '10px',
                              marginBottom: '10px',
                              border: "none",
                          }}/>
                          <div className="detailItemContents">
                            <span className="itemKeyContents">Answer:</span>
                            <span className="itemValueContents" style={{"color":"green", }}>{maintenance_checklist_response_list_data!.answer}</span>
                          </div>
                          <div className="detailItemContents">
                            <span className="itemKeyContents">Additional Comment:</span>
                            <span className="itemValueContents">{maintenance_checklist_response_list_data!.additional_comment}</span>
                          </div>
                          <div className="detailItemContents">
                            <span className="itemKeyContents">Images/Videos:</span>
                            <span className="itemValueContents">
                            {maintenance_checklist_response_files_data != null ? maintenance_checklist_response_files_data!.map((files)=> { 
                              return  <div style={{display:"flex"}}>
                                      {
                                          files.resource_type == "image" ?
                                          <img
                                              src={files.url}
                                              alt=""
                                              className="itemContentsImg"
                                          /> :  
                                          <video width="320" height="240" controls>
                                              <source src={files.url} type="video/mp4"/> 
                                          </video>
                                      }
                                  </div> 
                              }) : "No System Type"}
                            </span>
                          </div>
                          <div className="detailItemContents">
                            <span className="itemKeyContents">Is Completed ?:</span>
                            <span className="itemValueContents">{maintenance_checklist_response_list_data!.stepIsCompleted ? "Completed" : "Not Completed"}</span>
                          </div>
                          <div className="detailItemContents">
                            <span className="itemKeyContents">Date Completed:</span>
                            <span className="itemValueContents">
                              {maintenance_checklist_response_list_data!.date_completed}
                            </span>
                          </div>
                          
                        </div>
                      </div> : ""
                    }

                  {/* <div className="itemContents" 
                    style={{
                      "marginLeft":"20px", 
                      "border": "0.5px solid lightgray",
                      "padding":"20px", "borderRadius":"10px",
                      "marginTop":"10px",
                      "marginBottom":"10px",
                    }}>
                    <div className="detailsContents" style={{"width":"100%", }}>
                      <h1 className="itemTitleContents">Table Name</h1>
                      <hr style={{
                        height: "0.5px",
                        backgroundColor: '#dddddd',
                        marginTop: '10px',
                        marginBottom: '10px',
                        border: "none",
                        }}
                      /> 

                      
                                      
                              
                      </div>
                    </div> */}

                </div>
            </div>
          </Grid>

        </Grid>
       
      </Main>
    </Box>
  );
  
};

export default Maintenance_Checklist_Response_Detail;