import React, { useState } from "react";
import { CloudinaryContext, Image, Transformation, AdvancedVideo } from 'cloudinary-react';

const UploadFiles = () => {
  const [files, setFiles] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = async () => {
    console.log("picked files" +files[0]);

    setIsUploading(true);
    if (!files) {
      console.error("No files selected");
      setIsUploading(false);
      return;
    }
    const uploadPromises = Array.from(files).map(async file => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'z2lxfc1h');
      const result = await fetch('https://api.cloudinary.com/v1_1/wellbeyond/upload', {
        method: 'POST',
        body: formData
      }).then((res) => {
         const data = res.json()
        console.log({data})
        return data;
      }).catch((err) => {console.log({err})});
      console.log({result})
      return result;
    });
    const results = await Promise.all(uploadPromises);
    setUploadedFiles(results);
    setIsUploading(false);
  };

  return (
    <CloudinaryContext cloudName="wellbeyond">
      <div>
        <div>
          {console.log({uploadedFiles})}
          { uploadedFiles.length === 0 ?
          <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" className="rounded-circle" width="150"/> :
          uploadedFiles.map(file => (
            <div key={file.public_id}>
              <p>{file.original_filename}</p>
              {file.video ? <video src={file.url} width="300" height="300"/> :
              <Image publicId={file.public_id} >
                <Transformation width="300" height="300" crop="fill" />
              </Image>}
            </div>
          ))}
        </div>
        <input type="file" multiple onChange={e => setFiles(e.target.files)} />
        <button onClick={handleUpload} disabled={isUploading}>
          {isUploading ? "Uploading..." : "Upload"}
        </button>
      </div>
    </CloudinaryContext>)};

export default UploadFiles