import React from 'react';
import './card.scss';
import { Link } from "react-router-dom";
import rightArrowImage from './arrow-point-to-right.png';

//creating a props interface for the DashboardCard components types
interface NavCard_CommunityProps {
    cardTitle: string;
    cardContent: any;
    image: any;
    link: string;
}

const NavCard_Community : React.FC<NavCard_CommunityProps> = ({cardTitle, cardContent, image, link}) => {
    return (
        <div className="cardNavBody">
            
            <Link to={link} style={{ textDecoration: "none" }}>
                <div className='imageContainer'>
                    <img className='image' src={image} alt="Logo" />
                </div>
            </Link>
            <Link to={link} style={{ textDecoration: "none" }}>
                <h1 className='cardNavContentTitle'>{cardTitle} </h1>
            </Link>
            <Link to={link} style={{ textDecoration: "none" }}>
                <div className='imageContainer'>
                    <img className='imageRightArrow' src={rightArrowImage} alt="Logo" />
                </div>
            </Link>
            {/* <h1 className='cardNavContent'>{cardContent} </h1> */}
        </div>
    );
}

export default NavCard_Community;