import axios from "axios";
import http from "../../http-common";

class SystemType_Services {

    config = {
        headers:{
          'content-type': 'application/json',
          // header2: value2
        }
    };

    // eslint-disable-next-line no-dupe-args
    getAllSystemTypeDropDownList(){
        return http.get(`system_type/`, this.config);
    }

    getAllSystemTypeList(bearerToken: string){
        axios.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
        return http.get(`system_type/`, this.config);
    }

    getOneSystemType(bearerToken: string, system_type_id: string){
        axios.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
        return http.get(`system_type/${system_type_id}`, this.config);
    }

    createSuperAdminPost (organization_id: number, profile_image: string, first_name: string, last_name: string, userEmail: string, password: string,
        adminRole: string, phone_number: string, managementRole: string, isPublishedChecked: boolean, diagnostic: boolean, maintenance_checklist: boolean, 
        forms: boolean, impact_measurement: boolean, training: boolean, all_access: boolean ) {

            // let organizationId = organization_name.replace(/[^0-9]/g, '');
        console.log("organization id" + organization_id);
  
        let SaveData = {
           organization_id: organization_id,
           user_token: "string",
           firebase_token: "string",
           profile_image: profile_image,
           first_name: first_name,
           last_name: last_name,
           email: userEmail,
           password: password,
           role: adminRole,
           phone_number: phone_number,
           management_role: managementRole,
           reset_password_expiry_time: "Test",
           reset_password_token: "Test",
           isPhoneVerified: false,
           isEmailVerifed: false,
           active: true,
           accepted_terms: isPublishedChecked,
           suspended: false,
           updated_by: 0,
           created_by: 0,
     
           diagnostic: diagnostic,
           maintenance_checklist: maintenance_checklist,
           forms: forms,
           impact_measurement: impact_measurement,
           training: training,
           all_access: all_access,
     
        };
  
        console.log("user data " + SaveData);
        return http.post(`user/create_user`, SaveData, this.config)

    }

    createSuperAdminAddress (userId: number, street_address: string, city: string, postal_code: string, country: string,  ) {

        let createUserAddressData = {
            user_id: userId,
            community_id : null,
            organization_id: null,
            is_default: true,
            address : street_address,
            state : "userState",
            city : city,
            latitude : "latitude",
            longitude : "longitude",
            postal_code : postal_code,
            country : country
        };
  
        console.log("user address data " + createUserAddressData, );
        return http.post(`address/createAddress`, createUserAddressData, this.config);

    }

}

export default new SystemType_Services();