import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { Community } from "../../screens/authentication/authentication_interfaces";

//creating a props interface for the table components types
interface tableProps {
  data: Community [] | null;
}

const CommunityTableComponent : React.FC<tableProps> = ({data}) => {
    return (
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Community ID</TableCell>
              <TableCell className="tableCell">Community Name</TableCell>
              <TableCell className="tableCell">Organization Name</TableCell>
              <TableCell className="tableCell">Community Logo</TableCell>
              <TableCell className="tableCell">Community System Type(s)</TableCell>
              <TableCell className="tableCell">Date Created</TableCell>
              <TableCell className="tableCell">Status</TableCell>
              <TableCell className="tableCell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data!.map((row) => (
              <TableRow key={row.community_id}>
                <TableCell className="tableCell">{row.community_id}</TableCell>
                <TableCell className="tableCell">{row.community_name}</TableCell>
                <TableCell className="tableCell">{row.organization.name}</TableCell>
                <TableCell className="tableCell">
                  <div className="cellWrapper">
                    <img src={row.image} alt="" className="image" />
                    {/* {row.product} */}
                  </div>
                </TableCell>
                <TableCell className="tableCell">{row.communitySystemTypes.length !== 0 ? row.communitySystemTypes!.map((names)=> { return  <div>{names.system_type_name} <br/></div> }) : "No System Type"}</TableCell>
                <TableCell className="tableCell">{row.created_at}</TableCell>
                <TableCell className="tableCell">
                  <span className={`status ${row.active}`}>{row.active ? 'Active' : 'Inactive'}</span>
                </TableCell>
                <TableCell className="tableCell">
                  <Link to={`/home/community/edit/${row.community_id}`} style={{ textDecoration: "none", marginTop: "10px", }}>
                    <span className="hasAccount"><Button 
                        style={{
                          "width": "100%",
                          "fontSize":"10px",
                        }}
                        startIcon={<SendIcon />}
                        size="small"
                        variant="contained"
                        // onClick={handleSubmit}
                    >
                      Edit
                    </Button></span>
                  </Link>
                  <Link to={`/home/community/detail/${row.community_id}`} style={{ textDecoration: "none", marginTop: "10px", }}>
                    <span className="hasAccount">
                      <Button 
                          style={{
                            "fontSize":"10px",
                            "marginTop":"5px", 
                          }}
                          startIcon={<DeleteIcon />}
                          size="small"
                          variant="outlined"
                          color="secondary"
                          // onClick={handleSubmit}
                      >
                        View
                      </Button></span>
                  </Link>
                  
                  {/* <Button variant="contained">Contained</Button> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
}

export default CommunityTableComponent;