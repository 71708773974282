import React from "react";

import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Theme, useTheme } from '@mui/material/styles';
import { Button, Checkbox, Chip, DialogActions, DialogContent, FormControlLabel, FormGroup, FormLabel, ListItemText, Radio, RadioGroup, Step, StepLabel, Stepper, Switch, Typography } from "@mui/material";


import DashboardCard from "../../components/card/card";
import Custom_Text from "../../components/custom_text";
import SectionHeader from "../../components/custom_text/section_header";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import NavCard_Community from "../../components/nav_card/navcard_community";
import Sidebar from "../../components/sidebars/sidebar";
import './diagnostic.scss'
import SideBarDiagnostic from "./sidebar_diagnostic/sidebar_diagnostic";
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';
import logoImage from './images/logo.png';

import BootstrapDialogTitle, { BootstrapDialog } from "../../components/dialog_box/dialog_text_box";

import { styled } from '@mui/material/styles';

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];


const Create_Diagnostic : React.FC = () => {

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // let handleChange = (event : any) => {}
  let handleSubmit = (event : any) => {}

  const [name, setName] = React.useState('Composed TextField');
  const [age, setAge] = React.useState('');

  const [personName, setPersonName] = React.useState<string[]>([]);
  const [checked, setChecked] = React.useState(true);

  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const [switchChecked, setSwitchChecked] = React.useState(true);

  

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());



  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [openModel, setOpenModel] = React.useState(false);

  const handleClickOpen = () => {
    setOpenModel(true);
  };
  const handleClose = () => {
    setOpenModel(false);
  };


  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchChecked(event.target.checked);
    console.log(switchChecked);
  };


  const handleGroupChecklistChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };


  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  const handleCheckListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };


  const handleMultSelectDropDownChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const handleDropDownChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div className="home">
          <div className="homeContainer">

            <SectionHeader sectionTitle={'CREATE DIAGNOSTICS'} link='' />
            <div className="cardPadding">
          
              <NavCard_Community cardContent={20} cardTitle={'Create Diagnostic'} image={createImage} link={"/home/diagnostic/create"} />
              <NavCard_Community cardContent={20} cardTitle={'Diagnostic List'} image={listImage} link={"/home/diagnostic/list/:diagnosticId"} />
              <NavCard_Community cardContent={20} cardTitle={'Diagnostic Response'} image={editImage} link={"/home/diagnostic/response"} />
              <NavCard_Community cardContent={20} cardTitle={'Diagnostic Details'} image={detailImage} link={"/home/diagnostic/detail/:diagnosticId"} />

            </div>
            {/* <div className="cardPadding">
              <DashboardCard cardContent={20} cardTitle={'Organizations'} image={organizationImage} link={""}/>
              <DashboardCard cardContent={40} cardTitle={'Communities'} image={communityImage} link={""}/>
              <DashboardCard cardContent={230} cardTitle={'Management Users'} image={managementUsersImage} link={""}/>
              <DashboardCard cardContent={130} cardTitle={'People Trained'} image={trainedPeopleImage} link={""}/>
            </div> */}
          
            <div className="top">
              <div className="left">
                <div className="item">
                  <img
                    src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"
                    alt=""
                    className="itemImg"
                  />
                  <div className="details">
                    <h1 className="itemTitle">Ajah</h1>
                    <div className="detailItem">
                      <span className="itemKey">Email:</span>
                      <span className="itemValue">janedoe@gmail.com</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Phone:</span>
                      <span className="itemValue">+1 2345 67 89</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Address:</span>
                      <span className="itemValue">
                        Elton St. 234 Garden Yd. NewYork
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Country:</span>
                      <span className="itemValue">USA</span>
                    </div>
                  </div>

                </div>

                {/* <hr style={{
                    height: "2px",
                    backgroundColor: '#dddddd',
                    marginTop: '10px',
                    marginBottom: '10px',
                    border: "none",
                }}/> */}

                  <div className="formBox" style={{
                    "marginLeft":"10px", 
                    "border": "0.5px solid lightgray",
                    "padding":"20px", 
                    "borderRadius":"5px",
                    "marginTop":"10px",
                    "marginBottom":"10px",
                  }}>
                  <div className="details" style={{"width":"100%", }}>
                    <h1 className="itemTitle">Diagnostic Name</h1>
                    <hr style={{
                      height: "0.5px",
                      backgroundColor: '#dddddd',
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: "none",
                    }}/>

                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1 },
                      }}
                      noValidate
                      autoComplete="off">

                      {/* <FormControl variant="standard">
                        <InputLabel htmlFor="component-helper">Organization Name</InputLabel>
                        <Input
                          id="component-helper"
                          value={name}
                          onChange={handleChange}
                          aria-describedby="component-helper-text"
                        />
                        <FormHelperText id="component-helper-text">
                          Some important helper text
                        </FormHelperText>
                      </FormControl>
                      <FormControl error variant="standard">
                        <InputLabel htmlFor="component-error">Community Name</InputLabel>
                        <Input
                          id="component-error"
                          value={name}
                          onChange={handleChange}
                          aria-describedby="component-error-text"
                        />
                        <FormHelperText id="component-error-text">Error</FormHelperText>
                      </FormControl> */}
                      <FormControl>
                        <InputLabel htmlFor="component-outlined">Name</InputLabel>
                        <OutlinedInput
                          id="component-outlined"
                          value={name}
                          onChange={handleChange}
                          label="Name"
                        />
                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                      </FormControl>
                      {/* dropdown component */}
                      <FormControl sx={{ m: 1, minWidth: 220 }} error>
                      <InputLabel id="demo-simple-select-error-label">Age</InputLabel>
                      <Select
                        labelId="demo-simple-select-error-label"
                        id="demo-simple-select-error"
                        value={age}
                        label="Age"
                        onChange={handleDropDownChange}
                        renderValue={(value) =>  value }
                      >
                        <MenuItem value=""><em>None</em></MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                      <FormHelperText>Error</FormHelperText>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={personName}
                        onChange={handleMultSelectDropDownChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    
                    
                    <FormControlLabel 
                      control={<Checkbox
                        checked={checked}
                        onChange={handleCheckListChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />} 
                      label="Accepted" 
                    />

                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                      <FormLabel component="legend">Assign responsibility</FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox checked={gilad} onChange={handleGroupChecklistChange} name="gilad" />
                          }
                          label="Gilad Gray"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={jason} onChange={handleGroupChecklistChange} name="jason" />
                          }
                          label="Jason Killian"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={antoine} onChange={handleGroupChecklistChange} name="antoine" />
                          }
                          label="Antoine Llorca"
                        />
                      </FormGroup>
                      <FormHelperText>You can display an error</FormHelperText>
                    </FormControl>

                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                      </RadioGroup>
                    </FormControl>

                    <FormControlLabel 
                      control={
                        <Switch
                          checked={switchChecked}
                          onChange={handleSwitchChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      } 
                      label="Switch Control" 
                    />

                    <div>
                      <Button variant="outlined" onClick={handleClickOpen}>
                        Open dialog
                      </Button>
                      <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={openModel}
                      >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                          Modal title
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                          <Typography gutterBottom>
                            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                            consectetur ac, vestibulum at eros.
                          </Typography>
                          <Typography gutterBottom>
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                          </Typography>
                          <Typography gutterBottom>
                            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
                            magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
                            ullamcorper nulla non metus auctor fringilla.
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button autoFocus onClick={handleClose}>
                            Save changes
                          </Button>
                        </DialogActions>
                      </BootstrapDialog>
                    </div>

                    <div>
                      <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                          const stepProps: { completed?: boolean } = {};
                          const labelProps: {
                            optional?: React.ReactNode;
                          } = {};
                          // if (isStepOptional(index)) {
                          //   labelProps.optional = (
                          //     <Typography variant="caption">Optional</Typography>
                          //   );
                          // }
                          if (isStepSkipped(index)) {
                            stepProps.completed = false;
                          }
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                      {activeStep === steps.length ? (
                        <React.Fragment>
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                          </Typography>
                          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                          </Box>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                          {
                            activeStep === 1 ? 
                              <FormControl>
                                <InputLabel htmlFor="component-outlined">Name</InputLabel>
                                <OutlinedInput
                                  id="component-outlined"
                                  value={name}
                                  onChange={handleChange}
                                  label="Name"
                                />
                                <FormHelperText id="component-outlined-text">Error</FormHelperText>
                              </FormControl> 
                              : null 
                            } 
                          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                              color="inherit"
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                            >
                              Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                              </Button>
                            )}
                            <Button onClick={handleNext}>
                              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                          </Box>
                        </React.Fragment>
                      )}
                    </div>
                    


                    </Box>
                    
                  </div>
                </div>

              </div>
            </div>
      
            

          </div>
        </div>
       
      </Main>
    </Box>
  );

};

export default Create_Diagnostic;