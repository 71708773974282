import React from "react";
import DashboardCard from "../../components/card/card";
import Custom_Text from "../../components/custom_text";
import SectionHeader from "../../components/custom_text/section_header";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import Sidebar from "../../components/sidebars/sidebar";
import './forms.scss'
import SideBarForm from './sidebar_form/sidebar_form';
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';
import Organization_Details_Card from "../../components/organization_card_details";

import MaterialReactTable, {MRT_ColumnDef, MRT_Row,} from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { data, Person } from './makeData';


import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import logoImage from './images/logo.png';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


//defining columns outside of the component is fine, is stable
const columns: MRT_ColumnDef<Person>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    size: 40,
  },
  {
    accessorKey: 'firstName',
    header: 'First Name',
    size: 120,
  },
  {
    accessorKey: 'lastName',
    header: 'Last Name',
    size: 120,
  },
  {
    accessorKey: 'company',
    header: 'Company',
    size: 300,
  },
  {
    accessorKey: 'city',
    header: 'City',
  },
  {
    accessorKey: 'country',
    header: 'Country',
    size: 220,
  },
];

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const Form_Details : React.FC = () => {

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleExportRows = (rows: MRT_Row<Person>[]) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  let handleChange = (event : any) => {}
  let handleSubmit = (event : any) => {}


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div className="home">

          <div className="homeContainer">

            <SectionHeader sectionTitle={'FORM DETAILS'} link='' />
            <div className="cardPadding">
          
              <NavCard_Community cardContent={20} cardTitle={'Create Form'} image={createImage} link={"/home/forms/create"} />
              <NavCard_Community cardContent={20} cardTitle={'Forms List'} image={listImage} link={"/home/forms/list/:formsId"} />
              <NavCard_Community cardContent={20} cardTitle={'Submitted Forms'} image={editImage} link={"/home/forms/forms_submitted"} />
              <NavCard_Community cardContent={20} cardTitle={'Form Details'} image={detailImage} link={"/home/forms/detail/:formsId"} />

            </div>
            {/* <div className="cardPadding">
              <DashboardCard cardContent={20} cardTitle={'Organizations'} image={organizationImage} link={""}/>
              <DashboardCard cardContent={40} cardTitle={'Communities'} image={communityImage} link={""}/>
              <DashboardCard cardContent={230} cardTitle={'Management Users'} image={managementUsersImage} link={""}/>
              <DashboardCard cardContent={130} cardTitle={'People Trained'} image={trainedPeopleImage} link={""}/>
            </div> */}

            <Organization_Details_Card 
              organizationName={"Well Aware"} 
              email={"clovis@wellaware.com"} 
              image={"https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"} 
              phoneNumber={"+2347038958555"} 
              address={"Lagos, Island"} 
              country={"Nigeria"}
              catrdTile={"Organization Information"}
            />
          
            <div className="top">
              <div className="left">
                <div className="editButton">Edit</div>
                <h1 className="title">Form Information</h1>
                <hr style={{
                    height: "2px",
                    backgroundColor: '#dddddd',
                    marginTop: '10px',
                    marginBottom: '10px',
                    border: "none",
                }}/>
                <div className="item">
                  <img
                    src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"
                    alt=""
                    className="itemImg"
                  />
                  <div className="details">
                    <h1 className="itemTitle">Ajah</h1>
                    <div className="detailItem">
                      <span className="itemKey">Email:</span>
                      <span className="itemValue">janedoe@gmail.com</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Phone:</span>
                      <span className="itemValue">+1 2345 67 89</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Address:</span>
                      <span className="itemValue">
                        Elton St. 234 Garden Yd. NewYork
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Country:</span>
                      <span className="itemValue">USA</span>
                    </div>
                  </div>

                  <div className="details" style={{"marginLeft":"20px"}}>
                    <h1 className="itemTitle">Okonkwo Clovis</h1>
                    <div className="detailItem">
                      <span className="itemKey">Email:</span>
                      <span className="itemValue">janedoe@gmail.com</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Phone:</span>
                      <span className="itemValue">+1 2345 67 89</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Address:</span>
                      <span className="itemValue">
                        Elton St. 234 Garden Yd. NewYork
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Country:</span>
                      <span className="itemValue">USA</span>
                    </div>
                  </div>


                </div>

                {/* <hr style={{
                    height: "2px",
                    backgroundColor: '#dddddd',
                    marginTop: '10px',
                    marginBottom: '10px',
                    border: "none",
                }}/> */}

                <div className="item" style={{
                    "marginLeft":"20px", 
                    "border": "0.5px solid lightgray",
                    "padding":"20px", "borderRadius":"10px",
                    "marginTop":"10px",
                    "marginBottom":"10px",
                  }}>
                  <div className="details" style={{"width":"100%", }}>
                    <h1 className="itemTitle">Form Name</h1>
                      <hr style={{
                        height: "0.5px",
                        backgroundColor: '#dddddd',
                        marginTop: '10px',
                        marginBottom: '10px',
                        border: "none",
                      }}/>                
                                      <div className="detailItem">
                                        <span className="itemKey">Form Name:</span>
                                        <span className="itemValue">Bolehole Services</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Organization Name:</span>
                                        <span className="itemValue" style={{"color":"green", }}>Well Aware Active</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Form Type:</span>
                                        <span className="itemValue" style={{"color":"green", }}>Clovis Bole Hole Services</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Form Category:</span>
                                        <span className="itemValue" style={{"color":"green", }}>Water System</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Community Name:</span>
                                        <span className="itemValue" style={{"color":"green", }}>Ajah Community</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">System Type:</span>
                                        <span className="itemValue" style={{"color":"green", }}>Bole Hole</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Created On:</span>
                                        <span className="itemValue">
                                          30-09-2022
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Completed On:</span>
                                        <span className="itemValue">
                                          30-09-2022
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Form Description:</span>
                                        <span className="itemValue">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span>
                                      </div>
                                      <hr style={{
                                        height: "0.5px",
                                        backgroundColor: '#dddddd',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        border: "none",
                                      }}/>
                                      <div className="detailItem">
                                        <span className="itemKey">Question One:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Two:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Three:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Four:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Five:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Six:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Seven:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Eight:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Nine:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Ten:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                    </div>
                </div>

                <div className="item" 
                  style={{
                    "marginLeft":"20px", 
                    "border": "0.5px solid lightgray",
                    "padding":"20px", "borderRadius":"10px",
                    "marginTop":"10px",
                    "marginBottom":"10px",
                  }}>
                  <div className="details" style={{"width":"100%", }}>
                    <h1 className="itemTitle">Form Name</h1>
                    <hr style={{
                      height: "0.5px",
                      backgroundColor: '#dddddd',
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: "none",
                      }}
                    /> 

                    <MaterialReactTable
                      columns={columns}
                      data={data}
                      enableRowSelection
                      positionToolbarAlertBanner="bottom"
                      renderTopToolbarCustomActions={({ table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                              <Button
                                color="primary"
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                onClick={handleExportData}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export All Data
                              </Button>
                              <Button
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                  handleExportRows(table.getPrePaginationRowModel().rows)
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export All Rows
                              </Button>
                              <Button
                                disabled={table.getRowModel().rows.length === 0}
                                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                onClick={() => handleExportRows(table.getRowModel().rows)}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export Page Rows
                              </Button>
                              <Button
                                disabled={
                                  !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                }
                                //only export selected rows
                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export Selected Rows
                              </Button>
                            </Box>
                          )}
                        />
                                    
                            
                    </div>
                  </div>

              </div>
            </div>

          </div>
        </div>
       
      </Main>
    </Box>
  );
  
};

export default Form_Details;