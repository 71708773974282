import React from 'react';
import './details_card.scss';

//creating a props interface for the DashboardCard components types
//creating a props interface for the DashboardCard components types
interface Details_CardProps {
    organizationName: string;
    email: String;
    image: any;
    phoneNumber: string;
    address: string;
    country: string;
    catrdTile: string;
    
}

const Details_Card : React.FC<Details_CardProps> = ({organizationName, email, image, phoneNumber, address, country, catrdTile }) => {
    return (
        <div className="topLevel">
          <div className="leftContent">
            {/* <div className="editButton">Edit</div> */}
            <h3 className="title"> {catrdTile}</h3>
            <hr style={{
                height: "2px",
                backgroundColor: '#dddddd',
                marginTop: '10px',
                marginBottom: '10px',
                border: "none",
            }}/>
            <div className="itemContents">
              <img
                src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"
                alt=""
                className="itemContentsImg"
              />
              <div className="detailsContents">
                <h2 className="itemTitleContents">{organizationName}</h2>
                <div className="detailItemContents">
                  <span className="itemKeyContents">Email:</span>
                  <span className="itemValueContents">{email}</span>
                </div>
                <div className="detailItemContents">
                  <span className="itemKeyContents">Phone:</span>
                  <span className="itemValueContents">{phoneNumber}</span>
                </div>
                {/* <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">
                    {address}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">{country}</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
    );
}

export default Details_Card;