import React from "react";
import Custom_Text from "../../components/custom_text";
import './sign_up.css'


const ForgotPassword : React.FC = () => {

  let handleChange = (event : any) => {}
  let handleSubmit = (event : any) => {}
  
  return (
    <div className='wrapper'>
         <div className='form-wrapper'>
            <Custom_Text/>
            <h2>Forgot Password</h2>
            <form onSubmit={handleSubmit} noValidate >
               <div className='email'>
                  <label htmlFor="email">Email</label>
                  <input type='email' name='email' onChange ={handleChange}/>
               </div>             
               <div className='submit'>
                  <button>Reset Password</button>
               </div>
          </form>
      </div>
   </div>
  );
};

export default ForgotPassword;