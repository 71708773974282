import Custom_Text from "../../components/custom_text";
import './user_management.scss'
import SideBarUserManagement from './sidebar_user_management/sidebaruser_management';
import NavBarAdmin from "../../components/navbar/navbar_admin";
import SectionHeader from "../../components/custom_text/section_header";
import DashboardCard from "../../components/card/card";
import TableList from "../../components/table/User_Table";
import Sidebar from "../../components/sidebars/sidebar";
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';
import DataTableComponentForms from "../../components/table/data_table_component_forms";



import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import logoImage from './images/logo.png';
import React, { useEffect, useState } from "react";
import Administrator from '../administrator/administrator';
import axios from "axios";

const drawerWidth = 240;

export interface User {
  user_id: number;
  organization_id: number;
  user_token: string;
  firebase_token: string;
  profile_image?: any;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  role: string;
  phone_number: string;
  management_role: string;
  reset_password_expiry_time: string;
  reset_password_token?: any;
  isPhoneVerified: boolean;
  isEmailVerifed: boolean;
  active: boolean;
  accepted_terms: boolean;
  suspended: boolean;
  updated_by: number;
  created_by: number;
  created_at: Date;
  updated_at: Date;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const data : any = [
  {
    name: 'Clovis John',
    community: "Well beyond",
    form_name: "Maintenance Form",
    link_to_pdf: "",
    submitted_date: "28:10:2022",
    age: 30,
  },
  {
    name: 'John Trained',
    community: "Well beyond",
    form_name: "Maintenance Form",
    link_to_pdf: "",
    submitted_date: "28:10:2022", 
    age: 30,
  },
  {
    name: 'Princess John',
    community: "Well beyond",
    form_name: "Maintenance Form",
    link_to_pdf: "",
    submitted_date: "28:10:2022", 
    age: 30,
  },
]

const Super_Admin_List : React.FC = () => {

  
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

    let handleChange = (event : any) => {}
    let handleSubmit = (event : any) => {}

    const [super_admin_list_data, setSuperAdminListData] = useState<User[] | null>([]);
    console.log({super_admin_list_data})
    useEffect(() => {
      getData();
    }, []);
    //Api calls
    const getData = async () => {
      const userResponse = await axios.get(`http://api.wellbeyondwater.com:8000/api/user/superAdmin`).then((response) => {
        console.log('API call response status ' + response.status);
        setSuperAdminListData(response.data.data);
        console.log('Community Name ' + response['data']['data'][0]['name']);
        // console.log('Organization Name ' + userResponse['data']['status']);
      }).catch((err) => {
        console.log(err)
      })
    };

    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
  
            <Link to="/home" style={{ textDecoration: "none" }}>
                  {/* <span className='logo'>WellBeyond</span> */}
                  <img
                      src={logoImage}
                      alt=""
                      className="itemImg"
                  />
              </Link>
          </Toolbar>
          
          <NavBarAdmin/>
  
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? 
              <img
                src={logoImage}
                alt=""
                className="itemImg"
                  
              /> : 
              <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Sidebar />
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <div className="home">

            <div className="homeContainer">

              <SectionHeader sectionTitle={'SUPER ADMINS'} link='' />
              <div className="cardPadding">
                <NavCard_Community cardContent={20} cardTitle={'Create User'} image={createImage} link={"/home/user_management/create"} />
                <NavCard_Community cardContent={20} cardTitle={'Create Client'} image={createImage} link={"/home/user_management/create_client"} />
                <NavCard_Community cardContent={20} cardTitle={'Create Super Admin'} image={createImage} link={"/home/user_management/create_super_admin"} />
              </div>

              <div className="cardPadding">
                <NavCard_Community cardContent={20} cardTitle={'Super Admins'} image={listImage} link={"/home/user_management/list/super_admins"} />
                <NavCard_Community cardContent={20} cardTitle={'Clients'} image={listImage} link={"/home/user_management/list/clients"} />
                <NavCard_Community cardContent={20} cardTitle={'Management User'} image={listImage} link={"/home/user_management/list/users"} />

              </div>
              <div className="cardPadding">
                <NavCard_Community cardContent={20} cardTitle={'Edit Super Admin'} image={editImage} link={"/home/user_management/edit/super_admin/:user_id"} />
                <NavCard_Community cardContent={20} cardTitle={'Edit Client'} image={editImage} link={"/home/user_management/edit/client/:user_id"} />
                {/* <NavCard_Community cardContent={20} cardTitle={'Edit User'} image={editImage} link={"/home/profile/edit/:profileId"} /> */}
                <NavCard_Community cardContent={20} cardTitle={'Edit Management User'} image={editImage} link={"/home/user_management/edit/user/:user_id"} />
                <NavCard_Community cardContent={20} cardTitle={'User Details'} image={detailImage} link={"/home/profile"} />
              </div>
            <div className="cardPadding">
              {/* <DashboardCard cardContent={20} cardTitle={'Organizations'} image={organizationImage} link={""}/> */}
              {/* <DashboardCard cardContent={40} cardTitle={'Communities'} image={communityImage} link={""}/> */}
              {/* <DashboardCard cardContent={230} cardTitle={'Management Users'} image={managementUsersImage} link={""}/> */}
              <DashboardCard cardContent={super_admin_list_data?.length} cardTitle={'Total Number of Super Admins'} image={trainedPeopleImage} link={""}/>
            </div>

              {/* <SectionHeader sectionTitle={'DATA LISTS'} link='' />
              <DataTableComponentForms data={data}/> */}
              <div className="listContainer">
                <div className="listTitle">Super Administrators</div>
                <TableList data={super_admin_list_data}/>
              </div>

            </div>
          </div>
         
        </Main>
      </Box>
    );
  
};

export default Super_Admin_List;