import ProgressBar from '@ramonak/react-progress-bar';
import React from 'react';
import './progress_bar_chart.scss';
import { Line, Circle } from 'rc-progress';

//creating a props interface for the DashboardCard components types
interface ProgressBarChartProps {
    progressBarTitle: string;
    progressBarListTitle: any;
    ratingFirst: number;
    ratingSecond: number;
    ratingThird: number;
    ratingForth: number;
}

const ProgressBarChart : React.FC<ProgressBarChartProps> = ({progressBarTitle, progressBarListTitle, 
    ratingFirst, ratingSecond, ratingThird, ratingForth }) => {
    return (
        <div className="progressBarBody"> 
            <h1 className='progressBarTitle'>{progressBarTitle} </h1>
            <div className="">
                <h1 className='progressBarListTitle'>Initial Survey </h1>
                <h1 className='progressBarScore'>{ratingFirst}</h1>
            </div>
            <Line percent={ratingFirst} strokeWidth={1} strokeColor="#FFB200" trailColor="#FFF5CC" gapDegree={5}  />
            {/* <Circle percent={10} strokeWidth={4} strokeColor="#D3D3D3" /> */}
            <h1 className='progressBarListTitle'>2nd Survey Round Complete</h1>
            <h1 className='progressBarScore'>{ratingSecond}</h1>
            <Line percent={ratingSecond} strokeWidth={1} strokeColor="#4339F2" trailColor="#DAD7FE" gapDegree={5}  />
            
            <h1 className='progressBarListTitle'>3rd Survey Round Complete </h1>
            <h1 className='progressBarScore'>{ratingThird}</h1>
            <Line percent={ratingThird} strokeWidth={1} strokeColor="#02A0FC" trailColor="#CCF8FE" gapDegree={5}  />
            
            <h1 className='progressBarListTitle'>4th Survey Round Complete </h1>
            <h1 className='progressBarScore'>{ratingForth}</h1>
            <Line percent={ratingForth} strokeWidth={1} strokeColor="#FF3A29" trailColor="#FFE5D3" gapDegree={5}  />
            
        </div>
    );
}

export default ProgressBarChart;