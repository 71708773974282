import React from "react";
import DashboardCard from "../../components/card/card";
import Custom_Text from "../../components/custom_text";
import SectionHeader from "../../components/custom_text/section_header";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import Sidebar from "../../components/sidebars/sidebar";
import './maintenance_checklist.scss'
import SideBarMaintenanceChecklist from './sidebar_maintenance_checklist/sidebarmaintenance';
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';

import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Button, FormControlLabel, FormHelperText, MenuItem, Select, SelectChangeEvent, Step, StepLabel, Stepper, Switch, TextField, Typography, Checkbox, Modal, Grid } from '@mui/material';
import Maintenance_Checklist from './maintenance_checklist';


import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import logoImage from './images/logo.png';
import { Link, useParams } from 'react-router-dom';
import organization_services from "../organization/organization_services";
import systemtype_services from "../system_type/systemtype_services";
import {useEffect, useState} from 'react';
import authentication_services from "../authentication/authentication_services";
import { Community, MaintenanceChecklist, Maintenance_Checklist_Steps, Organization, SystemTypes, User } from "../authentication/authentication_interfaces";
import community_services from "../community/community_services";
import { useFilePicker } from "use-file-picker";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Hidden } from '@mui/material';
import { blue } from "@mui/material/colors";
import maintenance_checklist_service from "./maintenance_checklist_service";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export interface DynamicForms {
  question: string, 
  instruction: string, 
  yesOrNo: string, 
  images_video: CloudinaryResponse[], 
}

export interface DynamicFormsAnswer {
    question: string, 
    instruction: string, 
    status: string,
    answer: string,
    additional_comment: string,
    photo: CloudinaryResponse[], 
  }

export interface CloudinaryResponse {
  asset_id: string, 
  format: string, 
  public_id: string, 
  resource_type: string, 
  secure_url: string,
  url: string,
  type: string
}

let modules = {
  toolbar: [
    // [{ 'header': '1'}, { 'font': [] }],
    // [ {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold',],
    ['italic',],
    ['underline',],
    [ 'strike',],
    ['blockquote'],
    [{'indent': '-1'}, ],
    [{'indent': '+1'}],
    [{'list': 'ordered'},],
    [{'list': 'bullet'},],
    // ['image',],
    // ['video'],
    ['link',],
    ['clean'],
    [{container: "#toolbar",}]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}
/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
let formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]

const steps = ['Maintenance Checklist Question Response', 'Confirm Maintenance Checklist Answer'];

const Maintenance_Checklist_Response : React.FC = () => {

    const { maintenance_checklist_step_Id } = useParams();
    console.log("maintenance_checklist_step_Id " + maintenance_checklist_step_Id);

    const { maintenance_checklistId } = useParams();
    console.log("maintenance_checklistId " + maintenance_checklistId);

  const [value, setQuillValue] = useState('Additional Comments');
  // const [files, setFiles] = useState<File>();
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [photoFiles, setPhotoFiles] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [fileUrl, setFileUrl] = useState('');

  const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
    // accept: ['.txt', '.png', '.jpg', '.jpeg', '.mp4'],
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    limitFilesConfig: { max: 4 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 2350,
    // imageSizeRestrictions: {
    //   maxHeight: 900, // in pixels
    //   maxWidth: 1600,
    //   minHeight: 600,
    //   minWidth: 768,
    // },
  });

  const uploadFiles = async ()  =>  {
    console.log(filesContent);
    const allUploads = filesContent.map(async (files) =>  {
      const formData = new FormData();
      formData.append('file', files.content);
      formData.append('upload_preset', 'z2lxfc1h');
      const result = await fetch('https://api.cloudinary.com/v1_1/wellbeyond/upload', {
        method: 'POST',
        body: formData
      }).then((res) => {
        const data = res.json()
        console.log({data})
        return data;
      }).catch((err) => {console.log({err})});
      console.log({result});
      console.log(result["secure_url"]);

      return result;
    });

    const results = await Promise.all(allUploads);
    setUploadedFiles(results);
    console.log(uploadedFiles);
  }

  // const uploadFiles = async ()  =>  {
  //   console.log(filesContent);
  //   const formData = new FormData();
  //   formData.append('file', filesContent[0].content!);
  //   formData.append('upload_preset', 'z2lxfc1h');
  //   const result = await fetch('https://api.cloudinary.com/v1_1/wellbeyond/upload', {
  //     method: 'POST',
  //     body: formData
  //   }).then((res) => {
  //       const data = res.json()
  //     console.log({data})
  //     return data;
  //   }).catch((err) => {console.log({err})});
  //   console.log({result})
  //   return result;
  // }

  const [error, setUserError] = React.useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [isPublishedChecked, setPublishedChecked] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const [organization_name, setOrganization] = React.useState('');
  const [community_name, setCommunity] = React.useState('');
  const [system_type, setSystem_Type] = React.useState('');
  const [userData, setUserData] = useState<User | null>();

  const [checklist_name, setChecklistName] = React.useState('');
  const [checklist_category, setChecklistCategory] = React.useState('');
  const [checklist_description, setChecklistDescriptionValue] = React.useState('');
  const [checklist_frequency, setFrequencyValue] = React.useState('');

  const [fullName, setFullName] = useState<string>();
  const [userId, setUserId] = useState([]);
  const [bearer, setBearerToken] = useState<string>('');
  const [community_list_data, setCommunityListData] = useState<Community[] | null>([]);
  const [data, setOrganizationData] = useState<Organization[] | null>();
  const [systemData, setSystemTypeData] = useState<SystemTypes[] | null>();
  const [displaySystemTypeData, setDisplaySystemTypeData] = useState<any[]>();
  const [displaySystemTypeDataId, setDisplaySystemTypeDataID] = useState<any[]>([]);
  const [numberOfSteps, setNumberOfSteps] = useState('0');
  const [isYesOrNo, setIsYesOrNoChecked] = React.useState('');

  

  const [maintenance_checklist_list_data, setMaintenanceChecklistData] = useState<MaintenanceChecklist | null>();
  const [maintenance_checklist_step_list_data, setMaintenanceChecklistStepListData] = useState<Maintenance_Checklist_Steps[] | null>([]);

  let [user_Id, setUser_Id] = useState<number>(0);
  let [communityId, setCommunityId] = useState<number>(0);
  let [organizationId, setOrganizationId] = useState<number>(0);
  let [maintenanceChecklistId, setMaintenanceChecklistId] = useState<number>(0);
  let [systemTypeId, setSystemTypeId] = useState<number>(0);
  const [userCreatorData, setUserCreatorData] = useState<User | null>();

  const [status, setStatusChecked] = React.useState('');
  const [answers, setAnswers] = useState<string>("");
  const [question, setQuestions] = useState<string>("");
  let [stepCount, setStepCount] = useState<number>(0);
  let [stepCountFinish, setStepCountFinished] = useState<number>(0);
  let [storedData, setStoredData] = useState<DynamicFormsAnswer[]>([]);
  let [zeroIndexFix, setZeroIndexFix] = useState<number>(0);

  const handleAddQuestion = (event:any) => {

    setPhotoFiles(uploadedFiles);
    
    // if (zeroIndexFix === 0 && stepCountFinish === 0){

    //     zeroIndexFix = zeroIndexFix + 1
    //     stepCountFinish = stepCountFinish + 0;
    //     setZeroIndexFix(zeroIndexFix);
    //     setStepCountFinished(stepCountFinish);

    // }else{

    //     stepCountFinish = stepCountFinish + 1;
    //     setStepCountFinished(stepCountFinish);

    // }

    if(stepCountFinish + 1 === stepCount){

        stepCountFinish = stepCountFinish + 0;
        setStepCountFinished(stepCountFinish);

    }else{

        stepCountFinish = stepCountFinish + 1;
        setStepCountFinished(stepCountFinish);

    }

    // stepCountFinish = stepCountFinish + 1;
    // setStepCountFinished(stepCountFinish);

    console.log(stepCount);
    console.log(stepCountFinish);
    console.log(value);
    console.log(answers);
    event.preventDefault();

    const maintenance_checklist_stepAddedDatas = {
        question: maintenance_checklist_step_list_data![stepCountFinish]!.question, 
        instruction: maintenance_checklist_step_list_data![stepCountFinish]!.instruction, 
        status: status,
        answer: answers,
        additional_comment: value,
        photo: photoFiles, 
    };

    //pushing items into setState array
    setStoredData([...storedData, maintenance_checklist_stepAddedDatas]);

    setQuillValue('');
    setAnswers('');
    setIsYesOrNoChecked('');
    // uploadedFiles.length = 0;
    setStatusChecked('');


    // console.log("questions and instructions " + maintenance_checklist_stepAddedDatas);
    // console.log("stored answer, additional_comment, questions and instructions " + storedData);
    // console.log("stored answer, additional_comment, questions and instructions " + storedData![0].answer + ' ' + storedData![0].additional_comment + ' ' + storedData![0].instruction + ' ' + storedData![0].question);

  }

  const clearQuestions  = () => {

    storedData!.pop();
    stepCountFinish = stepCountFinish - 1;
    setStepCountFinished(stepCountFinish);
    console.log(stepCount);
    console.log(stepCountFinish);
    uploadedFiles.length = 0;
    
  };

  const handleSubmit = (e:any) => {
    console.log(value);
    console.log(uploadedFiles[0].secure_url);
    console.log(uploadedFiles.length);
    e.preventDefault();
    console.log(storedData!.length);
  }

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('userId') || '{}');
    const user_fullName = JSON.parse(localStorage.getItem('user_fullName') || '{}');
    const bearerToken = JSON.parse(localStorage.getItem('bearerToken') || '{}');

    //get user details
    authentication_services.getUserDetails(userId, bearerToken).then((response) => {

      const status = response['data']['status'];
      const message = response['data']['message'];
      const userId = response['data']['data']["user_id"];
      console.log("user id " + userId);

      setUserData(response.data.data);
      console.log('User First Name ' + response['data']['data']['first_name']);
      console.log('User API status ' + response['data']['status']);
      
      if(status === "Success"){

        setUserId(userId);
        setFullName(user_fullName);
        setBearerToken(bearerToken);
         
      }

      }).catch(function (error) {

        // setErrorMessage(error.toString());
        console.log('error ' + error.toJSON);
        console.log('error message ' + error.toString());
        // setopenErrorModal(true);

    });
    
    //Get all organizations list 
    organization_services.getAllOrganizationsDropDownList()
    .then((response) => {

      console.log('API call response status ' + response.status);
      setOrganizationData(response.data.data);
      console.log('Organization Name ' + response['data']['data'][0]['name']);
      console.log('Organization Name ' + response['data']['status']);

    }).catch((error) => {
      console.log('error ' + error.toJSON);
      console.log('error message ' + error.toString());
    });

    //Get all system type list 
    systemtype_services.getAllSystemTypeDropDownList()
    .then((response) => {

      console.log('API call response status ' + response.status);
      setSystemTypeData(response.data.data);
      console.log('Organization Name ' + response['data']['data'][0]['name']);
      console.log('Organization Name ' + response['data']['status']);

    }).catch((error) => {
      console.log('error ' + error.toJSON);
      console.log('error message ' + error.toString());
    });
    
    //get list of communities
    community_services.getAllCommunityList(bearerToken)
    .then((response) => {

      console.log('API call response status ' + response.status);
      console.log(response.data.data)
      setCommunityListData(response.data.data);
      console.log('Community Name ' + response['data']['data'][0]['community_name']);

    }).catch((error) => {
      console.log('error ' + error.toJSON);
      console.log('error message ' + error.toString());
    });

    maintenance_checklist_service.getMaintenanceChecklist(bearerToken, parseInt(maintenance_checklistId!))
    .then((response) => {

      console.log('maintenance checklist API call response status ' + response.status);
      console.log(response.data.data)
      setMaintenanceChecklistData(response.data.data);
      console.log('Maintenance Checklist Name ' + response['data']['data']['title']);
      setMaintenanceChecklistStepListData(response['data']['data']['maintenanceChecklistSteps']);
      setUserCreatorData(response['data']['data']['user']);
      setOrganizationData(response['data']['data']['organization']);
      setSystemTypeData(response['data']['data']['system_type']);
      setNumberOfSteps(`${response['data']['data']['number_of_steps']}`);
      setStepCount(response['data']['data']['number_of_steps']);

    }).catch((error) => {
      console.log('error ' + error.toJSON);
      console.log('error message ' + error.toString());
    });

    // maintenance_checklist_service.getMaintenanceChecklistSteps(bearerToken, parseInt(maintenance_checklist_step_Id!))
    // .then((response) => {

    //   console.log('Maintenance checklist step API call response status ' + response.status);
    //   console.log(response.data.data)
    //   setMaintenanceChecklistStepListData(response.data.data);
    //   console.log('Maintenance Checklist Steps ' + response['data']['data']['title']);
    //   setMaintenanceChecklistStepListData(response['data']['data']);
    //   setUser_Id(response['data']['data'][0]['user']);
    //   setOrganizationId(response['data']['data'][0]['organization']);
    //   setCommunityId(response['data']['data'][0]['community_id']);
    //   setMaintenanceChecklistId(response['data']['data'][0]['maintenance_checklist_id']);
    //   setSystemTypeId(response['data']['data'][0]['system_type_id']);

    // }).catch((error) => {
    //   console.log('error ' + error.toJSON);
    //   console.log('error message ' + error.toString());
    // });

  }, [bearer]); 

  const [display_systemType_name, setDisplaySystemTypeName] = React.useState<string>('');
  const [display_systemType_id, setDisplaySystemTypeId] = React.useState<number>();
  const [errorMessage, setErrorMessage] = React.useState();

  useEffect(() => {
    getSystemTypeName(system_type);
   }, [system_type]);

  function getSystemTypeName(str: string) {
    var values = str.split(" ");
    var id = values.shift();
    var name = values.join(' ');
    setDisplaySystemTypeName(name);
    setDisplaySystemTypeId(parseInt(id!));


    console.log("System Type Id " + id);
    console.log("System Type Name " + name);
  }

  const handleIsYesOrNoChange = (event: SelectChangeEvent) => {
    setIsYesOrNoChecked(event.target.value);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatusChecked(event.target.value);
  };

  const handleMultilineChecklistDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecklistDescriptionValue(event.target.value);
  };

  const handleQuestionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestions(event.target.value);
  };

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswers(event.target.value);
  };

  const handleDropDownCategoryCategory = (event: SelectChangeEvent) => {
    setChecklistCategory(event.target.value);
  };

  const handleDropDownFrequency = (event: SelectChangeEvent) => {
    setFrequencyValue(event.target.value);
  };

  const handleChecklistNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecklistName(event.target.value);
  };

  const handleNumberOfStepsChange = (event: SelectChangeEvent) => {
    setNumberOfSteps(event.target.value);
    setStepCount(parseInt(event.target.value));
    setStepCountFinished(0);
    console.log(numberOfSteps);
  };

  const handleDropDownSystemTypeChange = (event: SelectChangeEvent) => {
    setSystem_Type(event.target.value);
  };

  const handleDropDowOrganizationChange = (event: SelectChangeEvent) => {
    setOrganization(event.target.value);
  };

  const handleDropDownCommunityChange = (event: SelectChangeEvent) => {
    setCommunity(event.target.value);
  };

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = (event: any) => {
    event.preventDefault();

    setPhotoFiles(uploadedFiles);

    const maintenance_checklist_stepAddedDatas = {
        question: maintenance_checklist_step_list_data![stepCountFinish]!.question, 
        instruction: maintenance_checklist_step_list_data![stepCountFinish]!.instruction, 
        status: status,
        answer: answers,
        additional_comment: value,
        photo: photoFiles, 
    };

    //pushing items into setState array
    setStoredData([...storedData, maintenance_checklist_stepAddedDatas]);

    setQuillValue('');
    setAnswers('');
    setIsYesOrNoChecked('');
    // uploadedFiles.length = 0;
    setStatusChecked('');

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handlePublishedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublishedChecked(event.target.checked);
    console.log(isPublishedChecked);
  };

  if (loading) {
    return <Modal
    hideBackdrop
    open={loading}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
  >
    <Box sx={{ ...style, width: 250 }}>
        <h3 id="child-modal-title" style={{textAlign: "center"}}>Loading</h3>
        <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
          Loading Image/Video
        </p>
        <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{community_name}</h2>
    </Box>
  </Modal>;
  }

  // if (errors.length) {
  //   return <div>Error...</div>;
  // }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open} style={{width: "100%"}}>
        <DrawerHeader />
        <DrawerHeader />

        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          <Grid item xs={12} md={12} sm={12} lg={12}>
            <SectionHeader sectionTitle={'MAINTENANCE CHECKLIST RESPONSE'} link='' />
          </Grid>
          <div style={{height: "20px"}}></div>
          <Grid item xs={12} md={4} sm={6} lg={6}>
            <NavCard_Community cardContent={20} cardTitle={'Create Maintenance Checklist'} image={createImage} link={"/home/maintenance_checklist/create"} />
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={6}>
            <NavCard_Community cardContent={20} cardTitle={'Maintenance Checklist List'} image={listImage} link={"/home/maintenance_checklist/list/:maintenance_checklistId"} />
          </Grid>
          {/* <Grid item xs={12} md={4} sm={6} lg={3}>
            <NavCard_Community cardContent={20} cardTitle={'Edit Maintenance Checklist'} image={editImage} link={"/home/maintenance_checklist/edit/:maintenance_checklistId"} />
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={3}>
            <NavCard_Community cardContent={20} cardTitle={'Maintenance Checklist Details'} image={detailImage} link={"/home/maintenance_checklist/detail/:maintenance_checklistId"} />
          </Grid> */}
          
          {/* <Grid item xs={12} md={4} sm={6} lg={4}>
            <Checklist_DashboardCard cardContent={20} cardTitle={'Total Maintenance Checklist'} image={allForms} link={""}/>
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={4}>
            <Checklist_DashboardCard cardContent={40} cardTitle={'Pending Maintenance Checklist'} image={pendingForms} link={""}/>
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={4}>
            <Checklist_DashboardCard cardContent={230} cardTitle={'Completed Maintenance Checklist'} image={submitted_forms} link={""}/>
          </Grid> */}

          <Grid item xs={12} md={12} sm={12} lg={12}>
          <form onSubmit={handleSubmit}>
                    <div className="topLevel">
                        <div className="leftContent">
                        

                            <div className="formBox" style={{
                            "marginLeft":"10px", 
                            "border": "0.5px solid lightgray",
                            "padding":"20px", 
                            "borderRadius":"5px",
                            "marginTop":"10px",
                            "marginBottom":"10px",
                            }}>
                            <div className="details" style={{"width":"100%", }}>
                            <h1 className="itemTitle">Maintenance Checklist Response</h1>
                            <hr style={{
                                height: "0.5px",
                                backgroundColor: '#dddddd',
                                marginTop: '10px',
                                marginBottom: '10px',
                                border: "none",
                            }}/>

                            <div style={{"width":"100%", "marginTop": "30px"}}>
                                <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps: { completed?: boolean } = {};
                                    const labelProps: {
                                    optional?: React.ReactNode;
                                    } = {};
                                    // if (isStepOptional(index)) {
                                    //   labelProps.optional = (
                                    //     <Typography variant="caption">Optional</Typography>
                                    //   );
                                    // }
                                    if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                    }
                                    return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                    );
                                })}
                                </Stepper>
                                {activeStep === steps.length ? (
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </React.Fragment>
                                ) : (
                                <React.Fragment>
                                    {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                                    {
                                    activeStep === 0 ? 

                                        <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1 },
                                        }}
                                        noValidate
                                        autoComplete="off">
                        
                                        <div style={{"width":"100%", "marginTop": "30px"}}>

                                                <div >

                                                        { maintenance_checklist_step_list_data![stepCountFinish]! != null ?
                                                        
                                                        <div className="itemContents" style={{
                                                            "margin":"10px", 
                                                            "border": "0.5px solid lightgray",
                                                            "padding":"20px", "borderRadius":"10px",
                                                            "marginTop":"10px",
                                                            "marginBottom":"10px",
                                                            "width": "98%"
                                                        }}>
                                                            <div className="detailsContents" style={{"width":"100%", }}>
                                                                
                                                                <div className="detailItemContents">
                                                                    <span className="itemKeyContents">Questions:</span>
                                                                    <span className="itemValueContents">{ maintenance_checklist_step_list_data![stepCountFinish]!.question}</span>
                                                                </div>
                                                                <div className="detailItemContents">
                                                                    <span className="itemKeyContents">Instructions:</span>
                                                                    <span className="itemValueContents" style={{"color":"green", }}>{maintenance_checklist_step_list_data![stepCountFinish]!.instruction}</span>
                                                                </div>
                                                                <div className="detailItemContents">
                                                                    <span className="itemKeyContents">Images/Videos:</span>
                                                                    {/* <span className="itemValueContents" style={{"color":"green", }}>
                                                                        {maintenance_checklist_step_list_data![stepCountFinish]!.maintenance_checklist_files}
                                                                    </span> */}
                                                                    <div 
                                                                    style={{
                                                                        "display": "flex",
                                                                        // "justifyContent": "space-between", 
                                                                        // "width": "100%",
                                                                        // "height": "20%", 
                                                                    }}
                                                                    >
                                                                    {maintenance_checklist_step_list_data![stepCountFinish]!.maintenance_checklist_files.map((file, index) => (
                                                                        <div key={index}>
                                                                        <h2 
                                                                            style={{
                                                                            "marginRight":"10px",  
                                                                            "marginLeft":"10px",
                                                                            "fontSize": "14px",
                                                                            "marginTop":"20px", 
                                                                            "marginBottom":"10px", 
                                                                            "textAlign": "center"
                                                                            }}
                                                                        >
                                                                            {file.resource_type}
                                                                        </h2>
                                                                        <img 
                                                                            className="itemContentsImg"
                                                                            alt={file.resource_type} 
                                                                            src={file.url} 
                                                                            style={{
                                                                            "marginTop":"0px", 
                                                                            "marginBottom":"0px", 
                                                                            "marginRight":"10px",  
                                                                            "marginLeft":"10px",
                                                                            }}>
                                                                        </img>
                                                                        <br />
                                                                        </div>
                                                                    ))}
                                                                    </div>
                                                                </div>

                                                            </div> 

                                                        </div>

                                                        : ''
                                                        
                                                        }
                                                        

                                                        <div 
                                                        style={{
                                                            "display": "flex", 
                                                            "justifyContent": "space-between", 
                                                            "marginRight":"10px",  
                                                            "marginLeft":"10px",
                                                            "marginTop":"20px",  
                                                            // "marginBottom":"20px"  
                                                        }}
                                                        >

                                                        <FormControl style={{"width": "90%"}}>
                                                            <TextField
                                                            id="outlined-multiline-static"
                                                            label="Answer"
                                                            name='answer'
                                                            multiline
                                                            maxRows={4}
                                                            value={answers}
                                                            onChange={handleAnswerChange}
                                                            defaultValue="Default Value"
                                                            />
                                                            {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                        </FormControl>

                                                        <Button 
                                                            style={{
                                                            "width": "20%",
                                                            "height": "55px", 
                                                            "marginTop":"0px", 
                                                            "marginBottom":"0px", 
                                                            "marginRight":"0px",  
                                                            "marginLeft":"10px",
                                                            "fontSize":"10px",
                                                            }}
                                                            size="large"
                                                            variant="outlined"
                                                            name='video'
                                                            // onChange={event => handleFormChange(index, event)}
                                                            onClick={() => openFileSelector()}
                                                        >
                                                            Select Image\Video
                                                        </Button>

                                                        <Button 
                                                            style={{
                                                            "width": "20%",
                                                            "height": "55px", 
                                                            "marginTop":"0px", 
                                                            "marginBottom":"0px", 
                                                            "marginRight":"0px",  
                                                            "marginLeft":"10px",
                                                            "fontSize":"10px",
                                                            }}
                                                            size="large"
                                                            variant="contained"
                                                            name='video'
                                                            // onChange={event => handleFormChange(index, event)}
                                                            disabled={isUploading}
                                                            onClick={uploadFiles}
                                                        >
                                                            {isUploading ? "Uploading..." : "Upload"}
                                                        </Button>

                                                        {/* <Button 
                                                            style={{
                                                            "width": "20%",
                                                            "height": "55px", 
                                                            "marginTop":"0px", 
                                                            "marginBottom":"0px", 
                                                            "marginRight":"0px",  
                                                            "marginLeft":"10px",
                                                            "fontSize":"10px",
                                                            }}
                                                            size="large"
                                                            variant="contained"
                                                            name='video'
                                                            onClick={uploadFiles}
                                                        >

                                                            Upload
                                                        </Button> */}
                                
                                                        </div>

                                                        <div 
                                                        style={{
                                                            "display": "flex", 
                                                            "justifyContent": "space-between", 
                                                            "marginRight":"10px",  
                                                            "marginLeft":"10px",
                                                            "marginTop":"20px",  
                                                            "marginBottom":"60px"  
                                                        }}
                                                        >
                                                        <ReactQuill style={{"width": "100%", }} 
                                                            theme="snow" 
                                                            className='instruction'
                                                            value={value}
                                                            onChange={setQuillValue}
                                                            modules={modules}
                                                            formats={formats}
                                                            bounds={'.app'}
                                                        />
                                                        </div>

                                                        <div 
                                                        style={{
                                                            "display": "flex", 
                                                            "justifyContent": "space-between", 
                                                            "marginRight":"10px",  
                                                            "marginLeft":"10px",
                                                            "marginTop":"20px",  
                                                            // "marginBottom":"20px"  
                                                        }}
                                                        >
                                                        
                                                        {/* <Hidden only={['xs','sm', 'md','lg','xl']}>
                                                            <FormControl style={{"width": "100%"}}>
                                                            <TextField
                                                                id="outlined-multiline-static"
                                                                label="Instruction"
                                                                name='instruction'
                                                                multiline
                                                                maxRows={4}
                                                                value={input.instruction}
                                                                // onChange={handleQuestionsChange}
                                                                onChange={event => handleFormChange(index, event)}
                                                                defaultValue="Default Value"
                                                                hidden = {true}
                                                            />
                                                            {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                            </FormControl>
                                                        </Hidden> */}

                                                        <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "90%", "marginTop":"0px", "marginLeft":"0px",}} >
                                                            <InputLabel id="demo-simple-select-helper-label">Checklist Status</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-helper-label"
                                                                id="demo-simple-select-helper-label"
                                                                value={status}
                                                                label="Select Form Type Category"
                                                                name='yesOrNo'
                                                                onChange={handleStatusChange}
                                                                renderValue={(value) =>  value }
                                                                >
                                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                                    <MenuItem value={"complete"}>Completed</MenuItem>
                                                                    <MenuItem value={"unable to complete"}>Unable to Complete</MenuItem>
                                                                    <MenuItem value={"repairs needed"}>Repairs Needed</MenuItem>
                                                            </Select>
                                                            {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                        </FormControl>

                                                        {/* <FormControlLabel style={{"marginLeft": "2%", "fontSize": "10px"}} 
                                                        control={
                                                            <Checkbox
                                                            name='yesOrNo'
                                                            checked={input.yesOrNo}
                                                            // onChange={handleIsYesOrNoChange}
                                                            onChange={event => handleFormChange(index, event)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            />} label="Is Yes or No Question" 
                                                        /> */}
                                                        

                                                        {
                                                        stepCountFinish !== stepCount - 1 ? 
                                                        <Button 
                                                            style={{
                                                            // "width": "15%",
                                                            "height": "55px", 
                                                            "marginTop":"0px", 
                                                            "marginBottom":"0px", 
                                                            "marginRight":"0px",  
                                                            "marginLeft":"10px",
                                                            "fontSize":"12px",
                                                            }}
                                                            size="large"
                                                            variant="contained"
                                                            onClick={handleAddQuestion}
                                                        >
                                                            Respond To Maintenance Checklist Step {stepCountFinish + 1}
                                                        </Button>
                                                        : 
                                                        <Button 
                                                            style={{
                                                            "width": "15%",
                                                            "height": "55px", 
                                                            "marginTop":"0px", 
                                                            "marginBottom":"0px", 
                                                            "marginRight":"0px",  
                                                            "marginLeft":"10px",
                                                            "fontSize":"12px",
                                                            }}
                                                            size="large"
                                                            variant="contained"
                                                            onClick={handleNext}
                                                        >
                                                            {activeStep === steps.length - 1 ? 'Finish' : 'Complete Step'}
                                                        </Button>
                                                        
                                                        }

                                                        <Button 
                                                            style={{
                                                            "width": "15%",
                                                            "height": "55px", 
                                                            "marginTop":"0px", 
                                                            "marginBottom":"0px", 
                                                            "marginRight":"0px",  
                                                            "marginLeft":"10px",
                                                            "fontSize":"12px",
                                                            }}
                                                            size="large"
                                                            variant="outlined"
                                                            onClick={clearQuestions}
                                                        >
                                                            Clear Answer
                                                        </Button>

                                                        </div>

                                                        <div 
                                                        style={{
                                                            "display": "flex",
                                                            // "justifyContent": "space-between", 
                                                            // "width": "100%",
                                                            // "height": "20%", 
                                                        }}
                                                        >
                                                        {filesContent.map((file, index) => (
                                                            <div key={index}>
                                                            <h2 
                                                                style={{
                                                                "marginRight":"10px",  
                                                                "marginLeft":"10px",
                                                                "fontSize": "14px",
                                                                "marginTop":"20px", 
                                                                "marginBottom":"10px", 
                                                                }}
                                                            >
                                                                {file.name}
                                                            </h2>
                                                            <img 
                                                                className="itemImg"
                                                                alt={file.name} 
                                                                src={file.content} 
                                                                style={{
                                                                "marginTop":"0px", 
                                                                "marginBottom":"0px", 
                                                                "marginRight":"10px",  
                                                                "marginLeft":"10px",
                                                                }}>
                                                            </img>
                                                            <br />
                                                            </div>
                                                        ))}
                                                        </div>

                                                        <hr style={{
                                                            height: "2px",
                                                            backgroundColor: '#dddddd',
                                                            marginTop: '25px',
                                                            marginBottom: '25px',
                                                            marginLeft: '10px',
                                                            marginRight: '10px',
                                                            border: "none",
                                                        }}/>

                                                </div>


                                        </div>
                        
                        
                                        </Box>

                                        : <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1 },
                                        }}
                                        noValidate
                                        autoComplete="off">
                    
                                            <div style={{"width":"100%", "marginTop": "30px"}}>
                        
                                            <div className="itemContents" style={{
                                                "marginLeft":"20px", 
                                                "border": "0.5px solid lightgray",
                                                "padding":"20px", "borderRadius":"10px",
                                                "marginTop":"10px",
                                                "marginBottom":"10px",
                                            }}>
                                            <div className="detailsContents" style={{"width":"100%", }}>
                                                
                                                {/* <div className="detailItemContents">
                                                <span className="itemKeyContents">Maintenance Checklist Name:</span>
                                                <span className="itemValueContents">{checklist_name}</span>
                                                </div>
                                                <div className="detailItemContents">
                                                <span className="itemKeyContents">Maintenance Checklist Category:</span>
                                                <span className="itemValueContents" style={{"color":"green", }}>{checklist_category}</span>
                                                </div> */}
                                                {/* <div className="detailItem">
                                                <span className="itemKey">Community Name:</span>
                                                <span className="itemValue" style={{"color":"green", }}>Ajah Community</span>
                                                </div> */}
                                                {/* <div className="detailItemContents">
                                                <span className="itemKeyContents">System Type:</span>
                                                <span className="itemValueContents" style={{"color":"green", }}>{display_systemType_name}</span>
                                                </div>
                                                <div className="detailItemContents">
                                                <span className="itemKeyContents">Maintenance Checklist Frequency:</span>
                                                <span className="itemValueContents" style={{"color":"green", }}>{checklist_frequency}</span>
                                                </div> */}
                                                <div className="detailItemContents">
                                                <span className="itemKeyContents">Number of Step:</span>
                                                <span className="itemValueContents">
                                                    {numberOfSteps}
                                                </span>
                                                </div>
                                                {/* <div className="detailItem">
                                                <span className="itemKey">Completed On:</span>
                                                <span className="itemValue">
                                                    30-09-2022
                                                </span>
                                                </div> */}
                                                {/* <div className="detailItemContents">
                                                <span className="itemKeyContents">Maintenance Checklist Description:</span>
                                                <span className="itemValueContents">{checklist_description}</span>
                                                </div> */}
                                                <hr style={{
                                                height: "0.5px",
                                                backgroundColor: '#dddddd',
                                                marginTop: '20px',
                                                marginBottom: '20px',
                                                border: "none",
                                                }}/>
                                                <div className="detailItemContents">
                                                {storedData!.map((data, index) =>{
                                                    return <div key={index}>
                                                    <span className="itemKeyContents">Question {index + 1}: {data.question}</span>
                                                    <span className="itemValueContents">
                                                        <br/> 
                                                        <p style={{
                                                        paddingTop: "5px",
                                                        paddingRight: "5px",
                                                        paddingBottom: "5px",
                                                        backgroundColor: '#dddddd',
                                                        // color: '#2962ff'
                                                        }}>Instruction:</p> <div dangerouslySetInnerHTML={{__html: data.instruction}}></div>
                                                    </span>
                                                    <div style={{
                                                        height: "20px"
                                                        }}>
                                                    </div>
                                                    <span className="itemKeyContents">Answer {index + 1}: {data.answer}</span>
                                                    <span className="itemValueContents">
                                                        <br/> 
                                                        <p style={{
                                                        paddingTop: "5px",
                                                        paddingRight: "5px",
                                                        paddingBottom: "5px",
                                                        backgroundColor: '#dddddd',
                                                        // color: '#2962ff'
                                                        }}>Additional Comments:</p> <div dangerouslySetInnerHTML={{__html: data.instruction}}></div>
                                                    </span>
                                                    <span className="itemValueContents">
                                                    <br/> 
                                                        <p style={{
                                                        paddingTop: "5px",
                                                        paddingRight: "5px",
                                                        paddingBottom: "5px",
                                                        backgroundColor: '#dddddd',
                                                        // color: '#2962ff'
                                                        }}>Checklist Status:</p>
                                                        {data.status}
                                                    </span>
                                                    <div 
                                                        style={{
                                                        display:"flex"
                                                        }}
                                                    >
                                                        <span className="itemValueContents">
                                                        <br/> 
                                                            <p style={{
                                                            paddingTop: "5px",
                                                            paddingRight: "5px",
                                                            paddingBottom: "5px",
                                                            backgroundColor: '#dddddd',
                                                            // color: '#2962ff'
                                                            }}>Images\Videos:</p>
                                                        </span>

                                                        {data.photo.map((item, index) =>{
                                                        return <div key={index}>
                                                            
                                                            <img
                                                            src={item.secure_url}
                                                            alt=""
                                                            className="itemContentsImg"
                                                            />
                                                            {/* <p>{item.resource_type}</p> */}
                                                        </div>
                                                        })}
                                                    </div>
                                                    
                                                    <hr style={{
                                                        height: "0.5px",
                                                        backgroundColor: '#dddddd',
                                                        marginTop: '20px',
                                                        marginBottom: '20px',
                                                        border: "none",
                                                    }}/>
                                                    </div>
                                                })}
                                                
                                                </div>
                                                {/* <div className="detailItem">
                                                <span className="itemKey">Question Two:</span>
                                                <span className="itemValue">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                                </span>
                                                </div> */}
                                                
                                            </div>
                                            </div>
                    
                                        </div>
                    
                    
                                    </Box> 
                                    } 
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    {/* {isStepOptional(activeStep) && (
                                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                        Skip
                                        </Button>
                                    )} */}
                                    { activeStep === steps.length - 1 ? 
                                            
                                            <Button 
                                            style={{
                                                "width": "30%",
                                                "height": "50px", 
                                                
                                                "fontSize" : "12px"
                                            }}
                                            size="large"
                                            variant="contained"
                                            onClick={handleSubmit}
                                            >
                                            Submit Maintenance Checklist Response
                                            </Button>
                                        : 
                                        <Button onClick={handleNext}>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    }
                                    </Box>
                                </React.Fragment>
                                )}
                            </div>
                            
                            </div>
                        </div>

                        </div>
                    </div>
                </form>
          </Grid>

        </Grid>
       
      </Main>
    </Box>
  );

};

export default Maintenance_Checklist_Response;