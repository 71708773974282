import React, { useEffect, useState } from "react";
import DashboardCard from "../../components/card/card";
import SectionHeader from "../../components/custom_text/section_header";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import Sidebar from "../../components/sidebars/sidebar";
import './organization_dashboard.scss'
import SideBarOrganization from "./sidebar_organization/sidebarorganization";
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';


import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Button, FormControlLabel, FormHelperText, MenuItem, Modal, Select, SelectChangeEvent, Step, StepLabel, Stepper, Switch, TextField } from "@mui/material";


import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import logoImage from './images/logo.png';
import axios from 'axios';
import { states } from "../notifications/makeData";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const steps = ['Create Organization Details', 'Create Organization Address', 'Confirm Organization Details'];

export interface User {
  user_id: number;
  organization_id: number;
  user_token: string;
  firebase_token: string;
  profile_image?: any;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  role: string;
  phone_number: string;
  management_role: string;
  reset_password_expiry_time: string;
  reset_password_token?: any;
  isPhoneVerified: boolean;
  isEmailVerifed: boolean;
  active: boolean;
  accepted_terms: boolean;
  suspended: boolean;
  updated_by: number;
  created_by: number;
  created_at: Date;
  updated_at: Date;
}

export interface Address {
  address_id: number;
  user_id: number;
  organization_id: number;
  community_id: number;
  address: string;
  state: string;
  city: string;
  latitude: string;
  longitude: string;
  postal_code: string;
  country: string;
  is_default: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface Organization {
  organization_id: number;
  name: string;
  email: string;
  phone_number: string;
  organization_logo: string;
  capacity: string;
  active: boolean;
  suspended: boolean;
  updated_by: number;
  created_by: number;
  created_at: Date;
  updated_at: Date;
  users: User[];
  address: Address[];
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Edit_Oganization : React.FC = () => {
  
  const config = {
    headers:{
      'content-type': 'application/json',
      // header2: value2
    }
  };

  const [fullName, setFullName] = useState<string>();
  const [userId, setUserId] = useState([]);
  const [bearer, setBearerToken] = useState<string>();

  //Api call state management
  const [userData, setUserData] = useState<User | null>();
  const [organizationRetrievedData, setOrganizationRetrivedData] = useState<Organization | null>();
  const [organizationAddressRetrivedData, setOrganizationAddressRetrivedData] = useState<Address | null>();

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [error, setUserError] = React.useState(false);

  const [organizationName, setOrganizationName] = React.useState('');
  const [organizationCapacity, setOrganizationCapacity] = React.useState('');
  const [organizationPhoneNumber, setOrganizationPhoneNumber] = React.useState('');
  const [organizationEmail, setOrganizationEmail] = React.useState('');

  const [street_address, setUserAddress] = React.useState('');
  const [city, setUserCity] = React.useState('');
  const [postal_code, setUserPostalCode] = React.useState('');
  const [userState, setUserState] = React.useState('');

  const [country, setUserCountry] = React.useState('');
  const [longitude, setUserLongitude] = React.useState('');
  const [latitude, setUserLatitude] = React.useState('');

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('userId') || '{}');
    const user_fullName = JSON.parse(localStorage.getItem('user_fullName') || '{}');
    const bearerToken = JSON.parse(localStorage.getItem('bearerToken') || '{}');
    if (userId) {
      setUserId(userId);
      setFullName(user_fullName);
      setBearerToken(bearerToken);
      getUserData(userId, bearerToken);
      getOrganizationData(1, bearerToken);
    }
  }, []);

    const getUserData = async (userID : number, bearer: string) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
      const userResponse = await axios.get(`http://api.wellbeyondwater.com:8000/api/user/${userID}`, config);
      console.log('API call response status for user data ' + userResponse.status);
      setUserData(userResponse.data.data["user"]);
      console.log('User First Name ' + userResponse['data']['data']['first_name']);
      console.log('User API status ' + userResponse['data']['status']);
      console.log('User API status ' + userData?.first_name);
      // console.log('Bearer Token ' + userData?.first_name);

    };

    const getOrganizationData = async (organizationID : number, bearer: string) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
      const organizationResponse = await axios.get(`http://api.wellbeyondwater.com:8000/api/organization/${organizationID}`, config);
      console.log('API call response status ' + organizationResponse.status);
      setOrganizationRetrivedData(organizationResponse.data.data.organization);
      // console.log('Organization retrieved data in set org data ' , organizationResponse.data.data.organization)

      console.log('Organization Name ' + organizationResponse['data']['data']['organization']['name']);
      console.log('Organization API status ' + organizationResponse['data']['status']);

      setOrganizationName(organizationResponse['data']['data']['organization']['name'])
      setOrganizationEmail(organizationResponse['data']['data']['organization']['email'])
      setOrganizationPhoneNumber(organizationResponse['data']['data']['organization']['phone_number'])
      setOrganizationCapacity(organizationResponse['data']['data']['organization']['capacity']);

      // getOrganizationAddressData(organizationResponse['data']['data']['community_id']);
      getOrganizationAddressData(organizationResponse['data']['data']['organization']['organization_id'], bearer);
  
    };

    const getOrganizationAddressData = async (organizationAddressID : number, bearer: string) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
      const organizationResponse = await axios.get(`http://api.wellbeyondwater.com:8000/api/address/organization/${organizationAddressID}`, config);
      console.log('API call response status ' + organizationResponse.status);
      setOrganizationAddressRetrivedData(organizationResponse.data.data[0]);
      console.log('Organization Address Name ' + organizationResponse['data']['data'][0]['address']);
      console.log('Organization Address API status ' + organizationResponse['data']['status']);
  
      setUserAddress(organizationResponse.data.data[0]['address']);
      setUserCity(organizationResponse.data.data[0]['city']);
      setUserCountry(organizationResponse.data.data[0]['country']);
      setUserLatitude(organizationResponse.data.data[0]['latitude']);
      setUserPostalCode(organizationResponse.data.data[0]['postal_code']);
      setUserLongitude(organizationResponse.data.data[0]['longitude']);
    };

  
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

    const [openModal, setOpenModal] = React.useState(false);
    const [openErrorModal, setopenErrorModal] = React.useState(false);
    const handleOpen = () => {
      setOpenModal(true);
    };
    const handleClose = () => {
      setOpenModal(false);
    };

    const handleErrorClose  = () => {
      setopenErrorModal(false);
    };

    // let handleChange = (event : any) => {}
    // let handleSubmit = (event : any) => {};
  
    const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setOrganizationName(event.target.value);
    };

    const handleOrganizationCapacity = (event: React.ChangeEvent<HTMLInputElement>) => {
      setOrganizationCapacity(event.target.value);
    };

    const handleOrganizationPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
      setOrganizationPhoneNumber(event.target.value);
    };

    const handleOrganizationEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
      setOrganizationEmail(event.target.value);
    };

    const handleUserAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserAddress(event.target.value);
   };

   const handleUserCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserCity(event.target.value);
   };

   const handleUserStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserState(event.target.value);
  };

   const handleUserPostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserPostalCode(event.target.value);
   };

   const handleUserCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserCountry(event.target.value);
   };

   const handleUserLongitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserLongitude(event.target.value);
   };

   const handleUserLatitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserLatitude(event.target.value);
   };

  
    const isStepOptional = (step: number) => {
      return step === 1;
    };
  
    const isStepSkipped = (step: number) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

    let updateOrganizationData = {

      name : organizationName,
      email: organizationEmail,
      phone_number : organizationPhoneNumber,
      organization_logo : "organization_logo",
      capacity : 20,
      active : true,
      suspended : false,
      updated_by: userData?.user_id,
  
    };

    const [errorMessage, setErrorMessage] = React.useState();
    const [organizationData, setOrganizationData] = React.useState<Organization | null>();

    let handleSubmit = (event : any) => {
      event.persist();
      console.log("Organization data ", updateOrganizationData);
      console.log("Update organization and address event fired");
      PostData();
   };

   const PostData = async () => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
    console.log("Organization data ", updateOrganizationData);   
      const updateOrganizationResponse = await axios.put(`http://api.wellbeyondwater.com:8000/api/organization/update_organization/${organizationRetrievedData?.organization_id}`, updateOrganizationData)
      .then((response) => {
       console.log('got here')
        setOrganizationData(response.data.data);
        const status = response['data']['status'];
        const message = response['data']['message'];
        const organizationId = response['data']['data']["organization_id"];
        console.log("organization id " + organizationId);

        console.log('Create organization Status ' + status);
        console.log('Message ' + message);

        let updateOrganizationAddressData = {
          user_id: null,
          organization_id: organizationId,
          is_default: true,
          address : street_address,
          // state : userState,
          city : city,
          latitude : "latitude",
          longitude : "longitude",
          postal_code : postal_code,
          country : country
        };

        if(status === "Success"){
          console.log("organization address data " + updateOrganizationAddressData);
          axios.put(`http://api.wellbeyondwater.com:8000/api/address//update_organization_address/${organizationRetrievedData?.organization_id}`, updateOrganizationAddressData).then((response) => {
            const status = response['data']['status'];
            const message = response['data']['message'];
            const addressData = response['data']['data'];
            console.log("create address response ", addressData)
            console.log('Create address Status ', status);
            console.log('Message ', message);
            setOpenModal(true);
          });
          
        }

      }).catch(function (error) {

        setErrorMessage(error.toString());
        console.log('error ' , error.toJSON());
        console.log('error message ' , error.toString());
        setopenErrorModal(true);

      });

   };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div className="home">

    <div className="homeContainer">

      <SectionHeader sectionTitle={'EDIT ORGANIZATION'} link='' />
      <div className="cardPadding">
    
        <NavCard_Community cardContent={20} cardTitle={'Create Organization'} image={createImage} link={"/home/organization/create"} />
        <NavCard_Community cardContent={20} cardTitle={'Organization List'} image={listImage} link={"/home/organization/list/:organizationId"} />
        <NavCard_Community cardContent={20} cardTitle={'Edit Organization'} image={editImage} link={"/home/organization/edit/:organizationId"} />
        <NavCard_Community cardContent={20} cardTitle={'Organization Details'} image={detailImage} link={"/home/organization/detail/:organizationId"} />

      </div>
      {/* <div className="cardPadding">
        <DashboardCard cardContent={20} cardTitle={'Organizations'} image={organizationImage} link={""}/>
        <DashboardCard cardContent={40} cardTitle={'Communities'} image={communityImage} link={""}/>
        <DashboardCard cardContent={230} cardTitle={'Management Users'} image={managementUsersImage} link={""}/>
        <DashboardCard cardContent={130} cardTitle={'People Trained'} image={trainedPeopleImage} link={""}/>
      </div> */}
    
      <div className="top">
        <div className="left">
          <div className="item">
            <img
              src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"
              alt=""
              className="itemImg"
            />
            <div style={{
              "width": "50%",
              "height": "50px", 
              "marginTop":"20px", 
              "marginBottom":"20px", 
              "marginRight":"10px",  
              "marginLeft":"10px",
              }}>
                          
              {/* <Button 
                style={{
                  "width": "50%",
                  "height": "50px", 
                  "marginTop":"40px", 
                  "marginBottom":"20px", 
                  "marginRight":"10px",  
                  "marginLeft":"10px",
                }}
                  size="large"
                  variant="outlined"
                  onClick={() => {
                    alert('clicked');
                  }}
                >
                Select Organization Logo
              </Button> */}
            </div>

          </div>

          {/* <hr style={{
              height: "2px",
              backgroundColor: '#dddddd',
              marginTop: '10px',
              marginBottom: '10px',
              border: "none",
          }}/> */}

            <div className="formBox" style={{
              "marginLeft":"10px", 
              "border": "0.5px solid lightgray",
              "padding":"20px", 
              "borderRadius":"5px",
              "marginTop":"10px",
              "marginBottom":"10px",
            }}>
            <div className="details" style={{"width":"100%", }}>
              <h1 className="itemTitle">Edit Organization</h1>
              <hr style={{
                height: "0.5px",
                backgroundColor: '#dddddd',
                marginTop: '10px',
                marginBottom: '10px',
                border: "none",
              }}/>

              <div style={{"width":"100%", "marginTop": "30px"}}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    // if (isStepOptional(index)) {
                    //   labelProps.optional = (
                    //     <Typography variant="caption">Optional</Typography>
                    //   );
                    // }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                    {
                      activeStep === 0 ? 
                        <Box
                          component="form"
                          sx={{
                            '& > :not(style)': { m: 1 },
                          }}
                          noValidate
                          autoComplete="off">
        
                          <div style={{"width":"100%", "marginTop": "30px"}}>
        
                              <div 
                                style={{
                                  "display": "flex", 
                                  "justifyContent": "space-between", 
                                  "marginRight":"10px",  
                                  "marginLeft":"10px",
                                  "marginTop":"20px",  
                                  // "marginBottom":"20px"  
                                }}
                              >
        
                                <FormControl style={{"width": "45%"}}>
                                  <InputLabel htmlFor="component-outlined">Organization Name</InputLabel>
                                  <OutlinedInput
                                    id="component-outlined"
                                    value={organizationName}
                                    onChange={handleOrganizationChange}
                                    label="Organization Name"
                                    
                                  />
                                  {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                </FormControl>
        
                                <FormControl style={{"width": "45%"}}>
                                  <InputLabel htmlFor="component-outlined">Organization Email</InputLabel>
                                  <OutlinedInput
                                    id="component-outlined"
                                    value={organizationEmail}
                                    onChange={handleOrganizationEmail}
                                    label="Organization Email"
                                    
                                  />
                                  {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                </FormControl>
        
                              </div>

                              <div 
                                style={{
                                  "display": "flex", 
                                  "justifyContent": "space-between", 
                                  "marginRight":"10px",  
                                  "marginLeft":"10px",
                                  "marginTop":"20px",  
                                  // "marginBottom":"20px"  
                                }}
                                >
        
                                <FormControl style={{"width": "45%"}}>
                                  <InputLabel htmlFor="component-outlined">Organization Phone Number</InputLabel>
                                  <OutlinedInput
                                    id="component-outlined"
                                    value={organizationPhoneNumber}
                                    onChange={handleOrganizationPhoneNumber}
                                    label="Organization Phone Number"
                                    
                                  />
                                  {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                </FormControl>
        
                                <FormControl style={{"width": "45%"}}>
                                  <InputLabel htmlFor="component-outlined">Organization Capacity</InputLabel>
                                  <OutlinedInput
                                    id="component-outlined"
                                    value={organizationCapacity}
                                    onChange={handleOrganizationCapacity}
                                    label="Organization Capacity"
                                    
                                  />
                                  {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                </FormControl>
        
                              </div>
        
        
                          </div>
        
        
                        </Box>
                        : 
                        activeStep === 1 ? 
                        <Box
                          component="form"
                          sx={{
                            '& > :not(style)': { m: 1 },
                          }}
                          noValidate
                          autoComplete="off">
        
                          <div style={{"width":"100%", "marginTop": "30px"}}>
        
                              <div 
                                style={{
                                  "display": "flex", 
                                  "justifyContent": "space-between", 
                                  "marginRight":"10px",  
                                  "marginLeft":"10px",
                                  "marginTop":"20px",  
                                  // "marginBottom":"20px"  
                                }}
                              >
        
                                <FormControl style={{"width": "45%"}}>
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Street Address"
                                    multiline
                                    maxRows={4}
                                    value={street_address}
                                    onChange={handleUserAddressChange}
                                    defaultValue="Default Value"
                                  />
                                  {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                </FormControl>
        
                                <FormControl style={{"width": "45%"}}>
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="City"
                                    multiline
                                    maxRows={4}
                                    value={city}
                                    onChange={handleUserCityChange}
                                    defaultValue="Default Value"
                                  />
                                  {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                </FormControl>
        
                              </div>

                              <div 
                                style={{
                                  "display": "flex", 
                                  "justifyContent": "space-between", 
                                  "marginRight":"10px",  
                                  "marginLeft":"10px",
                                  "marginTop":"20px",  
                                  // "marginBottom":"20px"  
                                }}
                              >
        
                                <FormControl style={{"width": "45%"}}>
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Postal Code"
                                    multiline
                                    maxRows={4}
                                    value={postal_code}
                                    onChange={handleUserPostalCodeChange}
                                    defaultValue="Default Value"
                                  />
                                  {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                </FormControl>
        
                                <FormControl style={{"width": "45%"}}>
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Country"
                                    multiline
                                    maxRows={4}
                                    value={country}
                                    onChange={handleUserCountryChange}
                                    defaultValue="Default Value"
                                  />
                                  {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                </FormControl>
        
                              </div>

                              {/* <div 
                                style={{
                                  "display": "flex", 
                                  "justifyContent": "space-between", 
                                  "marginRight":"10px",  
                                  "marginLeft":"10px",
                                  "marginTop":"20px",  
                                  // "marginBottom":"20px"  
                                }}
                              >
        
                                <FormControl style={{"width": "45%"}}>
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Longitude"
                                    multiline
                                    maxRows={4}
                                    value={longitude}
                                    onChange={handleUserLongitudeChange}
                                    defaultValue="Default Value"
                                  />
                                  {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                </FormControl>
        
                                <FormControl style={{"width": "45%"}}>
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Latitude"
                                    multiline
                                    maxRows={4}
                                    value={latitude}
                                    onChange={handleUserLatitudeChange}
                                    defaultValue="Default Value"
                                  />
                                  {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                </FormControl>
        
                              </div> */}
        
                          </div>
        
        
                        </Box>

                        : <Box
                          component="form"
                          sx={{
                            '& > :not(style)': { m: 1 },
                          }}
                          noValidate
                          autoComplete="off">
      
                            <div style={{"width":"100%", "marginTop": "30px"}}>
          
                              <div className="item" style={{
                                "marginLeft":"20px", 
                                "border": "0.5px solid lightgray",
                                "padding":"20px", "borderRadius":"10px",
                                "marginTop":"10px",
                                "marginBottom":"10px",
                              }}>
                              <div className="details" style={{"width":"100%", }}>
                                
                                <div className="detailItem">
                                  <span className="itemKey">Organization Name:</span>
                                  <span className="itemValue">{organizationName}</span>
                                </div>
                                <div className="detailItem">
                                  <span className="itemKey">Organization Email:</span>
                                  <span className="itemValue" style={{"color":"green", }}>{organizationEmail}</span>
                                </div>
                                <div className="detailItem">
                                  <span className="itemKey">Organization Phone Number:</span>
                                  <span className="itemValue" style={{"color":"green", }}>{organizationPhoneNumber}</span>
                                </div>
                                <div className="detailItem">
                                  <span className="itemKey">Capacity:</span>
                                  <span className="itemValue" style={{"color":"green", }}>{organizationCapacity}</span>
                                </div>
                                
                                <hr style={{
                                  height: "0.5px",
                                  backgroundColor: '#dddddd',
                                  marginTop: '20px',
                                  marginBottom: '20px',
                                  border: "none",
                                }}/>
                                <div className="detailItem">
                                  <span className="itemKey">Street Address:</span>
                                  <span className="itemValue">
                                    {street_address}
                                  </span>
                                </div>
                                <div className="detailItem">
                                  <span className="itemKey">City:</span>
                                  <span className="itemValue">
                                    {city}
                                  </span>
                                </div>
                                <div className="detailItem">
                                  <span className="itemKey">Postal Code:</span>
                                  <span className="itemValue">
                                    {postal_code}
                                  </span>
                                </div>
                                <div className="detailItem">
                                  <span className="itemKey">Country:</span>
                                  <span className="itemValue">
                                    {country}
                                  </span>
                                </div>
                                <div className="detailItem">
                                  <span className="itemKey">Longitude:</span>
                                  <span className="itemValue">
                                    {longitude}
                                  </span>
                                </div>
                                <div className="detailItem">
                                  <span className="itemKey">Latitude:</span>
                                  <span className="itemValue">
                                    {latitude}
                                  </span>
                                </div>
                                
                              </div>
                            </div>
      
                            {/* <Button 
                              style={{
                                "width": "50%",
                                "height": "50px", 
                                "marginTop":"40px", 
                                "marginBottom":"20px", 
                                "marginRight":"10px",  
                                "marginLeft":"20px",
                              }}
                              size="large"
                              variant="contained"
                              onClick={handleSubmit}
                            >
                              Create Organization
                            </Button> */}
      
                        </div>
      
      
                      </Box> 
                      } 
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {isStepOptional(activeStep) && (
                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                          Skip
                        </Button>
                      )}
                      { activeStep === steps.length - 1 ? 

                            <Button 
                              style={{
                                "width": "20%",
                                "height": "50px", 
                                           
                                "fontSize" : "12px"
                              }}
                            size="large"
                            variant="contained"
                            onClick={handleSubmit}
                            >
                              Update Organization
                            </Button>
                            : 
                          <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                          </Button>
                      }
                    </Box>
                  </React.Fragment>
                )}
              </div>
              
            </div>
          </div>

        </div>
      </div>
      

    </div>
  </div>

  <Modal
          hideBackdrop
          open={openModal}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 450 }}>
          <Button onClick={handleClose}>x</Button>
              <h3 id="child-modal-title" style={{textAlign: "center"}}>Organization Updated Successfully</h3>
              {/* <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
                Welcome
              </p> */}
              <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{organizationName}</h2>
              <Link to="/home/organization/" style={{ textDecoration: "none", marginTop: "10px", }}>
                <span className="hasAccount"><Button 
                    style={{
                      "width": "100%",
                      "height": "50px", 
                      "marginTop":"20px", 
                      "marginBottom":"20px", 
                      "marginRight":"10px",  
                      "marginLeft":"20px",
                    }}
                    size="large"
                    variant="contained"
                    // onClick={handleSubmit}
                >
                    Continue
                </Button></span>
              </Link>
          </Box>
        </Modal>

        <Modal
          hideBackdrop
          open={openErrorModal}
          onClose={handleErrorClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 550 }}>
          <Button onClick={handleErrorClose}>x</Button>
              <h3 id="child-modal-title" style={{textAlign: "center"}}>Organization Update Failed</h3>
              <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
                {errorMessage}
              </p>
              <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{organizationName}</h2>
              <span className="hasAccount"><Button 
                    style={{
                      "width": "100%",
                      "height": "50px", 
                      "marginTop":"20px", 
                      "marginBottom":"20px", 
                      "marginRight":"10px",  
                      "marginLeft":"20px",
                    }}
                    size="large"
                    variant="contained"
                    onClick={handleErrorClose}
                >
                    Continue
                </Button></span>
          </Box>
        </Modal>
       
      </Main>
    </Box>
  );


};

export default Edit_Oganization;