import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

import { Link } from "react-router-dom";
// import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import Custom_Text from "../../components/custom_text";
import './sidebar.scss'
import detailImage from './images/logo.png';


const SideBar : React.FC = () => {

    const [openDiagnostic, setOpenDiagnostic] = React.useState(false);
    const handleClickDiagnostic = () => {
        setOpenDiagnostic(!openDiagnostic);
    };

    const [openImpact, setOpenImpact] = React.useState(false);
    const handleClickImpact = () => {
        setOpenImpact(!openImpact);
    };

    const [openMaintenance, setOpenMaintenance] = React.useState(false);
    const handleClickMaintenance = () => {
        setOpenMaintenance(!openMaintenance);
    };

    const [openForms, setOpenForms] = React.useState(false);
    const handleClickForms = () => {
        setOpenForms(!openForms);
    };

    const [openTraining, setOpenTraining] = React.useState(false);
    const handleClickTraining = () => {
        setOpenTraining(!openTraining);
    };

    const [openManagementUsers, setOpenManagementUsers] = React.useState(false);
    const handleClickManagementUsers = () => {
        setOpenManagementUsers(!openManagementUsers);
    };

    const [openOrganizations, setOpenOrganizations] = React.useState(false);
    const handleClickOrganizations = () => {
        setOpenOrganizations(!openOrganizations);
    };

    const [openCommunities, setOpenCommunities] = React.useState(false);
    const handleClickCommunities = () => {
        setOpenCommunities(!openCommunities);
    };

    const [openWaterSystem, setOpenWaterSystem] = React.useState(false);
    const handleClickWaterSystem = () => {
        setOpenWaterSystem(!openWaterSystem);
    };

    const [openProfile, setOpenProfile] = React.useState(false);
    const handleClickProfile = () => {
        setOpenProfile(!openProfile);
    };

    const [openAdministrator, setOpenAdministrator] = React.useState(false);
    const handleClickAdministrator = () => {
        setOpenAdministrator(!openAdministrator);
    };

    let handleChangeNow = (event : any) => {}
    let handleSubmit = (event : any) => {}

  return (
    <div className='sidebar'>

        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            // subheader={
            //     <ListSubheader component="div" id="nested-list-subheader">
            //     Nested List Items
            //     </ListSubheader>
            // }
            >
            <div className='center'>
                <ul>
                    <p className="title">MAIN</p>
                </ul>
            </div>
            <Link to="/home" style={{ textDecoration: "none" }}>
                <ListItemButton>
                    <ListItemIcon>
                    <DashboardIcon className="icon" />
                    </ListItemIcon>
                    <ListItemText secondary="Dashboard" />
                </ListItemButton>
            </Link>
            <div className='center'>
                <ul>
                    <p className="title">FEATURES</p>
                </ul>
            </div>

            <ListItemButton onClick={handleClickDiagnostic}>
                <ListItemIcon>
                    <QueryStatsIcon className="icon" />
                </ListItemIcon>
                <ListItemText secondary="Diagnostic" />
                {openDiagnostic ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openDiagnostic} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/diagnostic" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <QueryStatsIcon className="icon"/>
                                <span>Diagnostic</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/diagnostic/create" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <QueryStatsIcon className="icon"/>
                                <span>Create Diagnostic</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/diagnostic/list/:diagnosticId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <QueryStatsIcon className="icon"/>
                                <span>Diagnostic List</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/diagnostic/response" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <QueryStatsIcon className="icon"/>
                                <span>Diagnostic Response</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/diagnostic/edit/:diagnosticId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <QueryStatsIcon className="icon"/>
                                <span>Edit Diagnostic Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/diagnostic/detail/:diagnosticId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <QueryStatsIcon className="icon"/>
                                <span>Diagnostic Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                </List>
            </Collapse>

            <ListItemButton onClick={handleClickMaintenance}>
                <ListItemIcon>
                    <AssessmentIcon className="icon" />
                </ListItemIcon>
                <ListItemText secondary="Maintenance Checklist" />
                {openMaintenance ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openMaintenance} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/maintenance_checklist" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/maintenance_checklist/create" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Create Maintenance Checklist</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/maintenance_checklist/list/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist List</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    {/* <Link to="/home/maintenance_checklist/detail/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist Response</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                    {/* <Link to="/home/maintenance_checklist/edit/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Edit Maintenance Checklist Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/diagnostic/detail/:diagnosticId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                </List>
            </Collapse>

            <ListItemButton onClick={handleClickImpact}>
                <ListItemIcon>
                    <CreditCardIcon className="icon" />
                </ListItemIcon>
                <ListItemText secondary="Impact Measurements" />
                {openImpact ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openImpact} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/impact_measurement" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <CreditCardIcon className="icon" />
                                <span>Impact Measurements</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/impact_measurement/create" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <CreditCardIcon className="icon" />
                                <span>Create Impact Measurements</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/impact_measurement/list/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <CreditCardIcon className="icon" />
                                <span>Impact Measurements List</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    {/* <Link to="/home/maintenance_checklist/detail/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist Response</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                    <Link to="/home/impact_measurement/edit/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <CreditCardIcon className="icon" />
                                <span>Edit Impact Measurements Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/impact_measurement/detail/:diagnosticId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <CreditCardIcon className="icon" />
                                <span>Impact Measurements Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                </List>
            </Collapse>

            <ListItemButton onClick={handleClickForms}>
                <ListItemIcon>
                <DynamicFormIcon className="icon" />
                </ListItemIcon>
                <ListItemText secondary="Forms" />
                {openForms ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openForms} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/forms" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <DynamicFormIcon className="icon" />
                                <span>Forms</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/forms/create" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <DynamicFormIcon className="icon" />
                                <span>Create Forms</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/forms/list/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <DynamicFormIcon className="icon" />
                                <span>Forms List</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    {/* <Link to="/home/maintenance_checklist/detail/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist Response</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                    <Link to="/home/forms/edit/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <DynamicFormIcon className="icon" />
                                <span>Edit Forms Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/forms/detail/:diagnosticId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <DynamicFormIcon className="icon" />
                                <span>Forms Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                </List>
            </Collapse>

            <ListItemButton onClick={handleClickTraining}>
                <ListItemIcon>
                <ModelTrainingIcon className="icon" />
                </ListItemIcon>
                <ListItemText secondary="Training" />
                {openTraining ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openTraining} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/training" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <ModelTrainingIcon className="icon" />
                                <span>Training</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/training/create" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <ModelTrainingIcon className="icon" />
                                <span>Create Training</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/training/list/:trainingId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <ModelTrainingIcon className="icon" />
                                <span>Training List</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    {/* <Link to="/home/maintenance_checklist/detail/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist Response</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                    <Link to="/home/training/edit/:trainingId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <ModelTrainingIcon className="icon" />
                                <span>Edit Training Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/training/detail/:trainingId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <ModelTrainingIcon className="icon" />
                                <span>Training Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                </List>
            </Collapse>

            <div className='center'>
                <ul>
                    <p className="title">LISTS</p>
                </ul>
            </div>
            
            <ListItemButton onClick={handleClickManagementUsers}>
                <ListItemIcon>
                <PersonOutlineIcon className="icon" />
                </ListItemIcon>
                <ListItemText secondary="Users" style={{fontSize: "12px"}} />
                {openManagementUsers ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openManagementUsers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/user_management" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>Users</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/user_management/create_client" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>Add Clients</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/user_management/create_super_admin" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>Add Super Admin</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/user_management/create" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>Add Management User </span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/user_management/list/super_admins" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>View Super Admins</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/user_management/list/clients" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>View Clients</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/user_management/list/users" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>View Management Users</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    {/* <Link to="/home/maintenance_checklist/detail/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist Response</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                    <Link to="/home/profile/edit/:profileId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>Edit User Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/profile" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>User Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                </List>
            </Collapse>

            <ListItemButton onClick={handleClickOrganizations}>
                <ListItemIcon>
                <CorporateFareIcon className="icon" />
                </ListItemIcon>
                <ListItemText secondary="Organizations" />
                {openOrganizations ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openOrganizations} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/organization" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <CorporateFareIcon className="icon" />
                                <span>Organizations</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/organization/create" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <CorporateFareIcon className="icon" />
                                <span>Create Organizations</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/organization/list/:organizationId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <CorporateFareIcon className="icon" />
                                <span>Organizations List</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    {/* <Link to="/home/maintenance_checklist/detail/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist Response</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                    <Link to="/home/organization/edit/:organizationId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <CorporateFareIcon className="icon" />
                                <span>Edit Organizations Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/organization/detail/:organizationId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <CorporateFareIcon className="icon" />
                                <span>Organizations Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                </List>
            </Collapse>

            <ListItemButton onClick={handleClickCommunities}>
                <ListItemIcon>
                <StoreIcon className="icon" />
                </ListItemIcon>
                <ListItemText secondary="Communities" />
                {openCommunities ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openCommunities} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/community" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <StoreIcon className="icon" />
                                <span>Community</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/community/create" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <StoreIcon className="icon" />
                                <span>Create Community</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/community/list/:communityId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <StoreIcon className="icon" />
                                <span>Community List</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    {/* <Link to="/home/maintenance_checklist/detail/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist Response</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                    <Link to="/home/community/edit/:trainingId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <StoreIcon className="icon" />
                                <span>Edit Community Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/community/detail/:trainingId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <StoreIcon className="icon" />
                                <span>Community Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                </List>
            </Collapse>

            <div className='center'>
                <ul>
                    <p className="title">USEFUL</p>
                </ul>
            </div>
            
            <Link to="/home/notification" style={{ textDecoration: "none" }}>
                <ListItemButton>
                    <ListItemIcon>
                    <NotificationsNoneIcon className="icon" />
                    </ListItemIcon>
                    <ListItemText secondary="Notifications" />
                </ListItemButton>
            </Link>

            <div className='center'>
                <ul>
                    <p className="title">SERVICES</p>
                </ul>
            </div>

            <ListItemButton onClick={handleClickWaterSystem}>
                <ListItemIcon>
                <SettingsSystemDaydreamOutlinedIcon className="icon" />
                </ListItemIcon>
                <ListItemText secondary="Water System Type" />
                {openWaterSystem ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openWaterSystem} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/system_type" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <SettingsSystemDaydreamOutlinedIcon className="icon" />
                                <span>Water System Type</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/system_type/create" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <SettingsSystemDaydreamOutlinedIcon className="icon" />
                                <span>Create Water System Type</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/system_type/list/:system_typeId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <SettingsSystemDaydreamOutlinedIcon className="icon" />
                                <span>Water System Type List</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    {/* <Link to="/home/maintenance_checklist/detail/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist Response</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                    <Link to="/home/system_type/edit/:system_typeId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <SettingsSystemDaydreamOutlinedIcon className="icon" />
                                <span>Edit Water System Type Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/system_type/detail/:system_typeId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <SettingsSystemDaydreamOutlinedIcon className="icon" />
                                <span>Water System Type Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                </List>
            </Collapse>

            <Link to="/settings" style={{ textDecoration: "none" }}>
                <ListItemButton>
                    <ListItemIcon>
                    <SettingsApplicationsIcon className="icon" />
                    </ListItemIcon>
                    <ListItemText secondary="Settings" />
                </ListItemButton>
            </Link>


            <div className='center'>
                <ul>
                    <p className="title">USERS</p>
                </ul>
            </div>

            <ListItemButton onClick={handleClickProfile}>
                <ListItemIcon>
                <AccountCircleOutlinedIcon className="icon" />
                </ListItemIcon>
                <ListItemText secondary="Profile" />
                {openProfile ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openProfile} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/home/profile" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AccountCircleOutlinedIcon className="icon" />
                                <span>Profile</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    {/* <Link to="/home/training/create" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <ModelTrainingIcon className="icon" />
                                <span>Create Training</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/training/list/:trainingId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <ModelTrainingIcon className="icon" />
                                <span>Training List</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                    {/* <Link to="/home/maintenance_checklist/detail/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist Response</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                    <Link to="/home/profile/edit/:profileId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AccountCircleOutlinedIcon className="icon" />
                                <span>Edit Profile</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    {/* <Link to="/home/profile" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <ModelTrainingIcon className="icon" />
                                <span>Training Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                </List>
            </Collapse>

            <ListItemButton onClick={handleClickAdministrator}>
                <ListItemIcon>
                <PersonOutlineIcon className="icon" />
                </ListItemIcon>
                <ListItemText secondary="Administrators" />
                {openAdministrator ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openAdministrator} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to="/admin" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>Administrators</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/user_management/create" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>Create User</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/user_management/list/:user_managementId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>Management User List</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    {/* <Link to="/home/maintenance_checklist/detail/:maintenance_checklistId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <AssessmentIcon className="icon" />
                                <span>Maintenance Checklist Response</span>
                                </li>
                            </ul>
                        </div>
                    </Link> */}
                    <Link to="/home/profile/edit/:profileId" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>Edit User Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/home/profile" style={{ textDecoration: "none" }}>
                        <div className='center'>
                            <ul>
                                <li>
                                <PersonOutlineIcon className="icon" />
                                <span>User Details</span>
                                </li>
                            </ul>
                        </div>
                    </Link>
                </List>
            </Collapse>

            <Link to="/" style={{ textDecoration: "none" }}>
                <ListItemButton>
                    <ListItemIcon>
                    <ExitToAppIcon className="icon" />
                    </ListItemIcon>
                    <ListItemText secondary="Logout" />
                </ListItemButton>
            </Link>
                    
            </List>

        <div className="bottom">
            {/* <div className="colorOption" onClick={() => dispatch({ type: "LIGHT" })}>

            </div>
            <div className="colorOption" onClick={() => dispatch({ type: "DARK" })}>
                
            </div> */}
        </div>
   </div>
  );
};

export default SideBar;