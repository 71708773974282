import React from "react";
import DashboardCard from "../../components/card/card";
import Custom_Text from "../../components/custom_text";
import SectionHeader from "../../components/custom_text/section_header";
import DashboardPieChart from "../../components/dashboard_cards/dashboard_card_pie";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import Sidebar from "../../components/sidebars/sidebar";
import TableList from "../../components/table/table";
import './maintenance_checklist.scss'
import SideBarMaintenanceChecklist from './sidebar_maintenance_checklist/sidebarmaintenance';
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';


import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import logoImage from './images/logo.png';


import pendingForms from './images/pending_forms.png';
import allForms from './images/all.png';
import submitted_forms from './images/submitted_forms.png';
import { Grid } from '@mui/material';
import Checklist_DashboardCard from "./checklist_dashboard_card";
import organization_services from "../organization/organization_services";
import systemtype_services from "../system_type/systemtype_services";
import community_services from "../community/community_services";
import {useEffect, useState} from 'react';
import authentication_services from "../authentication/authentication_services";
import { Community, MaintenanceChecklist, MaintenanceChecklistDetails, Organization, SystemTypes, User } from "../authentication/authentication_interfaces";
import maintenance_checklist_service from "./maintenance_checklist_service";
import MaintenanceChecklistTable from "../../components/table/maintenance_checklist_table";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Maintenance_Checklist : React.FC = () => {

  const individualsTrainedPerSubjectBarData = {
    labels: ['today', 'yesterday', 'tomorrow', "next week"],
    datasets: [
      {
        label: 'Individuals Trained per Subject',
        backgroundColor: "#4EC7F2",
        borderColor: "#79B82C",
        borderWidth: 2,
        data: [34, 2, 9, 100]
      }
    ]
  }
  
  const communitySystemStatusPieData = {
    labels:["Functioning", "Maintenance Checklist Overdue", "Unresolved Diagnostics", "Maintenance & Diagnostic needs"],
    datasets:[{
     data: [300, 50, 100, 40],
     backgroundColor: [
        "#79B82C",
        "#4EC7F2",
        "#FFC870",
        "#A8B3C5",
        "#616774"
      ],
     borderWidth: 1
    }]
  };
  
  const maintenanceStatusPieData = {
    labels:["Compliant", "Overdue"],
    datasets:[{
     data: [300, 50],
     backgroundColor: [
        "#79B82C",
        "#FFB200"
      ],
     borderWidth: 1
    }]
  };
  
  const diagnosticStatusPieData = {
    labels:["Issue Reported", "Under Review", "Functioning", "Pending Maintenace", "Contact Community"],
    datasets:[{
     data: [300, 50, 100, 40, 20],
     backgroundColor: [
        "#79B82C",
        "#FFB200",
        "#5AD3D1",
        "#531CBC",
        "#FF0000",
      ],
     borderWidth: 1
    }]
  };

  
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let handleChange = (event : any) => {}
  let handleSubmit = (event : any) => {}

  const [fullName, setFullName] = useState<string>();
  const [userId, setUserId] = useState([]);
  const [bearer, setBearerToken] = useState<string>('');
  const [community_list_data, setCommunityListData] = useState<Community[] | null>([]);
  const [data, setOrganizationData] = useState<Organization[] | null>();
  const [systemData, setSystemTypeData] = useState<SystemTypes[] | null>();
  const [userData, setUserData] = useState<User | null>();
  const [totalMaintenanceChecklistData, setMaintenanceChecklistData] = useState<MaintenanceChecklistDetails | null>();

  const [maintenance_checklist_list_data, setMaintenanceChecklistListData] = useState<MaintenanceChecklist[] | null>([]);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('userId') || '{}');
    const user_fullName = JSON.parse(localStorage.getItem('user_fullName') || '{}');
    const bearerToken = JSON.parse(localStorage.getItem('bearerToken') || '{}');

    //get user details
    authentication_services.getUserDetails(userId, bearerToken).then((response) => {

      const status = response['data']['status'];
      const message = response['data']['message'];
      const userId = response['data']['data']["user_id"];
      console.log("user id " + userId);

      setUserData(response.data.data);
      console.log('User First Name ' + response['data']['data']['first_name']);
      console.log('User API status ' + response['data']['status']);
      
      if(status === "Success"){

        setUserId(userId);
        setFullName(user_fullName);
        setBearerToken(bearerToken);
         
      }

      }).catch(function (error) {

        // setErrorMessage(error.toString());
        console.log('error ' + error.toJSON());
        console.log('error message ' + error.toString());
        // setopenErrorModal(true);

    });
    
    // //Get all organizations list 
    // organization_services.getAllOrganizationsDropDownList()
    // .then((response) => {

    //   console.log('API call response status ' + response.status);
    //   setOrganizationData(response.data.data);
    //   console.log('Organization Name ' + response['data']['data'][0]['name']);
    //   console.log('Organization Name ' + response['data']['status']);

    // }).catch((error) => {
    //   console.log('error ' + error.toJSON());
    //   console.log('error message ' + error.toString());
    // });

    //Get all system type list 
    // systemtype_services.getAllSystemTypeDropDownList()
    // .then((response) => {

    //   console.log('API call response status ' + response.status);
    //   setSystemTypeData(response.data.data);
    //   console.log('Organization Name ' + response['data']['data'][0]['name']);
    //   console.log('Organization Name ' + response['data']['status']);

    // }).catch((error) => {
    //   console.log('error ' + error.toJSON());
    //   console.log('error message ' + error.toString());
    // });
    
    // //get list of communities
    // community_services.getAllCommunityList(bearerToken)
    // .then((response) => {

    //   console.log('API call response status ' + response.status);
    //   console.log(response.data.data)
    //   setCommunityListData(response.data.data);
    //   console.log('Community Name ' + response['data']['data'][0]['community_name']);

    // }).catch((error) => {
    //   console.log('error ' + error.toJSON());
    //   console.log('error message ' + error.toString());
    // });

    maintenance_checklist_service.getMaintenanceChecklistDetails(bearerToken)
    .then((response) => {

      console.log('API call response status ' + response.status);
      console.log(response.data.data)
      setMaintenanceChecklistData(response.data.data);
      console.log('Number Completed Maintenance Checklist ' + response['data']['data']['CompletedMaintenanceList']);

    }).catch((error) => {
      console.log('error ' + error.toJSON);
      console.log('error message ' + error.toString());
    });

    maintenance_checklist_service.getAllMaintenanceChecklistDropDownList()
    .then((response) => {

      console.log('API call response status ' + response.status);
      console.log(response.data.data)
      setMaintenanceChecklistListData(response.data.data);
      console.log('Maintenance Checklist Name ' + response['data']['data'][0]['title']);

    }).catch((error) => {
      console.log('error ' + error.toJSON);
      console.log('error message ' + error.toString());
    });

  }, [bearer]); 

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open} style={{width: "100%"}}>
        <DrawerHeader />
        <DrawerHeader />
        

        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          <Grid item xs={12} md={12} sm={12} lg={12}>
            <SectionHeader sectionTitle={'MAINTENANCE CHECKLIST'} link='' />
          </Grid>
          <div style={{height: "20px"}}></div>
          <Grid item xs={12} md={4} sm={6} lg={6}>
            <NavCard_Community cardContent={20} cardTitle={'Create Maintenance Checklist'} image={createImage} link={"/home/maintenance_checklist/create"} />
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={6}>
            <NavCard_Community cardContent={20} cardTitle={'Maintenance Checklist List'} image={listImage} link={"/home/maintenance_checklist/list/:maintenance_checklistId"} />
          </Grid>
          {/* <Grid item xs={12} md={4} sm={6} lg={3}>
            <NavCard_Community cardContent={20} cardTitle={'Edit Maintenance Checklist'} image={editImage} link={"/home/maintenance_checklist/edit/:maintenance_checklistId"} />
          </Grid>
          <Grid item xs={12} md={4} sm={6} lg={3}>
            <NavCard_Community cardContent={20} cardTitle={'Maintenance Checklist Details'} image={detailImage} link={"/home/maintenance_checklist/detail/:maintenance_checklistId"} />
          </Grid> */}
          {totalMaintenanceChecklistData != null ? 
          <Grid item xs={12} md={4} sm={6} lg={4}>
            <Checklist_DashboardCard cardContent={totalMaintenanceChecklistData!.totalNumberOfMaintenanceChecklist} cardTitle={'Total Maintenance Checklist'} image={allForms} link={""}/>
          </Grid>: 
          ""}
          
          {totalMaintenanceChecklistData != null ? 
          <Grid item xs={12} md={4} sm={6} lg={4}>
            <Checklist_DashboardCard cardContent={totalMaintenanceChecklistData!.PendingMaintenanceList} cardTitle={'Pending Maintenance Checklist'} image={pendingForms} link={""}/>
          </Grid> : 
          ""}
          
          {totalMaintenanceChecklistData != null ? 
          <Grid item xs={12} md={4} sm={6} lg={4}>
            <Checklist_DashboardCard cardContent={totalMaintenanceChecklistData!.CompletedMaintenanceList} cardTitle={'Completed Maintenance Checklist'} image={submitted_forms} link={""}/>
          </Grid> : 
          ""}
          


          {/* <Grid item xs={12} md={12} sm={12} lg={12}>
            <Checklist_DashboardCard cardContent={20} cardTitle={'Total Maintenance Checklist'} image={allForms} link={""}/>
          </Grid> */}

          <Grid item xs={12} md={12} sm={12} lg={12}>
            <SectionHeader sectionTitle={'CHECKLISTS'} link='' />
            <div style={{height: "20px"}}></div>
            <div className="listContainer">
              <div className="listTitle">Maintenance Checklist Lists</div>
              <MaintenanceChecklistTable data={maintenance_checklist_list_data}/>
            </div>
          </Grid>

        </Grid>


       
      </Main>
    </Box>
  );

};

export default Maintenance_Checklist;