import React from "react";
import './custom_text.css'

const Custom_Text : React.FC = () => {
  return (
    <div>
      <h1 className="welcomeText">WellBeyond Waters</h1>
    </div>
  );
};

export default Custom_Text;