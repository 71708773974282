import React, { useEffect, useState } from "react";
import DashboardCard from "../../components/card/card";
import SectionHeader from "../../components/custom_text/section_header";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import NavCard_Community from "../../components/nav_card/navcard_community";
import Sidebar from "../../components/sidebars/sidebar";
import './community.scss'
import SideBarCommunity from "./sidebar_community/sidebarcommunity";
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';
import Organization_Details_Card from "../../components/organization_card_details";



import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link, useParams } from 'react-router-dom';
import logoImage from './images/logo.png';
import { Community, User, CommunitySystemTypes, Address, Organization, CommunitySystemTypesList, MaintenanceChecklist } from '../authentication/authentication_interfaces';
import authentication_services from "../authentication/authentication_services";
import community_services from "./community_services";
import { Grid } from "@mui/material";
import CommunitySystemTypeTable from "../../components/table/community_systemType_table";
import { data } from '../form/makeData';
import MaintenanceChecklistStepTable from "../../components/table/maintenance_checklist_steps_table";
import MaintenanceChecklistResponseTable from "../../components/table/maintenance_checklist_response_table";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Community_Detail : React.FC = () => {

  const { communityId } = useParams();
  console.log("communityId " + communityId);
  
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let handleChange = (event : any) => {}
  let handleSubmit = (event : any) => {}

  const [fullName, setFullName] = useState<string>();
  const [userId, setUserId] = useState([]);
  const [bearer, setBearerToken] = useState<string>();

  //Api call state management
  const [userData, setUserData] = useState<User | null>();
  const [userCreatedCommunityData, setUserCreatedCommunityData] = useState<User | null>();
  const [communitydata, setCommunityData] = useState<Community | null>();
  const [communityOrganizationdata, setCommunityOrganizationData] = useState<Organization | null>();
  const [communityAddressdata, setCommunityAddressData] = useState<Address | null>();
  const [communitySystemTypedata, setCommunitySystemTypesData] = useState<CommunitySystemTypes[] | null>([]);
  const [communitySystemTypesList, setCommunitySystemTypesList] = useState<CommunitySystemTypesList[] | null>([]);
  const [maintenanceChecklist, setMaintenanceChecklist] = useState<MaintenanceChecklist | null>();

  const updateMaintenanceChecklist = (maintenanceChecklist:MaintenanceChecklist) => {
    setMaintenanceChecklist(maintenanceChecklist);
  }

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('userId') || '{}');
    const user_fullName = JSON.parse(localStorage.getItem('user_fullName') || '{}');
    const bearerToken = JSON.parse(localStorage.getItem('bearerToken') || '{}');

    //get user details
    authentication_services.getUserDetails(userId, bearerToken).then((response) => {

      const status = response['data']['status'];
      const message = response['data']['message'];
      const userId = response['data']['data']["user_id"];
      console.log("user id " + userId);

      setUserData(response.data.data);
      console.log('User First Name ' + response['data']['data']['first_name']);
      console.log('User API status ' + response['data']['status']);
      // console.log('User API Last Name ' + userData?.last_name);

      if(status === "Success"){

        setUserId(userId);
        setFullName(user_fullName);
        setBearerToken(bearerToken);
         
      }

      }).catch(function (error) {

        // setErrorMessage(error.toString());
        console.log('error ' + error.toJSON());
        console.log('error message ' + error.toString());
        // setopenErrorModal(true);

    });
    
    //Get all communities 
    community_services.getOneCommunity(bearerToken, parseInt(communityId!))
    .then((response) => {

      console.log('API call response status ' + response.status);
      console.log(response.data.data)
      setCommunityData(response.data.data);
      console.log('Community Name ' + response['data']['data']['community_name']);
      setCommunityOrganizationData(response['data']['data']['organization']);
      setCommunitySystemTypesList(response['data']['data']['communitySystemTypesList']);
   
      setCommunityAddressData(response['data']['data']['address'][0]);
      setCommunitySystemTypesData(response['data']['data']['communitySystemTypes']);

    //get user that created community details
    authentication_services.getUserDetails(response['data']['data']['created_by'], bearerToken).then((response) => {

      const status = response['data']['status'];
      const message = response['data']['message'];
      const userId = response['data']['data']["user_id"];
      console.log("created by user id " + userId);

      setUserCreatedCommunityData(response.data.data);

    });

    }).catch((error) => {
      console.log('error ' + error.toJSON());
      console.log('error message ' + error.toString());
    });

    


  }, []); 

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open} >
        <div style={{height: "2%"}}></div>
        <div style={{"width":"98%"}}>
          <DrawerHeader />
          
          <SectionHeader sectionTitle={'COMMUNITIES DETAILS'} link='' />
          <div style={{height: "12%"}}></div>
          <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
            <Grid item xs={12} sm={6} md={6}>
              <NavCard_Community cardContent={20} cardTitle={'Create Community'} image={createImage} link={"/home/community/create"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <NavCard_Community cardContent={20} cardTitle={'Community List'} image={listImage} link={"/home/community/list/:communityId"} />
            </Grid>
            {/* <Grid item xs={6} sm={6} md={4}>
              <NavCard_Community cardContent={20} cardTitle={'Edit Community'} image={editImage} link={"/home/community/edit/:communityId"} />
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <NavCard_Community cardContent={20} cardTitle={'Community Details'} image={detailImage} link={"/home/community/detail/:communityId"} />
            </Grid> */}
            

          </Grid>
          <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
          <Grid item xs={12} sm={12} md={6}>
              { communityOrganizationdata != null ? 
                <Organization_Details_Card 
                  organizationName={communityOrganizationdata!.name} 
                  email={communityOrganizationdata!.email} 
                  image={"https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"} 
                  phoneNumber={communityOrganizationdata!.phone_number} 
                  address={""} 
                  country={""}
                  catrdTile={"Organization Information"}
                />
              : 
              null }
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              { communityOrganizationdata != null ? 
                <Organization_Details_Card 
                  organizationName={`${userCreatedCommunityData?.first_name} ${userCreatedCommunityData?.last_name}`} 
                  email={`${userCreatedCommunityData?.email}`} 
                  image={"https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"} 
                  phoneNumber={`${userCreatedCommunityData?.phone_number}`} 
                  address={""} 
                  country={""}
                  catrdTile={"Community Created by"}
                />
              : 
              null }
            </Grid>
          </Grid>
        </div>

        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h3 className="title">Community Information</h3>
            <hr 
              style={{
                height: "2px",
                backgroundColor: '#dddddd',
                marginTop: '10px',
                marginBottom: '10px',
                border: "none",
              }}
            />

            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={12} sm={12} md={4} >
              <div className="item" style={{
                    "marginLeft":"20px", 
                    "border": "0.5px solid lightgray",
                    "padding":"20px", "borderRadius":"10px",
                    "marginTop":"10px",
                    "marginBottom":"10px",
                  }}>
                  <div className="details" style={{"width":"100%", }}>
                    <h2 className="itemTitle">{communitydata?.community_name}</h2>
                    <hr style={{
                      height: "0.5px",
                      backgroundColor: '#dddddd',
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: "none",
                    }}/>
                    <div className="detailItem">
                      <span className="itemKey">Community Population:</span>
                      <span className="itemValue">{communitydata?.population}</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">System type:</span>
                      <span className="itemValue">Bolehole</span>
                    </div>
                    
                    <div className="detailItem">
                      <span className="itemKey">System Status:</span>
                      <span className="itemValue" style={{"color":"green", }}>{communitydata?.system_type ? "Active" : "Deactiavted"}</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Created On:</span>
                      <span className="itemValue">
                        {communitydata?.created_at}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Description:</span>
                      <span className="itemValue">{communitydata?.community_description}</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Address:</span>
                      <span className="itemValue">{communitydata?.address[0].address}</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Address:</span>
                      <span className="itemValue">{communitydata?.address[0].city}</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Country:</span>
                      <span className="itemValue">{communitydata?.address[0].country}</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Postal Code:</span>
                      <span className="itemValue">{communitydata?.address[0].postal_code}</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">Latitude:</span>
                      <span className="itemValue">{communitydata?.address[0].latitude}</span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">longitude:</span>
                      <span className="itemValue">{communitydata?.address[0].longitude}</span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} >
                <Grid item xs={12} sm={12} md={12} >
                  <div className="item" style={{
                      "marginLeft":"20px", 
                      "border": "0.5px solid lightgray",
                      "padding":"20px", "borderRadius":"10px",
                      "marginTop":"10px",
                      "marginBottom":"10px",
                    }}>
                    <div className="details" style={{"width":"100%", }}>
                    <h3 className="itemTitle">System Types</h3>
                      <hr style={{
                        height: "0.5px",
                        backgroundColor: '#dddddd',
                        marginTop: '10px',
                        marginBottom: '0px',
                        border: "none",
                      }}/>
                      
                      <CommunitySystemTypeTable 
                        data={communitySystemTypesList} 
                        handleUpdatedMaintenanceChecklist={updateMaintenanceChecklist} 
                      />

                    </div>
                  </div>  
                </Grid>
                {maintenanceChecklist != null ? <Grid item xs={12} sm={12} md={12} >
                  <div className="item" style={{
                      "marginLeft":"20px", 
                      "border": "0.5px solid lightgray",
                      "padding":"20px", "borderRadius":"10px",
                      "marginTop":"10px",
                      "marginBottom":"10px",
                    }}>
                    <div className="details" style={{"width":"100%", }}>
                      <h3 className="itemTitle">Maintenance Checklist Details</h3>
                      <hr style={{
                        height: "0.5px",
                        backgroundColor: '#dddddd',
                        marginTop: '10px',
                        marginBottom: '10px',
                        border: "none",
                      }}/>
                      <div style={{
                        height: "133px",

                        marginTop: '2px',
                        marginBottom: '2px',

                      }}>
                        <div className="detailItem">
                          <span className="itemKey">Name:</span>
                          <span className="itemValue">{maintenanceChecklist?.title}</span>
                        </div>
                        <div className="detailItem">
                          <span className="itemKey">Recommended frequency of maintenance:</span>
                          <span className="itemValue">{maintenanceChecklist?.recommended_frequency_of_maintenance}</span>
                        </div>
                        <div className="detailItem">
                          <span className="itemKey">Number of Steps:</span>
                          <span className="itemValue">{maintenanceChecklist?.number_of_steps}</span>
                        </div>
                        <div className="detailItem">
                          <span className="itemKey">Description:</span>
                          <span className="itemValue">{maintenanceChecklist?.description}</span>
                        </div>
                        {/* <div className="detailItem">
                          <span className="itemKey">Published:</span>
                          <span className="itemValue">{maintenanceChecklist?.isPublished}</span>
                        </div> */}
                        <div className="detailItem">
                          <span className="itemKey">Date Created:</span>
                          <span className="itemValue">{maintenanceChecklist?.created_at}</span>
                        </div>
                        
                      </div>
                      

                    </div>
                  </div>  
                </Grid> : ""} 
              </Grid>

              {maintenanceChecklist != null ? <Grid item xs={12} sm={12} md={12} >
                <div className="item" style={{
                    "marginLeft":"20px", 
                    "border": "0.5px solid lightgray",
                    "padding":"20px", "borderRadius":"10px",
                    "marginTop":"10px",
                    "marginBottom":"10px",
                  }}>
                  <div className="details" style={{"width":"100%", }}>
                    <h3 className="itemTitle">Maintenance Checklist Steps</h3>
                    <hr style={{
                      height: "0.5px",
                      backgroundColor: '#dddddd',
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: "none",
                    }}/>

                    <MaintenanceChecklistStepTable data={maintenanceChecklist!.maintenance_Checklist_Steps} isFromDashboard={true}/>
                    

                  </div>
                </div>  
              </Grid> : ""}
              
              {maintenanceChecklist != null ? <Grid item xs={12} sm={12} md={12} >
                <div className="item" style={{
                    "marginLeft":"20px", 
                    "border": "0.5px solid lightgray",
                    "padding":"20px", "borderRadius":"10px",
                    "marginTop":"10px",
                    "marginBottom":"10px",
                  }}>
                  <div className="details" style={{"width":"100%", }}>
                    <h3 className="itemTitle">Maintenance Checklist Response</h3>
                    <hr style={{
                      height: "0.5px",
                      backgroundColor: '#dddddd',
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: "none",
                    }}/>

                    <MaintenanceChecklistResponseTable data={maintenanceChecklist!.maintenance_Checklist_response}/>

                  </div>
                </div>  
              </Grid> : ""}
              
            </Grid>

          </div>
        </div>
      </Main>
    </Box>
  );
  
};

export default Community_Detail;