import React from "react";
import DashboardCard from "../../components/card/card";
import Custom_Text from "../../components/custom_text";
import SectionHeader from "../../components/custom_text/section_header";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import Sidebar from "../../components/sidebars/sidebar";
import './forms.scss'
import SideBarForm from './sidebar_form/sidebar_form';

// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';

import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Button, FormHelperText, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";


import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import logoImage from './images/logo.png';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Create_Form_types : React.FC = () => {

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [form_type_name, setFormTypeName] = React.useState('Form Type Name');
  const [form_type_category, setFormTypeCategory] = React.useState('');
  const [value, setValue] = React.useState('Form Type Description');

  let handleSubmit = (event : any) => {}

  

  const handleMultilineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };


  const handleDropDownFormTypeCategory = (event: SelectChangeEvent) => {
    setFormTypeCategory(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormTypeName(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div className="home">

          <div className="homeContainer">

            <SectionHeader sectionTitle={'CREATE FORM TYPES'} link='' />
            <div className="cardPadding">
          
              <NavCard_Community cardContent={20} cardTitle={'Create Form'} image={createImage} link={"/home/forms/create"} />
              <NavCard_Community cardContent={20} cardTitle={'Forms List'} image={listImage} link={"/home/forms/list/:formsId"} />
              <NavCard_Community cardContent={20} cardTitle={'Submitted Forms'} image={editImage} link={"/home/forms/forms_submitted"} />
              <NavCard_Community cardContent={20} cardTitle={'Form Details'} image={detailImage} link={"/home/forms/detail/:formsId"} />

            </div>
            {/* <div className="cardPadding">
              <DashboardCard cardContent={20} cardTitle={'Organizations'} image={organizationImage} link={""}/>
              <DashboardCard cardContent={40} cardTitle={'Communities'} image={communityImage} link={""}/>
              <DashboardCard cardContent={230} cardTitle={'Management Users'} image={managementUsersImage} link={""}/>
              <DashboardCard cardContent={130} cardTitle={'People Trained'} image={trainedPeopleImage} link={""}/>
            </div> */}

            <div className="top">
              <div className="left">
                <div className="item">

                  <div className="formBox" style={{
                    "marginLeft":"10px", 
                    "border": "0.5px solid lightgray",
                    "padding":"20px", 
                    "borderRadius":"5px",
                    "marginTop":"10px",
                    "marginBottom":"10px",
                    "width": "100%",
                  }}>

                    <div className="details" style={{"width":"100%", }}>
                      <h1 className="itemTitle">Form Types</h1>
                      <hr style={{
                        height: "0.5px",
                        backgroundColor: '#dddddd',
                        marginTop: '10px',
                        marginBottom: '10px',
                        border: "none",
                      }}/>

                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1 },
                        }}
                        noValidate
                        autoComplete="off">

                        <div style={{"width":"100%"}}>

                        <div className="item">
                          <img
                            src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"
                            alt=""
                            className="itemImg"
                          />
                          <div style={{
                                "width": "50%",
                                "height": "50px", 
                                "marginTop":"20px", 
                                "marginBottom":"20px", 
                                "marginRight":"10px",  
                                "marginLeft":"10px",
                              }}>
                                
                            <Button 
                              style={{
                                "width": "50%",
                                "height": "50px", 
                                "marginTop":"40px", 
                                "marginBottom":"20px", 
                                "marginRight":"10px",  
                                "marginLeft":"10px",
                              }}
                              size="large"
                              variant="outlined"
                              onClick={() => {
                                alert('clicked');
                              }}
                            >
                              Select Form Type Image
                            </Button>
                          </div>

                        </div>

                          <div 
                            style={{
                              "display": "flex", 
                              "justifyContent": "space-between", 
                              "marginRight":"10px",  
                              "marginLeft":"10px",
                              "marginTop":"20px",  
                              // "marginBottom":"20px"  
                            }}
                          >

                            <FormControl style={{"width": "45%"}}>
                              <InputLabel htmlFor="component-outlined">Form Type Name</InputLabel>
                              <OutlinedInput
                                id="component-outlined"
                                value={form_type_name}
                                onChange={handleChange}
                                label="Form Type Name"
                                
                              />
                              <FormHelperText id="component-outlined-text">Error</FormHelperText>
                            </FormControl>

                            <FormControl style={{"width": "45%"}}>
                              <TextField
                                id="outlined-multiline-static"
                                label="Form Type Description"
                                multiline
                                maxRows={4}
                                value={value}
                                onChange={handleMultilineChange}
                                defaultValue="Default Value"
                              />
                              <FormHelperText id="component-outlined-text">Error</FormHelperText>
                            </FormControl>

                          </div>

                          <div 
                            style={{
                              "display": "block", 
                              "justifyContent": "space-between", 
                              "marginRight":"10px",  
                              "marginLeft":"10px",
                              "marginTop":"20px",  
                              // "marginBottom":"20px"  
                            }}
                          >


                            {/* dropdown component */}
                            <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} error>
                              <InputLabel id="demo-simple-select-error-label">Select Form Type Category</InputLabel>
                              <Select
                                labelId="demo-simple-select-error-label"
                                id="demo-simple-select-error"
                                value={form_type_category}
                                label="Select Form Type Category"
                                onChange={handleDropDownFormTypeCategory}
                                renderValue={(value) =>  value }
                              >
                                <MenuItem value=""><em>None</em></MenuItem>
                                <MenuItem value={"Misc Reporting"}>Misc Reporting</MenuItem>
                                <MenuItem value={"Water System"}>Water System</MenuItem>
                                
                              </Select>
                              <FormHelperText>Error</FormHelperText>
                            </FormControl>

                          </div>

                          <Button 
                            style={{
                              "width": "50%",
                              "height": "50px", 
                              "marginTop":"40px", 
                              "marginBottom":"20px", 
                              "marginRight":"10px",  
                              "marginLeft":"10px",
                            }}
                            size="large"
                            variant="contained"
                            onClick={() => {
                              alert('clicked');
                            }}
                          >
                            Create Form Type
                          </Button>

                      </div>


                      </Box>

                      

                    </div>


                  </div>

                </div>
              </div>
            </div>
          
          </div>
        </div>
       
      </Main>
    </Box>
  );

};

export default Create_Form_types;