export type Person = {
    id: number;
    firstName: string;
    lastName: string;
    company: string;
    city: string;
    country: string;
  };
  
  export const data = [
    {
      id: 1,
      firstName: 'Elenora',
      lastName: 'Wilkinson',
      company: 'Feest - Reilly',
      city: 'Hertaland',
      country: 'Qatar',
    },
    {
      id: 2,
      firstName: 'Berneice',
      lastName: 'Feil',
      company: 'Deckow, Leuschke and Jaskolski',
      city: 'Millcreek',
      country: 'Nepal',
    },
    {
      id: 3,
      firstName: 'Frieda',
      lastName: 'Baumbach',
      company: 'Heidenreich, Grady and Durgan',
      city: 'Volkmanside',
      country: 'Croatia',
    },
    {
      id: 4,
      firstName: 'Zachery',
      lastName: 'Brown',
      company: 'Cormier - Skiles',
      city: 'Faychester',
      country: 'Saint Pierre and Miquelon',
    },
    {
      id: 5,
      firstName: 'Kendra',
      lastName: 'Bins',
      company: 'Wehner - Wilderman',
      city: 'New Valentin',
      country: 'Senegal',
    },
    {
      id: 6,
      firstName: 'Lysanne',
      lastName: 'Fisher',
      company: 'Schmidt LLC',
      city: 'Malachitown',
      country: 'Costa Rica',
    },
    {
      id: 7,
      firstName: 'Garrick',
      lastName: 'Ryan',
      company: 'Ryan - Buckridge',
      city: 'East Pearl',
      country: 'Cocos (Keeling) Islands',
    },
    {
      id: 8,
      firstName: 'Hollis',
      lastName: 'Medhurst',
      company: 'Quitzon Group',
      city: 'West Sienna',
      country: 'Papua New Guinea',
    },
    {
      id: 9,
      firstName: 'Arlo',
      lastName: 'Buckridge',
      company: 'Konopelski - Spinka',
      city: 'Chino',
      country: 'Congo',
    },
    {
      id: 10,
      firstName: 'Rickie',
      lastName: 'Auer',
      company: 'Lehner - Walsh',
      city: 'Nyahfield',
      country: 'Sudan',
    },
    {
      id: 11,
      firstName: 'Isidro',
      lastName: 'Larson',
      company: 'Reichert - Paucek',
      city: 'Fort Rosinaside',
      country: 'Belize',
    },
    {
      id: 12,
      firstName: 'Bettie',
      lastName: 'Skiles',
      company: 'Zulauf, Flatley and Rolfson',
      city: 'West Feltonchester',
      country: 'Poland',
    },
  ] as Person[];

//50 us states array
export const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
    'Puerto Rico',
  ];