import axios from "axios";
import http from "../../http-common";

class Maintenance_Checklist_Services {

    config = {
        headers:{
          'content-type': 'application/json',
          // header2: value2
        }
    };

    // eslint-disable-next-line no-dupe-args
    getAllMaintenanceChecklistDropDownList(){
        return http.get(`maintenanceCheckList/`, this.config);
    }

    getAllMaintenanceChecklistList(bearerToken: string){
        axios.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
        return http.get(`maintenanceCheckList/`, this.config);
    }

    // getOneMaintenanceChecklist(bearerToken: string, maintenance_checklist_id: number){
    //     axios.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
    //     return http.get(`maintenanceCheckList/${maintenance_checklist_id}`, this.config);
    // }

    getMaintenanceChecklist(bearerToken: string, maintenance_checklist_id: number){
        http.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
        return http.get(`maintenanceCheckList/${maintenance_checklist_id}`, this.config);
    }

    getMaintenanceChecklistResponse(bearerToken: string, maintenance_checklist_response_id: number){
        http.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
        return http.get(`maintenanceCheckListResponse/${maintenance_checklist_response_id}`, this.config);
    }

    getMaintenanceChecklistSteps(bearerToken: string, maintenance_checklist_step_id: number){
        http.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
        return http.get(`maintenanceCheckListStep/${maintenance_checklist_step_id}`, this.config);
    }

    getOrganizationMaintenanceChecklistResponse(bearerToken: string, maintenance_checklist_id: number,  organization_id: number){
        http.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;

        let data = {

            maintenance_checklist_id: maintenance_checklist_id,
            organization_id: organization_id,
        
        };

        return http.post(`maintenanceCheckList/getAllOrganizationMaintenanceChecklist`, data, this.config);
    }

    getMaintenanceChecklistDetails(bearerToken: string){
        http.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;

        let data = {

            maintenance_checklist_id: "",
            organization_id: "",
        
        };

        return http.post(`maintenanceCheckList/getMaintenanceChecklistDetails`, data, this.config);
    }

    createMaintenance_Checklist (organization_id: number, system_type_id: number, isPublished: boolean, steps_completed: boolean, image: string, 
        community_name: string, communityPopulation: string, recommended_frequency_of_maintenance: number, description: string, 
        communityDescription: string, selectedSystemTypesId: any[], bearer : string) {
        
        let createCommunityData = {

            // organization_id: parseInt(organization_name.charAt(0)),
            // organization_id: organizationId,
            community_name : community_name,
            image : image,//"https://images.nigeriapropertycentre.com/area-guides/f738af22-d802-4911-a09b-874ab03f586f.jpeg",
            population : communityPopulation,
            community_description : communityDescription,
            system_type: selectedSystemTypesId,
            active : true
        
        };
    
        console.log("community systemt type data " + selectedSystemTypesId);
        console.log("community data " + createCommunityData);

        http.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
        return http.put(`community/update_community/${organization_id}`, createCommunityData, this.config)

    }

}

export default new Maintenance_Checklist_Services();