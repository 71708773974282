import axios from "axios";
import http from "../../http-common";

class Community_Services {

    config = {
        headers:{
          'content-type': 'application/json',
          // header2: value2
        }
    };

    // eslint-disable-next-line no-dupe-args
    getAllCommunityDropDownList(bearerToken: string){
        http.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
        return http.get(`community/`, this.config);
    }

    getAllCommunityList(bearerToken: string){
        http.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
        return http.get(`community/`, this.config);
    }

    getOneCommunity(bearerToken: string, community_id: number){
        http.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
        return http.get(`community/${community_id}`, this.config);
    }

    getOneCommunityAddress(bearerToken: string, community_id: number){
        http.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
        return http.get(`address/community/${community_id}`, this.config);
    }

    deleteCommunitySystemType(bearerToken: string, community_system_types_id: number){
        http.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
        return http.delete(`community/delete_community_system_type/${community_system_types_id}`, this.config);
    }

    createCommunity (organizationId: number, profile_image: string, community_name: string, communityPopulation: string, communityDescription: string, selectedSystemTypesId: any[], bearer : string) {
        
            let createCommunityData = {

                // organization_id: parseInt(organization_name.charAt(0)),
                organization_id: organizationId,
                community_name : community_name,
                image : profile_image,//"https://images.nigeriapropertycentre.com/area-guides/f738af22-d802-4911-a09b-874ab03f586f.jpeg",
                population : communityPopulation,
                community_description : communityDescription,
                system_type: selectedSystemTypesId,
                active : true
            
            };
        
        console.log("community systemt type data " + selectedSystemTypesId);
        console.log("community data " + createCommunityData);

        http.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
        return http.post(`community/createCommunity`, createCommunityData, this.config)

    }

    updateCommunity (community_id: number, profile_image: string, community_name: string, communityPopulation: string, communityDescription: string, selectedSystemTypesId: any[], bearer : string) {
        
        let createCommunityData = {

            // organization_id: parseInt(organization_name.charAt(0)),
            // organization_id: organizationId,
            community_name : community_name,
            image : profile_image,//"https://images.nigeriapropertycentre.com/area-guides/f738af22-d802-4911-a09b-874ab03f586f.jpeg",
            population : communityPopulation,
            community_description : communityDescription,
            system_type: selectedSystemTypesId,
            active : true
        
        };
    
        console.log("community systemt type data " + selectedSystemTypesId);
        console.log("community data " + createCommunityData);

        http.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
        return http.put(`community/update_community/${community_id}`, createCommunityData, this.config)

    }

    createCommunityAddress (community_id: number, street_address: string, city: string, postal_code: string, country: string, latitude: string, longitude: string, bearer: string) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
        let createUserAddressData = {
            user_id: null,
            community_id : community_id,
            organization_id: null,
            is_default: true,
            address : street_address,
            state : "userState",
            city : city,
            latitude : latitude,
            longitude : longitude,
            postal_code : postal_code,
            country : country
        };
  
        console.log("user address data " + createUserAddressData, );
        return http.post(`address/createAddress`, createUserAddressData, this.config);

    }

    updateCommunityAddress (community_id: number, street_address: string, city: string, postal_code: string, country: string, latitude: string, longitude: string, bearer: string) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
        let createUserAddressData = {
            user_id: null,
            community_id : community_id,
            organization_id: null,
            is_default: true,
            address : street_address,
            state : "userState",
            city : city,
            latitude : latitude,
            longitude : longitude,
            postal_code : postal_code,
            country : country
        };
  
        console.log("user address data " + createUserAddressData, );
        return http.put(`address/update_community_address/${community_id}`, createUserAddressData, this.config);

    }

}

export default new Community_Services();