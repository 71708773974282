import React from "react";
import DashboardCard from "../../components/card/card";
import Custom_Text from "../../components/custom_text";
import SectionHeader from "../../components/custom_text/section_header";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import Sidebar from "../../components/sidebars/sidebar";
import './forms.scss'
import SideBarForm from './sidebar_form/sidebar_form';

// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';

import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Theme, useTheme } from '@mui/material/styles';
import { Button, Checkbox, Chip, DialogActions, DialogContent, FormControlLabel, FormGroup, FormLabel, ListItemText, Radio, RadioGroup, Step, StepLabel, Stepper, Switch, TextField, Typography } from "@mui/material";
import BootstrapDialogTitle, { BootstrapDialog } from "../../components/dialog_box/dialog_text_box";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import logoImage from './images/logo.png';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const steps = ['Create Form Details', 'Create Form Questions', 'Confirm Form Details'];

const Create_Forms : React.FC = () => {

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [isPublishedChecked, setPublishedChecked] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const [organization_name, setOrganization] = React.useState('');
  const [system_type, setSystem_Type] = React.useState('');

  const [form_name, setFormName] = React.useState('Form Name');
  const [form_type_category, setFormTypeCategory] = React.useState('');
  const [value, setValue] = React.useState('Form Description');
  const [form_type, setFormType] = React.useState('');

  const handleMultilineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleDropDownFormType = (event: SelectChangeEvent) => {
    setFormType(event.target.value);
  };

  const handleDropDownFormTypeCategory = (event: SelectChangeEvent) => {
    setFormTypeCategory(event.target.value);
  };

  const handleFormNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormName(event.target.value);
  };

  const handleDropDownSystemTypeChange = (event: SelectChangeEvent) => {
    setSystem_Type(event.target.value);
  };

  const handleDropDowOrganizationChange = (event: SelectChangeEvent) => {
    setOrganization(event.target.value);
  };

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handlePublishedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublishedChecked(event.target.checked);
    console.log(isPublishedChecked);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div className="home">

          <div className="homeContainer">

            <SectionHeader sectionTitle={'CREATE FORMS'} link='' />
            <div className="cardPadding">
          
              <NavCard_Community cardContent={20} cardTitle={'Create Form'} image={createImage} link={"/home/forms/create"} />
              <NavCard_Community cardContent={20} cardTitle={'Forms List'} image={listImage} link={"/home/forms/list/:formsId"} />
              <NavCard_Community cardContent={20} cardTitle={'Submitted Forms'} image={editImage} link={"/home/forms/forms_submitted"} />
              <NavCard_Community cardContent={20} cardTitle={'Form Details'} image={detailImage} link={"/home/forms/detail/:formsId"} />

            </div>
            {/* <div className="cardPadding">
              <DashboardCard cardContent={20} cardTitle={'Organizations'} image={organizationImage} link={""}/>
              <DashboardCard cardContent={40} cardTitle={'Communities'} image={communityImage} link={""}/>
              <DashboardCard cardContent={230} cardTitle={'Management Users'} image={managementUsersImage} link={""}/>
              <DashboardCard cardContent={130} cardTitle={'People Trained'} image={trainedPeopleImage} link={""}/>
            </div> */}
          
            <div className="top">
              <div className="left">
                <div className="item">
                  <img
                    src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"
                    alt=""
                    className="itemImg"
                  />
                  <div style={{
                    "width": "50%",
                    "height": "50px", 
                    "marginTop":"20px", 
                    "marginBottom":"20px", 
                    "marginRight":"10px",  
                    "marginLeft":"10px",
                    }}>
                                
                    <Button 
                      style={{
                        "width": "50%",
                        "height": "50px", 
                        "marginTop":"40px", 
                        "marginBottom":"20px", 
                        "marginRight":"10px",  
                        "marginLeft":"10px",
                      }}
                        size="large"
                        variant="outlined"
                        onClick={() => {
                          alert('clicked');
                        }}
                      >
                      Select Form Type Image
                    </Button>
                  </div>

                </div>

                {/* <hr style={{
                    height: "2px",
                    backgroundColor: '#dddddd',
                    marginTop: '10px',
                    marginBottom: '10px',
                    border: "none",
                }}/> */}

                  <div className="formBox" style={{
                    "marginLeft":"10px", 
                    "border": "0.5px solid lightgray",
                    "padding":"20px", 
                    "borderRadius":"5px",
                    "marginTop":"10px",
                    "marginBottom":"10px",
                  }}>
                  <div className="details" style={{"width":"100%", }}>
                    <h1 className="itemTitle">Form</h1>
                    <hr style={{
                      height: "0.5px",
                      backgroundColor: '#dddddd',
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: "none",
                    }}/>

                    <div style={{"width":"100%", "marginTop": "30px"}}>
                      <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                          const stepProps: { completed?: boolean } = {};
                          const labelProps: {
                            optional?: React.ReactNode;
                          } = {};
                          // if (isStepOptional(index)) {
                          //   labelProps.optional = (
                          //     <Typography variant="caption">Optional</Typography>
                          //   );
                          // }
                          if (isStepSkipped(index)) {
                            stepProps.completed = false;
                          }
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                      {activeStep === steps.length ? (
                        <React.Fragment>
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                          </Typography>
                          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                          </Box>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                          {
                            activeStep === 0 ? 
                              <Box
                                component="form"
                                sx={{
                                  '& > :not(style)': { m: 1 },
                                }}
                                noValidate
                                autoComplete="off">
              
                                <div style={{"width":"100%", "marginTop": "30px"}}>
              
                                    <div 
                                      style={{
                                        "display": "flex", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"20px",  
                                        // "marginBottom":"20px"  
                                      }}
                                    >
              
                                      <FormControl style={{"width": "45%"}}>
                                        <InputLabel htmlFor="component-outlined">Form Name</InputLabel>
                                        <OutlinedInput
                                          id="component-outlined"
                                          value={form_name}
                                          onChange={handleFormNameChange}
                                          label="Form Name"
                                          
                                        />
                                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                                      </FormControl>
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Form Description"
                                          multiline
                                          maxRows={4}
                                          value={value}
                                          onChange={handleMultilineChange}
                                          defaultValue="Default Value"
                                        />
                                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                                      </FormControl>
              
                                    </div>
              
                                    <div 
                                      style={{
                                        "display": "block", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"0px",  
                                        // "marginBottom":"20px"  
                                      }}
                                    >
              
                                      {/* dropdown component */}
                                      <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} error>
                                        <InputLabel id="demo-simple-select-helper-label">Select Form Type</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-helper-label"
                                          id="demo-simple-select-helper-label"
                                          value={form_type}
                                          label="Select Form Type"
                                          onChange={handleDropDownFormType}
                                          renderValue={(value) =>  value }
                                        >
                                          <MenuItem value=""><em>None</em></MenuItem>
                                          <MenuItem value={"Well Beyond Form"}>Well Beyond Form</MenuItem>
                                          <MenuItem value={"Well Aware Form"}>Well Aware Form</MenuItem>
                                          
                                        </Select>
                                        <FormHelperText>Error</FormHelperText>
                                      </FormControl>
              
              
                                      <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} >
                                        <InputLabel id="demo-simple-select-helper-label">Select Form Type Category</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-helper-label"
                                          id="demo-simple-select-helper-label"
                                          value={form_type_category}
                                          label="Select Form Type Category"
                                          onChange={handleDropDownFormTypeCategory}
                                          renderValue={(value) =>  value }
                                        >
                                          <MenuItem value=""><em>None</em></MenuItem>
                                          <MenuItem value={"Misc Reporting"}>Misc Reporting</MenuItem>
                                          <MenuItem value={"Water System"}>Water System</MenuItem>
                                          
                                        </Select>
                                        <FormHelperText>Error</FormHelperText>
                                      </FormControl>
              
                                    </div>
              
                                    <div 
                                      style={{
                                        "display": "block", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"0px",  
                                        // "marginBottom":"20px"  
                                      }}
                                    >
              
                                      {/* dropdown component */}
                                      <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} >
                                        <InputLabel id="demo-simple-select-helper-label">Select Organizations</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-helper-label"
                                          id="demo-simple-select-helper-label"
                                          value={organization_name}
                                          label="Select Organizations"
                                          onChange={handleDropDowOrganizationChange}
                                          renderValue={(value) =>  value }
                                        >
                                          <MenuItem value=""><em>None</em></MenuItem>
                                          <MenuItem value={"well_beyond"}>Well Beyond</MenuItem>
                                          <MenuItem value={"well_aware"}>Well Aware</MenuItem>
                                          <MenuItem value={"princess_aware"}>Princess Aware</MenuItem>
                                        </Select>
                                        <FormHelperText>Error</FormHelperText>
                                      </FormControl>
              
                                      {/* dropdown component */}
                                      <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} >
                                        <InputLabel id="demo-simple-select-helper-label">Select System Type</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-helper-label"
                                          id="demo-simple-select-helper-label"
                                          value={system_type}
                                          label="Select System Type"
                                          onChange={handleDropDownSystemTypeChange}
                                          renderValue={(value) =>  value }
                                        >
                                          <MenuItem value=""><em>None</em></MenuItem>
                                          <MenuItem value={"Bole Hole"}>Bole Hole</MenuItem>
                                          <MenuItem value={"Pipeline System"}>Pipeline System</MenuItem>
                                          <MenuItem value={"Rain Water System"}>Rain Water System</MenuItem>
                                        </Select>
                                        <FormHelperText>Error</FormHelperText>
                                      </FormControl>
              
                                      <FormControlLabel style={{"marginTop":"20px",}}
                                        control={
                                          <Switch
                                            checked={isPublishedChecked}
                                            onChange={handlePublishedChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                          />
                                        } 
                                        label="Publish Form" 
                                      />
              
                                    </div>
              
                                </div>
              
              
                              </Box>
                              : 
                              activeStep === 1 ? 
                              <Box
                                component="form"
                                sx={{
                                  '& > :not(style)': { m: 1 },
                                }}
                                noValidate
                                autoComplete="off">
              
                                <div style={{"width":"100%", "marginTop": "30px"}}>
              
                                    <div 
                                      style={{
                                        "display": "flex", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"20px",  
                                        // "marginBottom":"20px"  
                                      }}
                                    >
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Question One"
                                          multiline
                                          maxRows={4}
                                          value={value}
                                          onChange={handleMultilineChange}
                                          defaultValue="Default Value"
                                        />
                                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                                      </FormControl>
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Question two"
                                          multiline
                                          maxRows={4}
                                          value={value}
                                          onChange={handleMultilineChange}
                                          defaultValue="Default Value"
                                        />
                                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                                      </FormControl>
              
                                    </div>

                                    <div 
                                      style={{
                                        "display": "flex", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"20px",  
                                        // "marginBottom":"20px"  
                                      }}
                                    >
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Question Three"
                                          multiline
                                          maxRows={4}
                                          value={value}
                                          onChange={handleMultilineChange}
                                          defaultValue="Default Value"
                                        />
                                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                                      </FormControl>
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Question Four"
                                          multiline
                                          maxRows={4}
                                          value={value}
                                          onChange={handleMultilineChange}
                                          defaultValue="Default Value"
                                        />
                                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                                      </FormControl>
              
                                    </div>

                                    <div 
                                      style={{
                                        "display": "flex", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"20px",  
                                        // "marginBottom":"20px"  
                                      }}
                                    >
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Question Five"
                                          multiline
                                          maxRows={4}
                                          value={value}
                                          onChange={handleMultilineChange}
                                          defaultValue="Default Value"
                                        />
                                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                                      </FormControl>
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Question Six"
                                          multiline
                                          maxRows={4}
                                          value={value}
                                          onChange={handleMultilineChange}
                                          defaultValue="Default Value"
                                        />
                                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                                      </FormControl>
              
                                    </div>

                                    <div 
                                      style={{
                                        "display": "flex", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"20px",  
                                        // "marginBottom":"20px"  
                                      }}
                                    >
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Question Seven"
                                          multiline
                                          maxRows={4}
                                          value={value}
                                          onChange={handleMultilineChange}
                                          defaultValue="Default Value"
                                        />
                                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                                      </FormControl>
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Question Eight"
                                          multiline
                                          maxRows={4}
                                          value={value}
                                          onChange={handleMultilineChange}
                                          defaultValue="Default Value"
                                        />
                                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                                      </FormControl>
              
                                    </div>

                                    <div 
                                      style={{
                                        "display": "flex", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"20px",  
                                        // "marginBottom":"20px"  
                                      }}
                                    >
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Question Nine"
                                          multiline
                                          maxRows={4}
                                          value={value}
                                          onChange={handleMultilineChange}
                                          defaultValue="Default Value"
                                        />
                                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                                      </FormControl>
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Question Ten"
                                          multiline
                                          maxRows={4}
                                          value={value}
                                          onChange={handleMultilineChange}
                                          defaultValue="Default Value"
                                        />
                                        <FormHelperText id="component-outlined-text">Error</FormHelperText>
                                      </FormControl>
              
                                    </div>
              
                                </div>
              
              
                              </Box>

                              : <Box
                                component="form"
                                sx={{
                                  '& > :not(style)': { m: 1 },
                                }}
                                noValidate
                                autoComplete="off">
            
                                  <div style={{"width":"100%", "marginTop": "30px"}}>
                
                                    <div className="item" style={{
                                      "marginLeft":"20px", 
                                      "border": "0.5px solid lightgray",
                                      "padding":"20px", "borderRadius":"10px",
                                      "marginTop":"10px",
                                      "marginBottom":"10px",
                                    }}>
                                    <div className="details" style={{"width":"100%", }}>
                                      
                                      <div className="detailItem">
                                        <span className="itemKey">Form Name:</span>
                                        <span className="itemValue">Bolehole Services</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Organization Name:</span>
                                        <span className="itemValue" style={{"color":"green", }}>Well Aware Active</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Form Type:</span>
                                        <span className="itemValue" style={{"color":"green", }}>Clovis Bole Hole Services</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Form Category:</span>
                                        <span className="itemValue" style={{"color":"green", }}>Water System</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Community Name:</span>
                                        <span className="itemValue" style={{"color":"green", }}>Ajah Community</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">System Type:</span>
                                        <span className="itemValue" style={{"color":"green", }}>Bole Hole</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Created On:</span>
                                        <span className="itemValue">
                                          30-09-2022
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Completed On:</span>
                                        <span className="itemValue">
                                          30-09-2022
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Form Description:</span>
                                        <span className="itemValue">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span>
                                      </div>
                                      <hr style={{
                                        height: "0.5px",
                                        backgroundColor: '#dddddd',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        border: "none",
                                      }}/>
                                      <div className="detailItem">
                                        <span className="itemKey">Question One:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Two:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Three:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Four:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Five:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Six:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Seven:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Eight:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Nine:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Question Ten:</span>
                                        <span className="itemValue">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </span>
                                      </div>
                                    </div>
                                  </div>
            
                                  <Button 
                                    style={{
                                      "width": "50%",
                                      "height": "50px", 
                                      "marginTop":"40px", 
                                      "marginBottom":"20px", 
                                      "marginRight":"10px",  
                                      "marginLeft":"20px",
                                    }}
                                    size="large"
                                    variant="contained"
                                    onClick={() => {
                                      alert('clicked');
                                    }}
                                  >
                                    Create Form
                                  </Button>
            
                              </div>
            
            
                            </Box> 
                            } 
                          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                              color="inherit"
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                            >
                              Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                              </Button>
                            )}
                            <Button onClick={handleNext}>
                              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                          </Box>
                        </React.Fragment>
                      )}
                    </div>
                    
                  </div>
                </div>

              </div>
            </div>
      

          </div>
        </div>
       
      </Main>
    </Box>
  );
  
};

export default Create_Forms;