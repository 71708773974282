import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { Community, CommunitySystemTypesList, MaintenanceChecklist, Maintenance_Checklist_Steps, Maintenance_Checklist_response } from "../../screens/authentication/authentication_interfaces";
import { useEffect, useState } from "react";

//creating a props interface for the table components types
interface tableProps {
  data: Maintenance_Checklist_response [] | null;
//   handleUpdatedMaintenanceChecklist: (checklist: MaintenanceChecklist) => void;
}

const MaintenanceChecklistResponseTable : React.FC<tableProps> = ({data}) => {

    const [maintenanceChecklist, setMaintenanceChecklist] = useState<MaintenanceChecklist>();

    // useEffect(() => {
    //     handleUpdatedMaintenanceChecklist(maintenanceChecklist!)
    // }, [maintenanceChecklist]);

    const handleViewMaintenanceChecklist = (maintenanceChecklist: MaintenanceChecklist) => {
        setMaintenanceChecklist(maintenanceChecklist);
    };


    return (
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Step ID</TableCell>
              <TableCell className="tableCell">Question</TableCell>
              <TableCell className="tableCell">Instruction</TableCell>
              <TableCell className="tableCell">Answer</TableCell>
              <TableCell className="tableCell">Additional Comment</TableCell>
              {/* <TableCell className="tableCell">Images/Videos</TableCell> */}
              <TableCell className="tableCell">Completed Date</TableCell>
              <TableCell className="tableCell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data!.map((row) => (
              <TableRow key={row.community_id}>
                <TableCell className="tableCell">{row.maintenance_checklist_step_id}</TableCell>
                <TableCell className="tableCell">{row.question}</TableCell>
                <TableCell className="tableCell">{row.instructions}</TableCell>
                <TableCell className="tableCell">{row.answer}</TableCell>
                <TableCell className="tableCell">{row.additional_comment}</TableCell>
                {/* <TableCell className="tableCell">{row.maintenance_checklist_files.length !== 0 ? row.maintenance_checklist_files!.map((files)=> { 
                    return  <div style={{display:"flex"}}>
                            {
                                files.resource_type == "image" ?
                                <img
                                    src={files.url}
                                    alt=""
                                    className="itemImg"
                                /> :  
                                <video width="320" height="240" controls>
                                    <source src={files.url} type="video/mp4"/> 
                                </video>
                            }
                        </div> 
                    }) : "No System Type"}
                </TableCell> */}
                <TableCell className="tableCell">{row.date_completed}</TableCell>
                <TableCell className="tableCell">
                  <Link to={`/home/maintenance_checklist/response_details/${row.maintenance_checklist_response_id}`} style={{ textDecoration: "none", marginTop: "10px", }}>
                    <span className="hasAccount"><Button 
                        style={{
                          "width": "100%",
                          "fontSize":"10px",
                        }}
                        startIcon={<SendIcon />}
                        size="small"
                        variant="contained"
                        // onClick={handleSubmit}
                    >
                      View Details
                    </Button></span>
                  </Link>
                  {/* <span className="hasAccount">
                      <Button 
                          style={{
                            "fontSize":"10px",
                            "marginTop":"5px", 
                          }}
                          startIcon={<DeleteIcon />}
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={()=> handleViewMaintenanceChecklist(row.maintenanceChecklist)}
                      >
                        View Details
                      </Button>
                    </span> */}
                  
                  {/* <Button variant="contained">Contained</Button> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
}

export default MaintenanceChecklistResponseTable;