import Custom_Text from "../../components/custom_text";
import './user_management.scss'
import SideBarUserManagement from './sidebar_user_management/sidebaruser_management';
import NavBarAdmin from "../../components/navbar/navbar_admin";
import SectionHeader from "../../components/custom_text/section_header";
import TableList from "../../components/table/User_Table";
import DashboardCard from "../../components/card/card";
import DashboardPieChart from "../../components/dashboard_cards/dashboard_card_pie";
import Sidebar from "../../components/sidebars/sidebar";
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';
import React, { useEffect, useState } from "react";

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import logoImage from './images/logo.png';

import pendingForms from './images/pending_forms.png';
import allForms from './images/all.png';
import submitted_forms from './images/submitted_forms.png';
import axios from 'axios'

const drawerWidth = 240;

export interface User {
  user_id: number;
  organization_id: number;
  user_token: string;
  firebase_token: string;
  profile_image?: any;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  role: string;
  phone_number: string;
  management_role: string;
  reset_password_expiry_time: string;
  reset_password_token?: any;
  isPhoneVerified: boolean;
  isEmailVerifed: boolean;
  active: boolean;
  accepted_terms: boolean;
  suspended: boolean;
  updated_by: number;
  created_by: number;
  created_at: Date;
  updated_at: Date;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const User_Management : React.FC = () => {

  
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

    const individualsTrainedPerSubjectBarData = {
        labels: ['today', 'yesterday', 'tomorrow', "next week"],
        datasets: [
          {
            label: 'Individuals Trained per Subject',
            backgroundColor: "#4EC7F2",
            borderColor: "#79B82C",
            borderWidth: 2,
            data: [34, 2, 9, 100]
          }
        ]
      }
      
      const communitySystemStatusPieData = {
        labels:["Functioning", "Maintenance Checklist Overdue", "Unresolved Diagnostics", "Maintenance & Diagnostic needs"],
        datasets:[{
         data: [300, 50, 100, 40],
         backgroundColor: [
            "#79B82C",
            "#4EC7F2",
            "#FFC870",
            "#A8B3C5",
            "#616774"
          ],
         borderWidth: 1
        }]
      };
      
      const maintenanceStatusPieData = {
        labels:["Compliant", "Overdue"],
        datasets:[{
         data: [300, 50],
         backgroundColor: [
            "#79B82C",
            "#FFB200"
          ],
         borderWidth: 1
        }]
      };
      
      const diagnosticStatusPieData = {
        labels:["Issue Reported", "Under Review", "Functioning", "Pending Maintenace", "Contact Community"],
        datasets:[{
         data: [300, 50, 100, 40, 20],
         backgroundColor: [
            "#79B82C",
            "#FFB200",
            "#5AD3D1",
            "#531CBC",
            "#FF0000",
          ],
         borderWidth: 1
        }]
      };

    let handleChange = (event : any) => {}
    let handleSubmit = (event : any) => {}

    const [user_list_data, setUserListData] = useState<User[] | null>([]);
    console.log({user_list_data})
    useEffect(() => {
      getData();
    }, []);
    //Api calls
    const getData = async () => {
      const userResponse = await axios.get(`http://api.wellbeyondwater.com:8000/api/user`).then((response) => {
        console.log('API call response status ' + response.status);
        setUserListData(response.data.data);
        console.log('Community Name ' + response['data']['data'][0]['name']);
        // console.log('Organization Name ' + userResponse['data']['status']);
      }).catch((err) => {
        console.log(err)
      })
    };

    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
  
            <Link to="/home" style={{ textDecoration: "none" }}>
                  {/* <span className='logo'>WellBeyond</span> */}
                  <img
                      src={logoImage}
                      alt=""
                      className="itemImg"
                  />
              </Link>
          </Toolbar>
          
          <NavBarAdmin/>
  
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? 
              <img
                src={logoImage}
                alt=""
                className="itemImg"
                  
              /> : 
              <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Sidebar />
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <div className="home">

            <div className="homeContainer">

            <SectionHeader sectionTitle={'USERS'} link='' />
              <div className="cardPadding">
                <NavCard_Community cardContent={20} cardTitle={'Create User'} image={createImage} link={"/home/user_management/create"} />
                <NavCard_Community cardContent={20} cardTitle={'Create Client'} image={createImage} link={"/home/user_management/create_client"} />
                <NavCard_Community cardContent={20} cardTitle={'Create Super Admin'} image={createImage} link={"/home/user_management/create_super_admin"} />
              </div>

              <div className="cardPadding">
                <NavCard_Community cardContent={20} cardTitle={'Super Admins'} image={listImage} link={"/home/user_management/list/super_admins"} />
                <NavCard_Community cardContent={20} cardTitle={'Clients'} image={listImage} link={"/home/user_management/list/clients"} />
                <NavCard_Community cardContent={20} cardTitle={'Management User'} image={listImage} link={"/home/user_management/list/users"} />

              </div>
              <div className="cardPadding">
                <NavCard_Community cardContent={20} cardTitle={'Edit Super Admin'} image={editImage} link={"/home/user_management/edit/super_admin/:user_id"} />
                <NavCard_Community cardContent={20} cardTitle={'Edit Client'} image={editImage} link={"/home/user_management/edit/client/:user_id"} />
                {/* <NavCard_Community cardContent={20} cardTitle={'Edit User'} image={editImage} link={"/home/profile/edit/:profileId"} /> */}
                <NavCard_Community cardContent={20} cardTitle={'Edit Management User'} image={editImage} link={"/home/user_management/edit/user/:user_id"} />
                <NavCard_Community cardContent={20} cardTitle={'User Details'} image={detailImage} link={"/home/profile"} />
              </div>
            <div className="cardPadding">
              {/* <DashboardCard cardContent={20} cardTitle={'Total Users'} image={organizationImage} link={""}/>
              <DashboardCard cardContent={40} cardTitle={'Communities'} image={communityImage} link={""}/> */}
              {/* <DashboardCard cardContent={230} cardTitle={'Total Client Users'} image={managementUsersImage} link={""}/> */}
              <DashboardCard cardContent={user_list_data?.length} cardTitle={'Total Management Users'} image={managementUsersImage} link={""}/>
            </div>

            {/* <SectionHeader sectionTitle={'SYSTEM OVERVIEW'} link='' />
            <div className="dashboardPieChartContainer">
                <DashboardPieChart data={communitySystemStatusPieData} title='Community System Status' />
                <DashboardPieChart data={maintenanceStatusPieData} title='Maintenance Status' />
                <DashboardPieChart data={diagnosticStatusPieData} title='Diagnostic Status' />
            </div> */}

            <SectionHeader sectionTitle={'LISTS'} link='' />
            <div className="listContainer">
                <div className="listTitle">Users</div>
                <TableList data={user_list_data}/>
            </div>

            </div>
          </div>
         
        </Main>
      </Box>
    );
  
};

export default User_Management;