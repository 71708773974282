import React, { useEffect, useState } from "react";
import Custom_Text from "../../components/custom_text";
import './sign_up.css'
import { Link } from "react-router-dom";
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { InputLabel, Modal } from "@mui/material";
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import image from './images/logo.png';
import Auth_Services from "./authentication_services";
import { User } from "./authentication_interfaces";


const style = {
   position: 'absolute' as 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 400,
   bgcolor: 'background.paper',
   border: '1px solid #000',
   boxShadow: 24,
   pt: 2,
   px: 4,
   pb: 3,
};

const Login : React.FC = () => {

   const [password, setPassword] = useState<string>('');
   const [email, setEmail] = useState<string>('');
   const [firstName, setfirstName] = useState<string>();
   const [lastName, setlastName] = useState<string>();
   const [bearerToken, setBearerToken] = useState<string>();
   const [userId, setUserId] = useState([]);
   const [error, setUserError] = React.useState(false);
   const [open, setOpen] = React.useState(false);
   const [errorMessage, setErrorMessage] = React.useState();
   const handleOpen = () => {
      setOpen(true);
   };
   const handleClose = () => {
      setOpen(false);
   };
   
   const [openErrorModal, setopenErrorModal] = React.useState(false);
   const handleErrorClose  = () => {
      setopenErrorModal(false);
   };

   const handleUserPassword= (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
   };

   const handleUserEmail= (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
   };

   let loginData = { 
      email: email, 
      password: password 
   };

   useEffect(() => {
      localStorage.setItem('userId', JSON.stringify(userId));
      localStorage.setItem('user_fullName', JSON.stringify(`${firstName+ " " +lastName}`));
      localStorage.setItem('bearerToken', JSON.stringify(bearerToken));
   }, [userId, firstName, lastName, bearerToken ]);

      //save Api call response state management
      const [data, setData] = useState<User | null>();

      let handleSubmit = (event : any) => {
         event.persist();
         console.log(loginData);
         console.log("Login event fired");

         // PostData();
         Auth_Services.login(email, password).then((response) => {

            setData(response.data.data);
            const status = response['data']['status'];
            const message = response['data']['message'];
            const bearerToken = response['data']['bearerToken'];
            const accepted_terms = response['data']['data']["accepted_terms"];

            const firstName = response['data']['data']["first_name"];
            const LastName = response['data']['data']["last_name"];
            const userId = response['data']['data']["user_id"];

            console.log('Login Status ' + status);
            console.log('User Bearer Token ' + bearerToken);
            console.log('Message ' + message);
            console.log('Set Data ' + data?.accepted_terms);

            if(accepted_terms){

               setfirstName(firstName);
               setlastName(LastName);
               setUserId(userId);
               setBearerToken(bearerToken);
               setOpen(true);

            }
            
         }).catch(function (error) {

            setErrorMessage(error.toString());
            console.log('error ' + error.toJSON());
            console.log('error message ' + error.toString());
            setopenErrorModal(true);
   
      });

   };

   const PostData = async () => {
      const userResponse = await axios.post(`http://api.wellbeyondwater.com:8000/api/user/login`, loginData);
      // console.log('API call response status ' + userResponse.status);
      setData(userResponse.data.data);
      const status = userResponse['data']['status'];
      const message = userResponse['data']['message'];
      const bearerToken = userResponse['data']['bearerToken'];
      const accepted_terms = userResponse['data']['data']["accepted_terms"];

      const firstName = userResponse['data']['data']["first_name"];
      const LastName = userResponse['data']['data']["last_name"];
      const userId = userResponse['data']['data']["user_id"];

      console.log('Login Status ' + status);
      console.log('User Bearer Token ' + bearerToken);
      console.log('Message ' + message);
      console.log('Set Data ' + data?.accepted_terms);

      if(accepted_terms){

         setfirstName(firstName);
         setlastName(LastName);
         setUserId(userId);
         setBearerToken(bearerToken);
         setOpen(true);

      }
   };
  
  return (
    <div className='wrapper'>
         <div className='form-wrapper'>
            {/* <Custom_Text/> */}
            <h2 style={{"textAlign": "center", "marginTop": "40px"}}>
               <div className='imageContainer'>
                  <img className='image' src={image} alt="Logo" />
               </div>
            </h2>
            <h2>Login</h2>
            <form  >
               <div className='email'>
                  <FormControl style={{"width": "100%", marginTop: "40px"}}>
                     <InputLabel htmlFor="component-outlined">User Email</InputLabel>
                     <OutlinedInput
                        id="component-outlined"
                        value={email}
                        onChange={handleUserEmail}
                        label="User Email"
                        type='email'
                     />
                     {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                  </FormControl>
               </div>
               <div className='password'>
                  <FormControl style={{"width": "100%", marginTop: "20px"}}>
                     <InputLabel htmlFor="component-outlined">Password</InputLabel>
                     <OutlinedInput
                        id="component-outlined"
                        value={password}
                        onChange={handleUserPassword}
                        label="Password"
                        type='password'                     
                     />
                     {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                  </FormControl>
               </div>              
               <Button 
                  style={{
                     "width": "100%",
                     "height": "50px", 
                     "marginTop":"40px", 
                     "marginBottom":"20px", 
                     "marginRight":"0px",  
                     "marginLeft":"0px",
                  }}
                  size="large"
                  variant="contained"
                  onClick={handleSubmit}
               >
                  Login
               </Button>
               <Link to="/" style={{ textDecoration: "none", marginTop: "20px", }}>
                    <span className="hasAccount">Don't have an Account? <h2 className="login">Register</h2> </span>
               </Link>
          </form>
      </div>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
         <Box sx={{ ...style, width: 250 }}>
         <Button onClick={handleClose}>x</Button>
            <h3 id="child-modal-title" style={{textAlign: "center"}}>Login Successful</h3>
            <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
               Welcome
            </p>
            <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{firstName} {lastName}</h2>
            <Link to="/home" style={{ textDecoration: "none", marginTop: "10px", }}>
               <span className="hasAccount"><Button 
                  style={{
                     "width": "100%",
                     "height": "50px", 
                     "marginTop":"20px", 
                     "marginBottom":"20px", 
                     "marginRight":"10px",  
                     "marginLeft":"20px",
                  }}
                  size="large"
                  variant="contained"
                  onClick={handleSubmit}
               >
                  Proceed
               </Button></span>
            </Link>
         </Box>
      </Modal>

         <Modal
            hideBackdrop
            open={openErrorModal}
            onClose={handleErrorClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 550 }}>
            <Button onClick={handleErrorClose}>x</Button>
                <h3 id="child-modal-title" style={{textAlign: "center"}}>Login Failed</h3>
                <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
                  {errorMessage}
                </p>
                <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{firstName} {lastName}</h2>
                <span className="hasAccount"><Button 
                      style={{
                        "width": "100%",
                        "height": "50px", 
                        "marginTop":"20px", 
                        "marginBottom":"20px", 
                        "marginRight":"10px",  
                        "marginLeft":"20px",
                      }}
                      size="large"
                      variant="contained"
                      onClick={handleErrorClose}
                  >
                      Try Again
                  </Button></span>
            </Box>
         </Modal>
   </div>
  );
};

export default Login;