import React, { useEffect, useState } from "react";
import Custom_Text from "../../components/custom_text";
import './sign_up.css'
import { Link } from "react-router-dom";
import { green } from "@mui/material/colors";

import { Button, SelectChangeEvent, Step, Stepper, StepLabel, Chip, TextField, FormControlLabel, Switch, Modal } from '@mui/material';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import Administrator from '../administrator/administrator';
import image from './images/logo.png';
import axios from 'axios';
import Auth_Services from "./authentication_services";
import { Organization } from "./authentication_interfaces";
import Organization_Services from "../organization/organization_services";

// import IUserResponseData from '../../types/datatypes.type';
// import Organization from '../../types/datatypes.type';

 
 const style = {
   position: 'absolute' as 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 400,
   bgcolor: 'background.paper',
   border: '1px solid #000',
   boxShadow: 24,
   pt: 2,
   px: 4,
   pb: 3,
};

const steps = ['Create User Details', 'Create User Address', 'Confirm User Details'];


const SignUp : React.FC = () => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [isPublishedChecked, setPublishedChecked] = React.useState(false);
    const [organization_name, setOrganization] = React.useState('');
    const [adminRole, setAdminRole] = React.useState('');
   //  const [managementRole, setManagementRole] = React.useState<string[]>([]);
   const [managementRole, setManagementRole] = React.useState<string>("All Access Management");
    const [error, setUserError] = React.useState(false);

    //setState for the input field
    const [last_name, setUserLastName] = React.useState('');
    const [first_name, setUserFirstName] = React.useState('');
    const [userEmail, setUserEmail] = React.useState('');
    const [phone_number, setUserPhoneNumber] = React.useState('');
    const [password, setUserPassword] = React.useState('');
    const [confirm_password, setUserConfirmPassword] = React.useState('');
    const [street_address, setUserAddress] = React.useState('');
    const [city, setUserCity] = React.useState('');
    const [postal_code, setUserPostalCode] = React.useState('');

    const [country, setUserCountry] = React.useState('');
    const [longitude, setUserLongitude] = React.useState('');
    const [latitude, setUserLatitude] = React.useState('');
    const [organization_id, setOrganizationId] = React.useState<number>();

    const [openModal, setOpenModal] = React.useState(false);
    const [openErrorModal, setopenErrorModal] = React.useState(false);
    const handleOpen = () => {
      setOpenModal(true);
    };
    const handleClose = () => {
      setOpenModal(false);
    };

    const handleErrorClose  = () => {
      setopenErrorModal(false);
    };

   //methods to handle input validations
   const handleUserFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserFirstName(event.target.value);
   };

   const handleUserLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserLastName(event.target.value);
   };

   const handleUserPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
      setUserPhoneNumber(event.target.value);
   };

   const handleUserEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserEmail(event.target.value);
   };

   const handleUserPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
      setUserPassword(event.target.value);
   };

   const handleUserConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
      setUserConfirmPassword(event.target.value);
   };

   const handleUserAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserAddress(event.target.value);
   };

   const handleUserCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserCity(event.target.value);
   };

   const handleUserPostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserPostalCode(event.target.value);
   };

   const handleUserCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserCountry(event.target.value);
   };

   const handleUserLongitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserLongitude(event.target.value);
   };

   const handleUserLatitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserLatitude(event.target.value);
   };

   //dropdown methods
    const handleDropDowOrganizationChange = (event: SelectChangeEvent) => {
      setOrganization(event.target.value);
      console.log(organization_name);
      
    };
  
    const handleDropDownAdminRoleChange = (event: SelectChangeEvent) => {
      setAdminRole(event.target.value);
    };
  
    const isStepOptional = (step: number) => {
      return step === 1;
    };
  
    const isStepSkipped = (step: number) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
  
    const handlePublishedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPublishedChecked(event.target.checked);
      console.log(isPublishedChecked);
    };
  
   //Api call state management
   // const [data, setData] = useState<Organization[] | null>();
   const [errorMessage, setErrorMessage] = React.useState();
   const [organizationData, setOrganizationData] = React.useState<Organization[] | null>();
   const [display_organization_name, setDisplayOrganizationName] = React.useState<string>('');
   const [display_organization_id, setDisplayOrganizationId] = React.useState<number>(0);

   useEffect(() => {
      getOrganizationName(organization_name);
      Organization_Services.getAllOrganizationsDropDownList().then((response) => {
         console.log('API call response status ' + response.status);
         setOrganizationData(response.data.data);
         console.log('Organization Name ' + response['data']['data'][0]['name']);
         console.log('Organization Status ' + response['data']['status']);
      });
    }, [organization_name]);
  
   function getOrganizationName(str: string) {
      var values = str.split(" ");
      var id = values.shift();
      var name = values.join(' ');
      setDisplayOrganizationName(name);
      setDisplayOrganizationId(parseInt(id!));


      console.log("Organization Id " + id);
      console.log("Organization Name " + name);
   }

   let handleSubmit = (event : any) => {
      event.persist();
      console.log("Create user and address event fired");
      Auth_Services.createSuperAdminPost(display_organization_id, "profile_image", first_name, last_name, userEmail, password,
         adminRole, phone_number, managementRole, isPublishedChecked, true, true, true, true, true, true, )
         .then((response) => {

            const status = response['data']['status'];
            const message = response['data']['message'];
            const userId = response['data']['data']["user_id"];
            console.log("user id " + userId);

            console.log('Create super admin user Status ' + status);
            console.log('Message ' + message);

            if(status === "Success"){

               Auth_Services.createSuperAdminAddress(userId, street_address, city, postal_code, country )
               .then((response) => {
                 const status = response['data']['status'];
                 const message = response['data']['message'];
                 const addressData = response['data']['data'];
                 console.log("create address response " + addressData)
                 console.log('Create address Status ' + status);
                 console.log('Message ' + message);
                 setOpenModal(true);
               });
               
            }

         }).catch(function (error) {

            setErrorMessage(error.toString());
            console.log('error ' + error.toJSON());
            console.log('error message ' + error.toString());
            setopenErrorModal(true);
    
      });

   };
  
  return (
    <div className='' style={{"backgroundColor": "white"}}>
         <div className='' 
            style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // height: '100vh',
         }}>
            

            <div style={{"width": "80%",}}>
               {/* <Custom_Text/> */}
            
               <h2 style={{"textAlign": "center", "marginTop": "40px"}}>
                  <div className='imageContainer'>
                     <img className='image' src={image} alt="Logo" />
                  </div>
               </h2>
               <h2 style={{"textAlign": "center", "marginTop": "40px",}}>Create An Administrator Account</h2>
               <form onSubmit={handleSubmit} noValidate >
               <div className="top">
                  <div className="left">
                     <div className="item">
                        <img
                           src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"
                           alt=""
                           className="itemImg"
                        />
                        <div style={{
                           "width": "50%",
                           "height": "50px", 
                           "marginTop":"20px", 
                           "marginBottom":"20px", 
                           "marginRight":"10px",  
                           "marginLeft":"10px",
                           }}>
                                       
                           <Button 
                              style={{
                              "width": "50%",
                              "height": "50px", 
                              "marginTop":"40px", 
                              "marginBottom":"20px", 
                              "marginRight":"10px",  
                              "marginLeft":"10px",
                              }}
                              size="large"
                              variant="outlined"
                              onClick={() => {
                                 alert('clicked');
                              }}
                              >
                              Select User Image
                           </Button>
                        </div>

                           </div>

                           {/* <hr style={{
                              height: "2px",
                              backgroundColor: '#dddddd',
                              marginTop: '10px',
                              marginBottom: '10px',
                              border: "none",
                           }}/> */}

                           <div className="formBox" style={{
                              "marginLeft":"10px", 
                              "border": "0.5px solid lightgray",
                              "padding":"20px", 
                              "borderRadius":"5px",
                              "marginTop":"10px",
                              "marginBottom":"10px",
                           }}>
                           <div className="details" style={{"width":"100%", }}>
                              <h1 className="itemTitle">Super Admin User</h1>
                              <hr style={{
                                 height: "0.5px",
                                 backgroundColor: '#dddddd',
                                 marginTop: '10px',
                                 marginBottom: '10px',
                                 border: "none",
                              }}/>

                              <div style={{"width":"100%", "marginTop": "30px"}}>
                                 <Stepper activeStep={activeStep}>
                                 {steps.map((label, index) => {
                                    const stepProps: { completed?: boolean } = {};
                                    const labelProps: {
                                       optional?: React.ReactNode;
                                    } = {};
                                    // if (isStepOptional(index)) {
                                    //   labelProps.optional = (
                                    //     <Typography variant="caption">Optional</Typography>
                                    //   );
                                    // }
                                    if (isStepSkipped(index)) {
                                       stepProps.completed = false;
                                    }
                                    return (
                                       <Step key={label} {...stepProps}>
                                          <StepLabel {...labelProps}>{label}</StepLabel>
                                       </Step>
                                    );
                                 })}
                                 </Stepper>
                                 {activeStep === steps.length ? (
                                 <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                       All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                       <Box sx={{ flex: '1 1 auto' }} />
                                       <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                 </React.Fragment>
                                 ) : (
                                 <React.Fragment>
                                    {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                                    {
                                       activeStep === 0 ? 
                                       <Box
                                          component="form"
                                          sx={{
                                             '& > :not(style)': { m: 1 },
                                          }}
                                          noValidate
                                          autoComplete="off">
                        
                                          <div style={{"width":"100%", "marginTop": "30px"}}>

                                             <div 
                                                style={{
                                                   "display": "flex", 
                                                   "justifyContent": "space-between", 
                                                   "marginRight":"10px",  
                                                   "marginLeft":"10px",
                                                   "marginTop":"20px",  
                                                   // "marginBottom":"20px"  
                                                }}
                                             >
                        
                                                <FormControl style={{"width": "45%"}} required>
                                                   <InputLabel htmlFor="component-outlined" required>First Name</InputLabel>
                                                   <OutlinedInput
                                                   id="component-outlined"
                                                   value={first_name}
                                                   onChange={handleUserFirstNameChange}
                                                   label="First Name"
                                                   type="name"
                                                   
                                                   />
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                                <FormControl style={{"width": "45%"}}>
                                                   <InputLabel htmlFor="component-outlined">Last Name</InputLabel>
                                                   <OutlinedInput
                                                   id="component-outlined"
                                                   value={last_name}
                                                   onChange={handleUserLastNameChange}
                                                   label="Last Name"
                                                   type="name"
                                                   
                                                   />
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                             </div>
                        
                                             <div 
                                                style={{
                                                   "display": "flex", 
                                                   "justifyContent": "space-between", 
                                                   "marginRight":"10px",  
                                                   "marginLeft":"10px",
                                                   "marginTop":"20px",  
                                                   // "marginBottom":"20px"  
                                                }}
                                             >
                        
                                                <FormControl style={{"width": "45%"}}>
                                                   <InputLabel htmlFor="component-outlined">Phone Number</InputLabel>
                                                   <OutlinedInput
                                                   id="component-outlined"
                                                   value={phone_number}
                                                   onChange={handleUserPhoneNumberChange}
                                                   label="Phone Number"
                                                   type="phone"
                                                   
                                                   />
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                                <FormControl style={{"width": "45%"}}>
                                                   <InputLabel htmlFor="component-outlined">User Email</InputLabel>
                                                   <OutlinedInput
                                                   id="component-outlined"
                                                   value={userEmail}
                                                   onChange={handleUserEmailChange}
                                                   label="User Email"
                                                   type="email"
                                                   
                                                   />
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                             </div>

                                             <div 
                                                style={{
                                                   "display": "flex", 
                                                   "justifyContent": "space-between", 
                                                   "marginRight":"10px",  
                                                   "marginLeft":"10px",
                                                   "marginTop":"20px",  
                                                   // "marginBottom":"20px"  
                                                }}
                                             >
                        
                                                <FormControl style={{"width": "45%"}}>
                                                   <InputLabel htmlFor="component-outlined">Password</InputLabel>
                                                   <OutlinedInput
                                                   id="component-outlined"
                                                   value={password}
                                                   onChange={handleUserPasswordChange}
                                                   label="Password"
                                                   type="password"
                                                   
                                                   />
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                                <FormControl style={{"width": "45%"}}>
                                                   <InputLabel htmlFor="component-outlined">Confirm Password</InputLabel>
                                                   <OutlinedInput
                                                   id="component-outlined"
                                                   value={confirm_password}
                                                   onChange={handleUserConfirmPasswordChange}
                                                   label="Confirm Password"
                                                   type="password"
                                                   
                                                   />
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                             </div>

                                             <div 
                                                style={{
                                                   "display": "block", 
                                                   "justifyContent": "space-between", 
                                                   "marginRight":"10px",  
                                                   "marginLeft":"10px",
                                                   "marginTop":"0px",  
                                                   // "marginBottom":"20px"  
                                                }}
                                                >
                        
                                                {/* dropdown component */}
                                                <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} >
                                                   <InputLabel id="demo-simple-select-helper-label">Select Organizations</InputLabel>
                                                   <Select
                                                   labelId="demo-simple-select-helper-label"
                                                   id="demo-simple-select-helper-label"
                                                   value={organization_name.slice(1)}
                                                   label="Select Organizations"
                                                   onChange={handleDropDowOrganizationChange}
                                                   renderValue={(value) =>  value }
                                                   >
                                                   {/* <MenuItem value=""><em>None</em></MenuItem> */}
                                                   {organizationData ? organizationData.map((organizations) => {
                                                      return <MenuItem value={`${organizations.organization_id} ${organizations.name}`}>{organizations.name}</MenuItem>
                                                   }) : null}
                                                   {/* <MenuItem value={"well_beyond"}>Well Beyond</MenuItem>
                                                   <MenuItem value={"well_aware"}>Well Aware</MenuItem>
                                                   <MenuItem value={"princess_aware"}>Princess Aware</MenuItem> */}
                                                   </Select>
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                                {/* dropdown component */}
                                                <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} >
                                                   <InputLabel id="demo-simple-select-helper-label">Select Administrator Role</InputLabel>
                                                   <Select
                                                   labelId="demo-simple-select-helper-label"
                                                   id="demo-simple-select-helper-label"
                                                   value={adminRole}
                                                   label="Select Administrator Role"
                                                   onChange={handleDropDownAdminRoleChange}
                                                   renderValue={(value) =>  value }
                                                   >
                                                   <MenuItem value=""><em>None</em></MenuItem>
                                                   <MenuItem value={"Super Admin"}>Super Administrator</MenuItem>
                                                   {/* <MenuItem value={"Admin"}>Administrator</MenuItem>
                                                   <MenuItem value={"Management Admin"}>Management Admin</MenuItem> */}
                                                   </Select>
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>

                                                {/* <FormControl sx={{ m: 0, minWidth: 300 }} style={{"width": "100%", "marginTop":"20px",}}>
                                                   <InputLabel id="demo-multiple-chip-label">Select Communities</InputLabel>
                                                   <Select
                                                   labelId="demo-multiple-chip-label"
                                                   id="demo-multiple-chip"
                                                   multiple
                                                   value={communities}
                                                   onChange={handleDropDownCommunityChange}
                                                   input={<OutlinedInput id="select-multiple-chip" label="Select Communities" />}
                                                   renderValue={(selected) => (
                                                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                         {selected.map((value) => (
                                                         <Chip key={value} label={value} />
                                                         ))}
                                                      </Box>
                                                   )}
                                                   MenuProps={MenuProps}
                                                   >
                                                   {CommunitiesData.map((community) => (
                                                      <MenuItem
                                                         key={community}
                                                         value={community}
                                                         style={getStyles(community, communities, theme)}
                                                      >
                                                         {community}
                                                      </MenuItem>
                                                   ))}
                                                   
                                                   </Select>
                                                </FormControl> */}

                                                {/* <FormControl sx={{ m: 0, minWidth: 300 }} style={{"width": "100%", "marginTop":"20px",}}>
                                                   <InputLabel id="demo-multiple-chip-label">Select Management Role</InputLabel>
                                                   <Select
                                                   labelId="demo-multiple-chip-label"
                                                   id="demo-multiple-chip"
                                                   multiple
                                                   value={managementRole}
                                                   onChange={handleDropDownManagementRoleChange}
                                                   input={<OutlinedInput id="select-multiple-chip" label="Select Management Role" />}
                                                   renderValue={(selected) => (
                                                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                         {selected.map((value) => (
                                                         <Chip key={value} label={value} />
                                                         ))}
                                                      </Box>
                                                   )}
                                                   MenuProps={MenuProps}
                                                   >
                                                   {managementRoleData.map((name) => (
                                                      <MenuItem
                                                         key={name}
                                                         value={name}
                                                         style={getStyles(name, managementRole, theme)}
                                                      >
                                                         {name}
                                                      </MenuItem>
                                                   ))}
                                                   </Select>
                                                </FormControl> */}
                        
                                             </div>
                        
                        
                                          </div>
                        
                        
                                       </Box>
                                       : 
                                       activeStep === 1 ? 
                                       <Box
                                          component="form"
                                          sx={{
                                             '& > :not(style)': { m: 1 },
                                          }}
                                          noValidate
                                          autoComplete="off">
                        
                                          <div style={{"width":"100%", "marginTop": "30px"}}>
                        
                                             <div 
                                                style={{
                                                   "display": "flex", 
                                                   "justifyContent": "space-between", 
                                                   "marginRight":"10px",  
                                                   "marginLeft":"10px",
                                                   "marginTop":"20px",  
                                                   // "marginBottom":"20px"  
                                                }}
                                             >
                        
                                                <FormControl style={{"width": "45%"}}>
                                                   <TextField
                                                   id="outlined-multiline-static"
                                                   label="Street Address"
                                                   multiline
                                                   maxRows={4}
                                                   value={street_address}
                                                   onChange={handleUserAddressChange}
                                                   defaultValue="Default Value"
                                                   type="address"
                                                   />
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                                <FormControl style={{"width": "45%"}}>
                                                   <TextField
                                                   id="outlined-multiline-static"
                                                   label="City"
                                                   multiline
                                                   maxRows={4}
                                                   value={city}
                                                   onChange={handleUserCityChange}
                                                   defaultValue="Default Value"
                                                   type="city"
                                                   />
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                             </div>

                                             <div 
                                                style={{
                                                   "display": "flex", 
                                                   "justifyContent": "space-between", 
                                                   "marginRight":"10px",  
                                                   "marginLeft":"10px",
                                                   "marginTop":"20px",  
                                                   // "marginBottom":"20px"  
                                                }}
                                             >
                        
                                                <FormControl style={{"width": "45%"}}>
                                                   <TextField
                                                   id="outlined-multiline-static"
                                                   label="Postal Code"
                                                   multiline
                                                   maxRows={4}
                                                   value={postal_code}
                                                   onChange={handleUserPostalCodeChange}
                                                   defaultValue="Default Value"
                                                   type="postal"
                                                   />
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                                <FormControl style={{"width": "45%"}}>
                                                   <TextField
                                                   id="outlined-multiline-static"
                                                   label="Country"
                                                   multiline
                                                   maxRows={4}
                                                   value={country}
                                                   onChange={handleUserCountryChange}
                                                   defaultValue="Default Value"
                                                   type="country"
                                                   />
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                             </div>

                                             {/* <div 
                                                style={{
                                                   "display": "flex", 
                                                   "justifyContent": "space-between", 
                                                   "marginRight":"10px",  
                                                   "marginLeft":"10px",
                                                   "marginTop":"20px",  
                                                   // "marginBottom":"20px"  
                                                }}
                                             >
                        
                                                <FormControl style={{"width": "45%"}}>
                                                   <TextField
                                                   id="outlined-multiline-static"
                                                   label="Longitude"
                                                   multiline
                                                   maxRows={4}
                                                   value={longitude}
                                                   onChange={handleUserLongitudeChange}
                                                   defaultValue="Default Value"
                                                   type="longitude"
                                                   />
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                                <FormControl style={{"width": "45%"}}>
                                                   <TextField
                                                   id="outlined-multiline-static"
                                                   label="Latitude"
                                                   multiline
                                                   maxRows={4}
                                                   value={latitude}
                                                   onChange={handleUserLatitudeChange}
                                                   defaultValue="Default Value"
                                                   type="latitude"
                                                   />
                                                   {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                                </FormControl>
                        
                                             </div> */}
                        
                                          </div>
                        
                        
                                       </Box>

                                       : <Box
                                          component="form"
                                          sx={{
                                             '& > :not(style)': { m: 1 },
                                          }}
                                          noValidate
                                          autoComplete="off">
                     
                                             <div style={{"width":"100%", "marginTop": "30px"}}>
                           
                                             <div className="item" style={{
                                                "marginLeft":"20px", 
                                                "border": "0.5px solid lightgray",
                                                "padding":"20px", "borderRadius":"10px",
                                                "marginTop":"10px",
                                                "marginBottom":"10px",
                                             }}>
                                             <div className="details" style={{"width":"100%", }}>
                                                
                                                <div className="detailItem">
                                                   <span className="itemKey">First Name:</span>
                                                   <span className="itemValue">{first_name}</span>
                                                </div>
                                                <div className="detailItem">
                                                   <span className="itemKey">Last Name:</span>
                                                   <span className="itemValue">{last_name}</span>
                                                </div>
                                                <div className="detailItem">
                                                   <span className="itemKey">Email:</span>
                                                   <span className="itemValue">{userEmail}</span>
                                                </div>
                                                <div className="detailItem">
                                                   <span className="itemKey">Phone Number:</span>
                                                   <span className="itemValue">{phone_number}</span>
                                                </div>
                                                <div className="detailItem">
                                                   <span className="itemKey">Organization Name:</span>
                                                   <span className="itemValue">{display_organization_name}</span>
                                                </div>
                                                <div className="detailItem">
                                                   <span className="itemKey">Role:</span>
                                                   <span className="itemValue">{adminRole}</span>
                                                </div>
                                                <div className="detailItem">
                                                   <span className="itemKey">Management Access:</span>
                                                   <span className="itemValue">{managementRole}</span>
                                                </div>
                                                <div className="detailItem">
                                                   <span className="itemKey">Password:</span>
                                                   <span className="itemValue" style={{"color":"green", }}>{password}</span>
                                                </div>
                                                <hr style={{
                                                   height: "0.5px",
                                                   backgroundColor: '#dddddd',
                                                   marginTop: '20px',
                                                   marginBottom: '20px',
                                                   border: "none",
                                                }}/>
                                                <div className="detailItem">
                                                   <span className="itemKey">Street Address:</span>
                                                   <span className="itemValue">
                                                      {street_address}
                                                   </span>
                                                </div>
                                                <div className="detailItem">
                                                   <span className="itemKey">City:</span>
                                                   <span className="itemValue">
                                                      {city}
                                                   </span>
                                                </div>
                                                <div className="detailItem">
                                                   <span className="itemKey">Postal Code:</span>
                                                   <span className="itemValue">
                                                      {postal_code}
                                                   </span>
                                                </div>
                                                <div className="detailItem">
                                                   <span className="itemKey">Country:</span>
                                                   <span className="itemValue">
                                                      {country}
                                                   </span>
                                                </div>
                                                {/* <div className="detailItem">
                                                   <span className="itemKey">Longitude:</span>
                                                   <span className="itemValue">
                                                      {longitude}
                                                   </span>
                                                </div>
                                                <div className="detailItem">
                                                   <span className="itemKey">Latitude:</span>
                                                   <span className="itemValue">
                                                      {latitude}
                                                   </span>
                                                </div> */}

                                                <FormControlLabel style={{"marginTop":"20px",}}
                                                   control={
                                                   <Switch
                                                      checked={isPublishedChecked}
                                                      onChange={handlePublishedChange}
                                                      inputProps={{ 'aria-label': 'controlled' }}
                                                   />
                                                   } 
                                                   label="Accept Terms and Conditions" 
                                                />
                                                
                                             </div>
                                             </div>

                                             
                                          
                                       </div>
                     
                     
                                       </Box> 
                                       } 
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                       <Button
                                       color="inherit"
                                       disabled={activeStep === 0}
                                       onClick={handleBack}
                                       sx={{ mr: 1 }}
                                       >
                                       Back
                                       </Button>
                                       <Box sx={{ flex: '1 1 auto' }} />
                                       {isStepOptional(activeStep) && (
                                       <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                          Skip
                                       </Button>
                                       )}
                                       { activeStep === steps.length - 1 ? 
                                          
                                          <Button 
                                             style={{
                                                "width": "20%",
                                                "height": "50px", 
                                                   
                                                "fontSize" : "12px"
                                                }}
                                             size="large"
                                             variant="contained"
                                             onClick={handleSubmit}
                                          >
                                             Create Super Admin Account
                                          </Button>
                                          : 
                                          <Button onClick={handleNext}>
                                             {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                          </Button>
                                       }
                                    </Box>
                                 </React.Fragment>
                                 )}
                              </div>
                              
                           </div>
                           </div>

                        </div>
                     </div>  
                     <p style={{"textAlign": "center", "marginBottom": "40px", "marginLeft": "20px"}}>
                        <Link to="/login" style={{ textDecoration: "none", marginTop: "20px", }}>
                           <span className="hasAccount">Already has an Account? <h2 className="login">Login</h2> </span>
                        </Link>
                        <Link to="/forgotPassword" style={{ textDecoration: "none", marginTop: "10px", }}>
                           <span className="hasAccount">Forgot your  <h2 className="forgotpass">password</h2> </span>
                        </Link>
                     </p> 
                     {/* <div>{data ? data.map((organizations) => {
                        return <p>{organizations.name}</p>
                     }) : null}</div> */}
               </form>
            </div>
      </div>

      <Modal
            hideBackdrop
            open={openModal}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 450 }}>
            <Button onClick={handleClose}>x</Button>
                <h3 id="child-modal-title" style={{textAlign: "center"}}>User Account Created Successfully</h3>
                {/* <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
                  Welcome
                </p> */}
                <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{first_name} {last_name}</h2>
                <Link to="/login" style={{ textDecoration: "none", marginTop: "10px", }}>
                  <span className="hasAccount"><Button 
                      style={{
                        "width": "100%",
                        "height": "50px", 
                        "marginTop":"20px", 
                        "marginBottom":"20px", 
                        "marginRight":"10px",  
                        "marginLeft":"20px",
                      }}
                      size="large"
                      variant="contained"
                      // onClick={handleSubmit}
                  >
                      Login
                  </Button></span>
                </Link>
            </Box>
          </Modal>

          <Modal
            hideBackdrop
            open={openErrorModal}
            onClose={handleErrorClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 550 }}>
            <Button onClick={handleErrorClose}>x</Button>
                <h3 id="child-modal-title" style={{textAlign: "center"}}>User Creation Failed</h3>
                <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
                  {errorMessage}
                </p>
                <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{first_name} {last_name}</h2>
                <span className="hasAccount"><Button 
                      style={{
                        "width": "100%",
                        "height": "50px", 
                        "marginTop":"20px", 
                        "marginBottom":"20px", 
                        "marginRight":"10px",  
                        "marginLeft":"20px",
                      }}
                      size="large"
                      variant="contained"
                      onClick={handleErrorClose}
                  >
                      Try Again
                  </Button></span>
            </Box>
          </Modal>
          
   </div>
  );
};

export default SignUp;