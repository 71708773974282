import React, { useEffect, useState } from "react";
import DashboardCard from "../../components/card/card";
import SectionHeader from "../../components/custom_text/section_header";
import NavBarAdmin from "../../components/navbar/navbar_admin";
import NavCard_Community from "../../components/nav_card/navcard_community";
import Sidebar from "../../components/sidebars/sidebar";
import './community.scss'
import SideBarCommunity from "./sidebar_community/sidebarcommunity";

// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Button, Chip, FormControlLabel, FormHelperText, MenuItem, Modal, Select, SelectChangeEvent, Step, StepLabel, Stepper, Switch, TextField } from "@mui/material";
import System_Type from '../system_type/system_types';
import { Theme, useTheme } from '@mui/material/styles';


import { styled } from '@mui/material/styles';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link, useParams } from 'react-router-dom';
import logoImage from './images/logo.png';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import organization_services from "../organization/organization_services";
import systemtype_services from "../system_type/systemtype_services";
import authentication_services from "../authentication/authentication_services";
import { Address, Community, CommunitySystemTypes, Organization, SystemTypes, User } from "../authentication/authentication_interfaces";
import community_services from "./community_services";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const steps = ['Create Community Details', 'Create Community Address', 'Confirm Community Details'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function getStyles(name: string, systemTypeMultiple: readonly string[], theme: Theme) {
  return {
    fontWeight:
    systemTypeMultiple.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Edit_Community : React.FC = () => {
  
  const { communityId } = useParams();
  console.log("communityId " + communityId);


  const [communityDeletedSystemType, setCommunityDeletedSystemType] = useState<string>();
  const [communityRetrivedData, setCommunityRetrivedData] = useState<Community | null>();
  const [communityAddressRetrivedData, setCommunityAddressRetrivedData] = useState<Address | null>();
  const [communitySystemTypesRetrivedData, setCommunitySystemTypesRetrivedData] = useState<CommunitySystemTypes []>([]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [systemTypeMultiple, setSystemMultiple] = React.useState<string[]>([]);
  const [organization_name, setOrganization] = React.useState('');
  // const [communityId, setCommunityId] = React.useState<number>();
  const [community_name, setCommunity] = React.useState('');
  const [communityDescription, setcommunityDescription] = React.useState('');
  const [communityPopulation, setcommunityPopulation] = React.useState('');

  const [street_address, setUserAddress] = React.useState('');
  const [city, setUserCity] = React.useState('');
  const [postal_code, setUserPostalCode] = React.useState('');

  const [country, setUserCountry] = React.useState('');
  const [longitude, setUserLongitude] = React.useState('');
  const [latitude, setUserLatitude] = React.useState('');

  const [fullName, setFullName] = useState<string>();
  const [userId, setUserId] = useState([]);
  const [bearer, setBearerToken] = useState<string>('');

  //Api call state management
  const [userData, setUserData] = useState<User | null>();
  const [community_list_data, setCommunityListData] = useState<Community[] | null>([]);
  const [data, setOrganizationData] = useState<Organization[] | null>();
  const [systemData, setSystemTypeData] = useState<SystemTypes[] | null>();
  const [displaySystemTypeData, setDisplaySystemTypeData] = useState<any[]>();
  const [displaySystemTypeDataId, setDisplaySystemTypeDataID] = useState<any[]>([]);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('userId') || '{}');
    const user_fullName = JSON.parse(localStorage.getItem('user_fullName') || '{}');
    const bearerToken = JSON.parse(localStorage.getItem('bearerToken') || '{}');

    //get user details
    authentication_services.getUserDetails(userId, bearerToken).then((response) => {

      const status = response['data']['status'];
      const message = response['data']['message'];
      const userId = response['data']['data']["user_id"];
      console.log("user id " + userId);

      setUserData(response.data.data);
      console.log('User First Name ' + response['data']['data']['first_name']);
      console.log('User API status ' + response['data']['status']);
      
      if(status === "Success"){

        setUserId(userId);
        setFullName(user_fullName);
        setBearerToken(bearerToken);
         
      }

      }).catch(function (error) {

        // setErrorMessage(error.toString());
        console.log('error ' + error.toJSON());
        console.log('error message ' + error.toString());
        // setopenErrorModal(true);

    });
    //Get all organizations list 
    organization_services.getAllOrganizationsDropDownList()
    .then((response) => {

      console.log('API call response status ' + response.status);
      setOrganizationData(response.data.data);
      console.log('Organization Name ' + response['data']['data'][0]['name']);
      console.log('Organization Name ' + response['data']['status']);

    }).catch((error) => {
      console.log('error ' + error.toJSON());
      console.log('error message ' + error.toString());
    });

    //Get all system type list 
    systemtype_services.getAllSystemTypeDropDownList()
    .then((response) => {

      console.log('API call response status ' + response.status);
      setSystemTypeData(response.data.data);
      console.log('Organization Name ' + response['data']['data'][0]['name']);
      console.log('Organization Name ' + response['data']['status']);

    }).catch((error) => {
      console.log('error ' + error.toJSON());
      console.log('error message ' + error.toString());
    });
    
    //Get One communities 
    community_services.getOneCommunity(bearerToken, parseInt(communityId!))
    .then((response) => {

      console.log('API call response status ' + response.status);
      setCommunityRetrivedData(response.data.data);
      console.log('Community Name ' + response['data']['data']['community_name']);
      console.log('Community API status ' + response['data']['status']);
      console.log('Community Id ' + response['data']['data']['community_id']);

      

      setCommunity(response['data']['data']['community_name']);
      setcommunityPopulation(response['data']['data']['population']);
      setcommunityDescription(response['data']['data']['community_description']);
      setOrganization(response['data']['data']['organization']['name']);
      console.log(response['data']['data']['communitySystemTypes']);
      setCommunitySystemTypesRetrivedData(response['data']['data']['communitySystemTypes']);
      getCommunityAddressData(response['data']['data']['community_id'], bearer);

    }).catch((error) => {
      console.log('error ' + error.toJSON);
      console.log('error message ' + error.toString());
    });

    //Get One communities Address


  }, [bearer, communityId]); 
  
    const getCommunityAddressData = async (communityAddressID : number, bearer: string ) => {
      console.log("fired address community system type id " + communityAddressID );
      console.log("fired bearer community system type id " + bearer); 
      community_services.getOneCommunityAddress(bearer, communityAddressID)
      .then((response) => {
  
        console.log('Address API call response status ' + response.status);
        setCommunityAddressRetrivedData(response.data.data[0]);
        // console.log('Community Address Name ' + response['data']['data'][0]['address']);
        console.log('Community Address API status ' + response['data']['status']);
  
        setUserAddress(response.data.data[0]['address']);
        setUserCity(response.data.data[0]['city']);
        setUserCountry(response.data.data[0]['country']);
        setUserLatitude(response.data.data[0]['latitude']);
        setUserPostalCode(response.data.data[0]['postal_code']);
        setUserLongitude(response.data.data[0]['longitude']);
  
      });
  
    };

    const handleDelete = (communitysystemtype: CommunitySystemTypes) => async () => {
      console.log("fired delete community system type id " + communitysystemtype.community_system_types_id);

      //Get One communities Address
      community_services.deleteCommunitySystemType(bearer, communitysystemtype.community_system_types_id)
      .then((response) => {

        const status = response['data']['status'];
        const message = response['data']['message'];
        console.log("Community System Type id " + response['data']['data']["community_system_types_id"]);
        console.log('Delete Community System Type Status ' + status);
        console.log('Message ' + message);
        setCommunityDeletedSystemType(message);

      }).catch((error) => {
        console.log('error ' + error.toJSON);
        console.log('error message ' + error.toString());
      });
      
    };

  
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [error, setUserError] = React.useState(false);

    const handleDrawerOpen = () => {
      setOpen(true);
    };

    const handleDrawerClose = () => {
      setOpen(false);
    };

    const [openModal, setOpenModal] = React.useState(false);
    const [openErrorModal, setopenErrorModal] = React.useState(false);
    const handleOpen = () => {
      setOpenModal(true);
    };
    const handleClose = () => {
      setOpenModal(false);
    };

    const handleErrorClose  = () => {
      setopenErrorModal(false);
    };

    let selectedSystemTypesId : any[] = [];
    let selectedSystemTypes : any[] = [];

    const handleDropDowOrganizationChange = (event: SelectChangeEvent) => {
      setOrganization(event.target.value);
    };
  
    const handleCommunityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCommunity(event.target.value);
    };

    const handleCommunityDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setcommunityDescription(event.target.value);
    };

    const handleCommunityPopulationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setcommunityPopulation(event.target.value);
    };

    const handleUserAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserAddress(event.target.value);
   };

   const handleUserCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserCity(event.target.value);
   };

   const handleUserPostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserPostalCode(event.target.value);
   };

   const handleUserCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserCountry(event.target.value);
   };

   const handleUserLongitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserLongitude(event.target.value);
   };

   const handleUserLatitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserLatitude(event.target.value);
   };
  
    const isStepOptional = (step: number) => {
      return step === 1;
    };
  
    const isStepSkipped = (step: number) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

    const [display_organization_name, setDisplayOrganizationName] = React.useState<string>('');
    const [display_organization_id, setDisplayOrganizationId] = React.useState<number>();

    useEffect(() => {
      getOrganizationName(organization_name);
      getSelectedSystemTypes();
      getSelectedSystemTypesId();
     }, [organization_name, systemTypeMultiple]);
   
    function getOrganizationName(str: string) {
       var values = str.split(" ");
       var id = values.shift();
       var name = values.join(' ');
       setDisplayOrganizationName(name);
       setDisplayOrganizationId(parseInt(id!));
 
 
       console.log("Organization Id " + id);
       console.log("Organization Name " + name);
    }

    //handle multiple selected dropdown values
    const handleMultSelectDropDownSystemTypeChange = (event: SelectChangeEvent<typeof systemTypeMultiple>) => {
      const {
        target: { value },
      } = event;
      console.log("system type" + systemTypeMultiple);
      setSystemMultiple(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    //get the id from the selected system types
    const getSelectedSystemTypesId = async () => {
      selectedSystemTypesId.length = 0;
      console.log("selected multiple system types " + systemTypeMultiple);
      for(let i = 0; i < systemTypeMultiple.length; i++){
        var values = systemTypeMultiple[i].split(" ");
        var systemTypeId = values.shift();
        var systemTypeName = values.join(' ');
        selectedSystemTypesId.push(systemTypeId);
      }
      // getSelectedSystemTypes();
      console.log("selected id value " + selectedSystemTypesId);
      setDisplaySystemTypeDataID(selectedSystemTypesId);
    };

    const getSelectedSystemTypes = async () => {
      selectedSystemTypes.length = 0;
      console.log("selected system types " + systemTypeMultiple);
      for(let i = 0; i < systemTypeMultiple.length; i++){
        var values = systemTypeMultiple[i].split(" ");
        var systemTypeId = values.shift();
        var systemTypeName = values.join(' ');
        selectedSystemTypes.push(systemTypeName); //remove the first string character and add to the list to display in summary
      }
      console.log("display values "+ selectedSystemTypes);
      setDisplaySystemTypeData(selectedSystemTypes);
    };

    let createCommunityData = {

      community_name : community_name,
      population : communityPopulation,
      community_description : communityDescription,
      system_type: displaySystemTypeDataId,
      active : true
  
    };

    const [errorMessage, setErrorMessage] = React.useState();
    const [communityData, setcommunityData] = React.useState<Community | null>();

    let handleSubmit = (event : any) => {
      event.persist();
      console.log("community data " + createCommunityData);
      console.log("Update community event fired");
      // PostData();

      community_services.updateCommunity(communityRetrivedData!.community_id, "https://images.nigeriapropertycentre.com/area-guides/f738af22-d802-4911-a09b-874ab03f586f.jpeg", 
      community_name, communityPopulation, communityDescription, displaySystemTypeDataId, bearer).then((response) => {

        const status = response['data']['status'];
        const message = response['data']['message'];
        const communityId = response['data']['data']["community_id"];
        console.log("community id " + communityId);

        console.log('Create Community Status ' + status);
        console.log('Message ' + message);
        
        if(status === "Success"){

          community_services.updateCommunityAddress(communityId, street_address, city, postal_code, country, latitude, longitude, bearer )
          .then((response) => {
            const status = response['data']['status'];
            const message = response['data']['message'];
            const addressData = response['data']['data'];
            console.log("create address response " + addressData)
            console.log('Create address Status ' + status);
            console.log('Message ' + message);
            setOpenModal(true);
          });  
        }else{
          setErrorMessage(response['data']['message']);
          setopenErrorModal(true);
        }

      });

   };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div className="home">

          <div className="homeContainer">

            
            <SectionHeader sectionTitle={'EDIT COMMUNITIES'} link='' />
            <div className="cardPadding">

              <NavCard_Community cardContent={20} cardTitle={'Create Community'} image={createImage} link={"/home/community/create"} />
              <NavCard_Community cardContent={20} cardTitle={'Community List'} image={listImage} link={"/home/community/list/:communityId"} />
              <NavCard_Community cardContent={20} cardTitle={'Edit Community'} image={editImage} link={"/home/community/edit/:communityId"} />
              <NavCard_Community cardContent={20} cardTitle={'Community Details'} image={detailImage} link={"/home/community/detail/:communityId"} />

            </div>
            {/* <p>{userData?.first_name}</p> */}
            <div className="cardPadding">
              <DashboardCard cardContent={20} cardTitle={'Organizations'} image={organizationImage} link={""}/>
              <DashboardCard cardContent={40} cardTitle={'Communities'} image={communityImage} link={""}/>
              <DashboardCard cardContent={230} cardTitle={'Management Users'} image={managementUsersImage} link={""}/>
              <DashboardCard cardContent={130} cardTitle={'People Trained'} image={trainedPeopleImage} link={""}/>
            </div>

            <div className="top">
              <div className="left">
                <div className="item">
                  <img
                    src={communityRetrivedData?.image}
                    alt=""
                    className="itemImg"
                  />
                  <div style={{
                    "width": "50%",
                    "height": "50px", 
                    "marginTop":"20px", 
                    "marginBottom":"20px", 
                    "marginRight":"10px",  
                    "marginLeft":"10px",
                    }}>
                                
                    {/* <Button 
                      style={{
                        "width": "50%",
                        "height": "50px", 
                        "marginTop":"40px", 
                        "marginBottom":"20px", 
                        "marginRight":"10px",  
                        "marginLeft":"10px",
                      }}
                        size="large"
                        variant="outlined"
                        onClick={() => {
                          alert('clicked');
                        }}
                      >
                      Select Community Logo
                    </Button> */}
                  </div>

                </div>

                {/* <hr style={{
                    height: "2px",
                    backgroundColor: '#dddddd',
                    marginTop: '10px',
                    marginBottom: '10px',
                    border: "none",
                }}/> */}

                  <div className="formBox" style={{
                    "marginLeft":"10px", 
                    "border": "0.5px solid lightgray",
                    "padding":"20px", 
                    "borderRadius":"5px",
                    "marginTop":"10px",
                    "marginBottom":"10px",
                  }}>
                  <div className="details" style={{"width":"100%", }}>
                    <h1 className="itemTitle">Edit Community</h1>
                    <hr style={{
                      height: "0.5px",
                      backgroundColor: '#dddddd',
                      marginTop: '10px',
                      marginBottom: '10px',
                      border: "none",
                    }}/>

                    <div style={{"width":"100%", "marginTop": "30px"}}>
                      <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                          const stepProps: { completed?: boolean } = {};
                          const labelProps: {
                            optional?: React.ReactNode;
                          } = {};
                          // if (isStepOptional(index)) {
                          //   labelProps.optional = (
                          //     <Typography variant="caption">Optional</Typography>
                          //   );
                          // }
                          if (isStepSkipped(index)) {
                            stepProps.completed = false;
                          }
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                      {activeStep === steps.length ? (
                        <React.Fragment>
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                          </Typography>
                          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                          </Box>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                          {
                            activeStep === 0 ? 
                              <Box
                                component="form"
                                sx={{
                                  '& > :not(style)': { m: 1 },
                                }}
                                noValidate
                                autoComplete="off">
              
                                <div style={{"width":"100%", "marginTop": "30px"}}>
              
                                    <div 
                                      style={{
                                        "display": "flex", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"20px",  
                                        // "marginBottom":"20px"  
                                      }}
                                    >
              
                                      <FormControl style={{"width": "45%"}}>
                                        <InputLabel htmlFor="component-outlined">Community Name</InputLabel>
                                        <OutlinedInput
                                          id="component-outlined"
                                          value={community_name}
                                          onChange={handleCommunityChange}
                                          label="Community Name"
                                          
                                        />
                                        {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                      </FormControl>
              
                                      <FormControl style={{"width": "45%"}}>
                                        <InputLabel htmlFor="component-outlined">Community Population</InputLabel>
                                        <OutlinedInput
                                          id="component-outlined"
                                          value={communityPopulation}
                                          onChange={handleCommunityPopulationChange}
                                          label="Community Populations"
                                          
                                        />
                                        {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                      </FormControl>
              
                                    </div>

                                    <div 
                                      style={{
                                        "display": "block", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"20px",  
                                        // "marginBottom":"20px"  
                                      }}
                                      >
              
                                      {/* dropdown component */}
                                      {/* <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} >
                                        <InputLabel id="demo-simple-select-helper-label">Select Organizations</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-helper-label"
                                          id="demo-simple-select-helper-label"
                                          value={organization_name}
                                          label="Select Organizations"
                                          onChange={handleDropDowOrganizationChange}
                                          renderValue={(value) =>  value }
                                        >
                                          <MenuItem value=""><em>None</em></MenuItem>
                                          {data ? data.map((organizations) => {
                                            return <MenuItem value={`${organizations.organization_id} ${organizations.name}`}>{organizations.name}</MenuItem>
                                          }) : null}
                                        </Select>
                                        {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                      </FormControl> */}

                                      {/* <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px" , "marginBottom":"20px"}} >
                                        <InputLabel id="demo-simple-select-helper-label">Community System Types {communitySystemTypesRetrivedData![0].community_name}</InputLabel>
                                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                              {communitySystemTypesRetrivedData?.map((value, index) => (
                                                <Chip label={value.community_name} onDelete={value.community_system_types_id === 0 ? undefined : handleDeleteSystem(value.community_system_types_id)} />
                                              ))}
                                          </Box>
                                      </FormControl> */}
                                      <InputLabel id="demo-simple-select-helper-label">Community System Types</InputLabel>
                                      <Paper
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          flexWrap: 'wrap',
                                          listStyle: 'none',
                                          marginTop:"20px",
                                          p: 0.5,
                                          m: 0,
                                        }}
                                        component="ul"
                                      >
                                        {communitySystemTypesRetrivedData.map((data) => {
                                          let icon;

                                          if (data.community_id === 0) {
                                            icon = <TagFacesIcon />;
                                          }

                                          return (
                                            <ListItem key={data.community_system_types_id}>
                                              <Chip
                                                icon={icon}
                                                label={data.system_type_name}
                                                onDelete={data.community_id === 0 ? undefined : handleDelete(data)}
                                              />
                                            </ListItem>
                                          );
                                        })}
                                      </Paper>
              
                                      {/* dropdown component */}
                                      <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} >
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Community Description"
                                          multiline
                                          maxRows={4}
                                          value={communityDescription}
                                          onChange={handleCommunityDescriptionChange}
                                          defaultValue="Default Value"
                                        />
                                        {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                      </FormControl>

                                      <FormControl sx={{ m: 0, minWidth: 300 }} style={{"width": "100%", "marginTop":"20px",}}>
                                        <InputLabel id="demo-multiple-chip-label">Select System Type</InputLabel>
                                        <Select
                                          labelId="demo-multiple-chip-label"
                                          id="demo-multiple-chip"
                                          multiple
                                          value={systemTypeMultiple}
                                          onChange={handleMultSelectDropDownSystemTypeChange}
                                          input={<OutlinedInput id="select-multiple-chip" label="Select System Type" />}
                                          renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                              {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                              ))}
                                            </Box>
                                          )}
                                          MenuProps={MenuProps}
                                        >
                                          {systemData ? systemData.map((system_types, index) => (
                                            <MenuItem
                                              key={system_types.system_type_id}
                                              value={`${system_types.system_type_id} ${system_types.name}`}
                                              // value={index}
                                              style={getStyles(system_types.name, systemTypeMultiple, theme)}
                                            >
                                              {system_types.name}
                                            </MenuItem>
                                          )) : null}
                                        </Select>
                                      </FormControl>
              
                                    </div>
              
              
                                </div>
              
              
                              </Box>
                              : 
                              activeStep === 1 ? 
                              <Box
                                component="form"
                                sx={{
                                  '& > :not(style)': { m: 1 },
                                }}
                                noValidate
                                autoComplete="off">
              
                                <div style={{"width":"100%", "marginTop": "30px"}}>
              
                                    <div 
                                      style={{
                                        "display": "flex", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"20px",  
                                        // "marginBottom":"20px"  
                                      }}
                                    >
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Street Address"
                                          multiline
                                          maxRows={4}
                                          value={street_address}
                                          onChange={handleUserAddressChange}
                                          defaultValue="Default Value"
                                        />
                                        {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                      </FormControl>
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="City"
                                          multiline
                                          maxRows={4}
                                          value={city}
                                          onChange={handleUserCityChange}
                                          defaultValue="Default Value"
                                        />
                                        {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                      </FormControl>
              
                                    </div>

                                    <div 
                                      style={{
                                        "display": "flex", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"20px",  
                                        // "marginBottom":"20px"  
                                      }}
                                    >
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Postal Code"
                                          multiline
                                          maxRows={4}
                                          value={postal_code}
                                          onChange={handleUserPostalCodeChange}
                                          defaultValue="Default Value"
                                        />
                                        {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                      </FormControl>
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Country"
                                          multiline
                                          maxRows={4}
                                          value={country}
                                          onChange={handleUserCountryChange}
                                          defaultValue="Default Value"
                                        />
                                        {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                      </FormControl>
              
                                    </div>

                                    <div 
                                      style={{
                                        "display": "flex", 
                                        "justifyContent": "space-between", 
                                        "marginRight":"10px",  
                                        "marginLeft":"10px",
                                        "marginTop":"20px",  
                                        // "marginBottom":"20px"  
                                      }}
                                    >
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Longitude"
                                          multiline
                                          maxRows={4}
                                          value={longitude}
                                          onChange={handleUserLongitudeChange}
                                          defaultValue="Default Value"
                                        />
                                        {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                      </FormControl>
              
                                      <FormControl style={{"width": "45%"}}>
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="Latitude"
                                          multiline
                                          maxRows={4}
                                          value={latitude}
                                          onChange={handleUserLatitudeChange}
                                          defaultValue="Default Value"
                                        />
                                        {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                                      </FormControl>
              
                                    </div>
              
                                </div>
              
              
                              </Box>

                              : <Box
                                component="form"
                                sx={{
                                  '& > :not(style)': { m: 1 },
                                }}
                                noValidate
                                autoComplete="off">
            
                                  <div style={{"width":"100%", "marginTop": "30px"}}>
                
                                    <div className="item" style={{
                                      "marginLeft":"20px", 
                                      "border": "0.5px solid lightgray",
                                      "padding":"20px", "borderRadius":"10px",
                                      "marginTop":"10px",
                                      "marginBottom":"10px",
                                    }}>
                                    <div className="details" style={{"width":"100%", }}>
                                      
                                      <div className="detailItem">
                                        <span className="itemKey">Community Name:</span>
                                        <span className="itemValue">{community_name}</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Community Population:</span>
                                        <span className="itemValue" style={{"color":"green", }}>{communityPopulation}</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Organization:</span>
                                        <span className="itemValue" style={{"color":"green", }}>{organization_name}</span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Community Description: </span>
                                        <span className="itemValue">
                                          {communityDescription}
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Updated System Type: </span>
                                        <span className="itemValue">
                                          {displaySystemTypeData}
                                        </span>
                                      </div>
                                      <hr style={{
                                        height: "0.5px",
                                        backgroundColor: '#dddddd',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        border: "none",
                                      }}/>
                                      <div className="detailItem">
                                        <span className="itemKey">Street Address:</span>
                                        <span className="itemValue">
                                          {street_address}
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">City:</span>
                                        <span className="itemValue">
                                          {city}            
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Postal Code:</span>
                                        <span className="itemValue">
                                          {postal_code}
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Country:</span>
                                        <span className="itemValue">
                                          {country}
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Longitude:</span>
                                        <span className="itemValue">
                                          {longitude}
                                        </span>
                                      </div>
                                      <div className="detailItem">
                                        <span className="itemKey">Latitude:</span>
                                        <span className="itemValue">
                                          {latitude}
                                        </span>
                                      </div>
                                      
                                    </div>
                                  </div>
            
                                  {/* <Button 
                                    style={{
                                      "width": "50%",
                                      "height": "50px", 
                                      "marginTop":"40px", 
                                      "marginBottom":"20px", 
                                      "marginRight":"10px",  
                                      "marginLeft":"20px",
                                    }}
                                    size="large"
                                    variant="contained"
                                    onClick={handleSubmit}
                                  >
                                    Create Community
                                  </Button> */}
            
                              </div>
            
            
                            </Box> 
                            } 
                          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                              color="inherit"
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                            >
                              Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                              </Button>
                            )}
                            { activeStep === steps.length - 1 ? 
                                  
                                     <Button 
                                        style={{
                                           "width": "20%",
                                           "height": "50px", 
                                           
                                           "fontSize" : "12px"
                                        }}
                                        size="large"
                                        variant="contained"
                                        onClick={handleSubmit}
                                     >
                                        Update Community
                                     </Button>
                                   : 
                                  <Button onClick={handleNext}>
                                     {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                  </Button>
                              }
                          </Box>
                        </React.Fragment>
                      )}
                    </div>
                    
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>

        <Modal
          hideBackdrop
          open={openModal}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 450 }}>
          <Button onClick={handleClose}>x</Button>
              <h3 id="child-modal-title" style={{textAlign: "center"}}>Community Updated Successfully</h3>
              {/* <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
                Welcome
              </p> */}
              <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{community_name}</h2>
              <Link to="/home/community/" style={{ textDecoration: "none", marginTop: "10px", }}>
                <span className="hasAccount"><Button 
                    style={{
                      "width": "100%",
                      "height": "50px", 
                      "marginTop":"20px", 
                      "marginBottom":"20px", 
                      "marginRight":"10px",  
                      "marginLeft":"20px",
                    }}
                    size="large"
                    variant="contained"
                    // onClick={handleSubmit}
                >
                    Continue
                </Button></span>
              </Link>
          </Box>
        </Modal>

        <Modal
          hideBackdrop
          open={openErrorModal}
          onClose={handleErrorClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 550 }}>
          <Button onClick={handleErrorClose}>x</Button>
              <h3 id="child-modal-title" style={{textAlign: "center"}}>Community Updating Failed</h3>
              <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
                {errorMessage}
              </p>
              <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{community_name}</h2>
              <span className="hasAccount"><Button 
                    style={{
                      "width": "100%",
                      "height": "50px", 
                      "marginTop":"20px", 
                      "marginBottom":"20px", 
                      "marginRight":"10px",  
                      "marginLeft":"20px",
                    }}
                    size="large"
                    variant="contained"
                    onClick={handleErrorClose}
                >
                    Continue
                </Button></span>
          </Box>
        </Modal>
       
      </Main>
    </Box>
  );

};

export default Edit_Community;