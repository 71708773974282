import React, { useEffect, useState } from "react";
import Custom_Text from "../../components/custom_text";
import './system_type.scss'
import SideBarSystemType from './sidebar_system_type/sidebarsystem_type';
import NavBarAdmin from "../../components/navbar/navbar_admin";
import SectionHeader from "../../components/custom_text/section_header";
import DashboardCard from "../../components/card/card";
import Sidebar from "../../components/sidebars/sidebar";
import NavCard_Community from "../../components/nav_card/navcard_community";
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Button, FormHelperText, MenuItem, Select, SelectChangeEvent, TextField, Modal } from '@mui/material';


import { styled, useTheme } from '@mui/material/styles';

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import logoImage from './images/logo.png';
import axios from 'axios';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
export interface User {
  user_id: number;
  organization_id: number;
  user_token: string;
  firebase_token: string;
  profile_image?: any;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  role: string;
  phone_number: string;
  management_role: string;
  reset_password_expiry_time: string;
  reset_password_token?: any;
  isPhoneVerified: boolean;
  isEmailVerifed: boolean;
  active: boolean;
  accepted_terms: boolean;
  suspended: boolean;
  updated_by: number;
  created_by: number;
  created_at: Date;
  updated_at: Date;
}

export interface Address {
  address_id: number;
  user_id: number;
  organization_id: number;
  community_id: number;
  address: string;
  state: string;
  city: string;
  latitude: string;
  longitude: string;
  postal_code: string;
  country: string;
  is_default: boolean;
  created_at: Date;
  updated_at: Date;
}
export interface SystemTypes {
  system_type_id: number;
  user_id: number;
  image: string;
  name: string;
  description: string;
  updated_by: number,
  created_by: number;
  created_at: string;
  updated_at: string;
  users: {};
}

const Create_System_Type : React.FC = () => {

  const config = {
    headers:{
      'content-type': 'application/json',
      // header2: value2
    }
  };

  const [fullName, setFullName] = useState<string>();
  const [userId, setUserId] = useState([]);
  const [bearer, setBearerToken] = useState<string>();
  const [userData, setUserData] = useState<User | null>();

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('userId') || '{}');
    const user_fullName = JSON.parse(localStorage.getItem('user_fullName') || '{}');
    const bearerToken = JSON.parse(localStorage.getItem('bearerToken') || '{}');
    console.log("bearer token" + bearerToken)
    if (userId) {
      setUserId(userId);
      setFullName(user_fullName);
      setBearerToken(bearerToken);
      getUserData(userId, bearerToken);
    }
  }, []);

    const getUserData = async (userID : number, bearer: string ) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
      const userResponse = await axios.get(`http://api.wellbeyondwater.com:8000/api/user/${userID}`, config);
      console.log('API call response status ' + userResponse.status);
      setUserData(userResponse.data.data["user"]);
      console.log('User First Name ' + userResponse['data']['data']['user']['first_name']);
      console.log('User API status ' + userResponse['data']['status']);
    };
  
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

    const [openModal, setOpenModal] = React.useState(false);
    const [openErrorModal, setopenErrorModal] = React.useState(false);
    const handleOpen = () => {
      setOpenModal(true);
    };
    const handleClose = () => {
      setOpenModal(false);
    };

    const handleErrorClose  = () => {
      setopenErrorModal(false);
    };

  const [system_type_name, setSystemTypeName] = React.useState('');
  const [system_type_category, setSystemTypeCategory] = React.useState('');
  const [system_type_description, setSystemTypeDescription] = React.useState('');
  const [error, setUserError] = React.useState(false);

 
  const handleMultilineSystemDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSystemTypeDescription(event.target.value);
  };


  const handleDropDownSystemTypeCategory = (event: SelectChangeEvent) => {
    setSystemTypeCategory(event.target.value);
  };

  const handleSystemNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSystemTypeName(event.target.value);
  };

    const [errorMessage, setErrorMessage] = React.useState();
    const [systemTypeData, setSystemTypeData] = React.useState<SystemTypes | null>();

    let systemTypeDataPayload = {

      name : system_type_name,
      image : "https://images.nigeriapropertycentre.com/area-guides/f738af22-d802-4911-a09b-874ab03f586f.jpeg",
      description : system_type_description
  
    };

    let handleSubmit = (event : any) => {
      event.persist();
      console.log("system type data " + systemTypeDataPayload);
      console.log("create system type event fired");
      PostData();
   };

   const PostData = async () => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${bearer}`;
      const createSystemTypeResponse = await axios.post(`http://api.wellbeyondwater.com:8000/api/system_type/createSystemType`, systemTypeDataPayload)
      .then((response) => {
        setSystemTypeData(response.data.data);
        const status = response['data']['status'];
        const message = response['data']['message'];
        const systemTypeId = response['data']['data']["system_type_id"];
        console.log("system type id " + systemTypeId);

        console.log('Create System Type Status ' + status);
        console.log('Message ' + message);

        if(status === "Success"){

          setOpenModal(true);
          
        }

      }).catch(function (error) {

        setErrorMessage(error.toString());
        console.log('error ' + error.toJSON());
        console.log('error message ' + error.toString());
        setopenErrorModal(true);

      });

   };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/home" style={{ textDecoration: "none" }}>
                {/* <span className='logo'>WellBeyond</span> */}
                <img
                    src={logoImage}
                    alt=""
                    className="itemImg"
                />
            </Link>
        </Toolbar>
        
        <NavBarAdmin/>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? 
            <img
              src={logoImage}
              alt=""
              className="itemImg"
                
            /> : 
            <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Sidebar />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div className="home">

<div className="homeContainer">

  <SectionHeader sectionTitle={'CREATE SYSTEM TYPES'} link='' />
  <div className="cardPadding">

    <NavCard_Community cardContent={20} cardTitle={'Create System Type'} image={createImage} link={"/home/system_type/create"} />
    <NavCard_Community cardContent={20} cardTitle={'System Type List'} image={listImage} link={"/home/system_type/list/:system_typeId"} />
    <NavCard_Community cardContent={20} cardTitle={'Edit System Type'} image={editImage} link={"/home/system_type/edit/:system_typeId"} />
    <NavCard_Community cardContent={20} cardTitle={'System Type Details'} image={detailImage} link={"/home/system_type/detail/:system_typeId"} />

  </div>
  {/* <div className="cardPadding">
    <DashboardCard cardContent={20} cardTitle={'Organizations'} image={organizationImage} link={""}/>
    <DashboardCard cardContent={40} cardTitle={'Communities'} image={communityImage} link={""}/>
    <DashboardCard cardContent={230} cardTitle={'Management Users'} image={managementUsersImage} link={""}/>
    <DashboardCard cardContent={130} cardTitle={'People Trained'} image={trainedPeopleImage} link={""}/>
  </div> */}

  <div className="top">
    <div className="left">
      <div className="item">

        <div className="formBox" style={{
          "marginLeft":"10px", 
          "border": "0.5px solid lightgray",
          "padding":"20px", 
          "borderRadius":"5px",
          "marginTop":"10px",
          "marginBottom":"10px",
          "width": "100%",
        }}>

          <div className="details" style={{"width":"100%", }}>
            <h1 className="itemTitle">System Type</h1>
            <hr style={{
              height: "0.5px",
              backgroundColor: '#dddddd',
              marginTop: '10px',
              marginBottom: '10px',
              border: "none",
            }}/>

            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1 },
              }}
              noValidate
              autoComplete="off">

              <div style={{"width":"100%"}}>

              <div className="item">
                <img
                  src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/j4kq0dydlqc3gz51todw"
                  alt=""
                  className="itemImg"
                />
                <div style={{
                      "width": "50%",
                      "height": "50px", 
                      "marginTop":"20px", 
                      "marginBottom":"20px", 
                      "marginRight":"10px",  
                      "marginLeft":"10px",
                    }}>
                      
                  <Button 
                    style={{
                      "width": "50%",
                      "height": "50px", 
                      "marginTop":"40px", 
                      "marginBottom":"20px", 
                      "marginRight":"10px",  
                      "marginLeft":"10px",
                    }}
                    size="large"
                    variant="outlined"
                    onClick={() => {
                      alert('clicked');
                    }}
                  >
                    Select System Type Image
                  </Button>
                </div>

              </div>

                <div 
                  style={{
                    "display": "flex", 
                    "justifyContent": "space-between", 
                    "marginRight":"10px",  
                    "marginLeft":"10px",
                    "marginTop":"20px",  
                    // "marginBottom":"20px"  
                  }}
                >

                  <FormControl style={{"width": "45%"}}>
                    <InputLabel htmlFor="component-outlined">System Type Name</InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      value={system_type_name}
                      onChange={handleSystemNameChange}
                      label="System Type Name"
                      
                    />
                    {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                  </FormControl>

                  <FormControl style={{"width": "45%"}}>
                    <TextField
                      id="outlined-multiline-static"
                      label="System Type Description"
                      multiline
                      maxRows={4}
                      value={system_type_description}
                      onChange={handleMultilineSystemDescriptionChange}
                      defaultValue="Default Value"
                    />
                    {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                  </FormControl>

                </div>

                <div 
                  style={{
                    "display": "block", 
                    "justifyContent": "space-between", 
                    "marginRight":"10px",  
                    "marginLeft":"10px",
                    "marginTop":"20px",  
                    // "marginBottom":"20px"  
                  }}
                >


                  {/* dropdown component */}
                  {/* <FormControl sx={{ m: 0, minWidth: 220 }} style={{"width": "100%", "marginTop":"20px",}} error>
                    <InputLabel id="demo-simple-select-error-label">Select System Type Category</InputLabel>
                    <Select
                      labelId="demo-simple-select-error-label"
                      id="demo-simple-select-error"
                      value={system_type_category}
                      label="Select Form Type Category"
                      onChange={handleDropDownSystemTypeCategory}
                      renderValue={(value) =>  value }
                    >
                      <MenuItem value=""><em>None</em></MenuItem>
                      <MenuItem value={"Misc Reporting"}>Misc Reporting</MenuItem>
                      <MenuItem value={"Water System"}>Water System</MenuItem>
                      
                    </Select>
                    {error ? <FormHelperText id="component-outlined-text">Error</FormHelperText> : "" }
                  </FormControl> */}

                </div>

                <Button 
                  style={{
                    "width": "45%",
                    "height": "50px", 
                    "marginTop":"40px", 
                    "marginBottom":"20px", 
                    "marginRight":"10px",  
                    "marginLeft":"10px",
                  }}
                  size="large"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Create System Type
                </Button>

            </div>


            </Box>

            

          </div>


        </div>

      </div>
    </div>
  </div>

</div>
</div>

        <Modal
          hideBackdrop
          open={openModal}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 450 }}>
          <Button onClick={handleClose}>x</Button>
              <h3 id="child-modal-title" style={{textAlign: "center"}}>System Type Created Successfully</h3>
              {/* <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
                Welcome
              </p> */}
              <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{system_type_name}</h2>
              <Link to="/home/system_type/" style={{ textDecoration: "none", marginTop: "10px", }}>
                <span className="hasAccount"><Button 
                    style={{
                      "width": "100%",
                      "height": "50px", 
                      "marginTop":"20px", 
                      "marginBottom":"20px", 
                      "marginRight":"10px",  
                      "marginLeft":"20px",
                    }}
                    size="large"
                    variant="contained"
                    // onClick={handleSubmit}
                >
                    Continue
                </Button></span>
              </Link>
          </Box>
        </Modal>

        <Modal
          hideBackdrop
          open={openErrorModal}
          onClose={handleErrorClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 550 }}>
          <Button onClick={handleErrorClose}>x</Button>
              <h3 id="child-modal-title" style={{textAlign: "center"}}>System Type Creation Failed</h3>
              <p id="child-modal-description" style={{textAlign: "center", marginTop: "10px"}}>
                {errorMessage}
              </p>
              <h2 id="child-modal-title" style={{textAlign: "center", marginTop: "10px",}}>{system_type_name}</h2>
              <span className="hasAccount"><Button 
                    style={{
                      "width": "100%",
                      "height": "50px", 
                      "marginTop":"20px", 
                      "marginBottom":"20px", 
                      "marginRight":"10px",  
                      "marginLeft":"20px",
                    }}
                    size="large"
                    variant="contained"
                    onClick={handleErrorClose}
                >
                    Continue
                </Button></span>
          </Box>
        </Modal>
       
      </Main>
    </Box>
  );
  
};

export default Create_System_Type;