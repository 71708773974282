import Custom_Text from "../../components/custom_text";
import './training.scss'
import SideBarTraining from './sidebar_training/sidebar_training';
import NavBarAdmin from "../../components/navbar/navbar_admin";
import SectionHeader from "../../components/custom_text/section_header";
import DashboardCard from "../../components/card/card";
import TableList from "../../components/table/table";
import Sidebar from "../../components/sidebars/sidebar";
// Images
import createImage from './images/writing.png';
import editImage from './images/edit_writing.png';
import listImage from './images/to-do-list.png';
import detailImage from './images/details.png';
import NavCard_Community from "../../components/nav_card/navcard_community";
import organizationImage from './images/organization.png';
import communityImage from './images/community.png';
import managementUsersImage from './images/management_users.png';
import trainedPeopleImage from './images/training.png';
import DataTableComponentForms from "../../components/table/data_table_component_forms";
import React from "react";

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import logoImage from './images/logo.png';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const data : any = [
  {
    name: 'Clovis John',
    community: "Well beyond",
    form_name: "Maintenance Form",
    link_to_pdf: "",
    submitted_date: "28:10:2022",
    age: 30,
  },
  {
    name: 'John Trained',
    community: "Well beyond",
    form_name: "Maintenance Form",
    link_to_pdf: "",
    submitted_date: "28:10:2022", 
    age: 30,
  },
  {
    name: 'Princess John',
    community: "Well beyond",
    form_name: "Maintenance Form",
    link_to_pdf: "",
    submitted_date: "28:10:2022", 
    age: 30,
  },
]

const Training_List : React.FC = () => {
  
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

    let handleChange = (event : any) => {}
    let handleSubmit = (event : any) => {}

    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
  
            <Link to="/home" style={{ textDecoration: "none" }}>
                  {/* <span className='logo'>WellBeyond</span> */}
                  <img
                      src={logoImage}
                      alt=""
                      className="itemImg"
                  />
              </Link>
          </Toolbar>
          
          <NavBarAdmin/>
  
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? 
              <img
                src={logoImage}
                alt=""
                className="itemImg"
                  
              /> : 
              <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Sidebar />
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <div className="home">

            <div className="homeContainer">

              <SectionHeader sectionTitle={'TRAINING LIST'} link='' />
              <div className="cardPadding">

                <NavCard_Community cardContent={20} cardTitle={'Create Training'} image={createImage} link={"/home/training/create"} />
                <NavCard_Community cardContent={20} cardTitle={'Training List'} image={listImage} link={"/home/training/list/:trainingId"} />
                <NavCard_Community cardContent={20} cardTitle={'Completed Training'} image={editImage} link={"/home/training/completed"} />
                <NavCard_Community cardContent={20} cardTitle={'Training Details'} image={detailImage} link={"/home/training/detail/:trainingId"} />

              </div>
              <div className="cardPadding">
                <DashboardCard cardContent={20} cardTitle={'Organizations'} image={organizationImage} link={""}/>
                <DashboardCard cardContent={40} cardTitle={'Communities'} image={communityImage} link={""}/>
                <DashboardCard cardContent={230} cardTitle={'Management Users'} image={managementUsersImage} link={""}/>
                <DashboardCard cardContent={130} cardTitle={'People Trained'} image={trainedPeopleImage} link={""}/>
              </div>

              <SectionHeader sectionTitle={'DATA LISTS'} link='' />
              <DataTableComponentForms data={data}/>
              <div className="listContainer">
                <div className="listTitle">Training</div>
                <TableList data={[]}/>
              </div>

            </div>
          </div>
         
        </Main>
      </Box>
    );
  
};

export default Training_List;